export default function Case() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill-opacity="0.7" clip-path="url(#clip0_34_23293)">
        <path
          d="M3.25 7.75008C3.25 7.13124 3.47827 6.53775 3.8846 6.10017C4.29093 5.66258 4.84203 5.41675 5.41667 5.41675H20.5833C21.158 5.41675 21.7091 5.66258 22.1154 6.10017C22.5217 6.53775 22.75 7.13124 22.75 7.75008V18.2501C22.75 18.8689 22.5217 19.4624 22.1154 19.9C21.7091 20.3376 21.158 20.5834 20.5833 20.5834H5.41667C4.84203 20.5834 4.29093 20.3376 3.8846 19.9C3.47827 19.4624 3.25 18.8689 3.25 18.2501V7.75008Z"
          fill="white"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13 11.9167V14.0834"
          stroke="rgb(25, 34, 64)"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.16797 10.8333H23.8346"
          stroke="#192240"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
}
