import Layout from "components/Layout";
import {
  ArrowStyle,
  BigText,
  BlockClan,
  BlockCreateClan,
  BlockMode,
  ButMode,
  ClanBlockss,
  DefaultFlexG1,
  DivBg,
  MediumText,
  NoMyClansBlock,
  WrapClans,
  WrapFullClans,
} from "./styled";
import { useEffect, useState } from "react";
import { ClansServise } from "API/ClansServise";
import { useAppSelector } from "hooks/useAppSelector";
import { TinyIcons } from "assets/tiny-icons";
import Button from "components/UI/Button";
import {
  ClanRolesEnum,
  ClansOne,
  ClansArray,
  ClansSeasonOne,
} from "interfaces/Clans";
import Avatar from "components/Avatar";
import { useNavigate, useParams } from "react-router-dom";
import { Modals } from "components/Modal";
import { P } from "pages/Store/Policy/Styles";
import { Helmet } from "react-helmet";
import ClansState from "./ClansState";

export default function Clans() {
  const [Clans, setClans] = useState<ClansArray>();
  const [ClansTop, setClansTop] = useState<ClansSeasonOne[]>();
  const [isActiveCreateClan, setIsActiveCreateClan] = useState<boolean>(false);
  const [mode, setMode] = useState<number>(1);

  useEffect(() => {
    async function StartClans() {
      setClans(await ClansServise.getClans());
    }

    StartClans();
  }, [1]);

  useEffect(() => {
    async function GetTopClan() {
      setClansTop(await ClansServise.getClansTop());
    }
    if (mode == 1 && !ClansTop) {
      GetTopClan();
    }
  }, [mode]);

  useEffect(() => {
    Clans && ClansState.SetClans(Clans);
  }, [Clans]);

  const navigate = useNavigate();
  return (
    <Layout headerElement={<div />} leftElement={<div />}>
      <Helmet>
        <title>FREEZ-BLOOD - Кланы</title>
        <meta name="description" content={`FREEZ-BLOOD - Контракты`} />
      </Helmet>

      <WrapFullClans>
        {/* TOP-MyCLAN */}
        {Clans?.myClan == null ||
        Clans?.myClan.role == ClanRolesEnum.Requested ? (
          <NoMyClansBlock>
            <WrapClans style={{ gap: "2rem" }}>
              <MediumText>Мой клан</MediumText>
              <BlockClan style={{ padding: "2rem" }}>
                <MediumText style={{ opacity: "60%", fontWeight: "normal" }}>
                  Вы еще не вступили в клан
                </MediumText>
              </BlockClan>
            </WrapClans>
            <BlockCreateClan>
              <DefaultFlexG1>
                <TinyIcons.Swords />
                <MediumText>Создать клан!</MediumText>
              </DefaultFlexG1>
              <DefaultFlexG1>
                <MediumText
                  style={{
                    opacity: "60%",
                    maxWidth: "20rem",
                    fontWeight: "normal",
                    lineHeight: "2.5rem",
                  }}
                >
                  У вас еще нет клана? Создайте его
                </MediumText>
                <Button
                  height="5rem"
                  variant="default"
                  color="blue"
                  style={{
                    fontSize: "30px",
                    fontWeight: "normal",
                    borderRadius: "1.5rem",
                  }}
                  onClick={() => {
                    setIsActiveCreateClan(true);
                  }}
                >
                  +
                </Button>
              </DefaultFlexG1>
            </BlockCreateClan>
          </NoMyClansBlock>
        ) : (
          <NoMyClansBlock>
            <BlockCreateClan style={{ width: "100%", gap: "2rem" }}>
              <MediumText>Мой клан</MediumText>

              <BlockClan
                style={{ cursor: "pointer" }}
                onClick={() => navigate(`/clans/${Clans?.myClan?.id}`)}
              >
                <DefaultFlexG1 style={{ gap: "3rem" }}>
                  <DefaultFlexG1>
                    <TinyIcons.LeftBranch
                      style={{
                        fill:
                          Clans?.myClan.place == 1
                            ? "#fff200"
                            : Clans?.myClan.place == 2
                            ? "#509ba3"
                            : Clans?.myClan.place == 3
                            ? "#DF805D"
                            : "",
                        fillOpacity:
                          Clans?.myClan && Clans?.myClan?.place <= 3
                            ? "90%"
                            : "5%",
                      }}
                    />
                    <DivBg>
                      <MediumText>{Clans?.myClan?.place}</MediumText>
                    </DivBg>
                    <TinyIcons.RightBranch
                      style={{
                        fill:
                          Clans?.myClan?.place == 1
                            ? "#fff200"
                            : Clans?.myClan?.place == 2
                            ? "#509ba3"
                            : Clans?.myClan?.place == 3
                            ? "#DF805D"
                            : "",
                        fillOpacity:
                          Clans?.myClan && Clans?.myClan?.place <= 3
                            ? "90%"
                            : "5%",
                      }}
                    />
                  </DefaultFlexG1>
                  <DefaultFlexG1>
                    <Avatar size="small" src={Clans?.myClan?.avatar} />
                    <MediumText>{Clans?.myClan?.name}</MediumText>
                  </DefaultFlexG1>
                </DefaultFlexG1>
                <DefaultFlexG1 style={{ gap: "2rem" }}>
                  <DefaultFlexG1>
                    <MediumText>
                      {Clans?.myClan?.members || 0}/{Clans?.myClan?.capacity}
                    </MediumText>
                    <TinyIcons.UsersClans />
                  </DefaultFlexG1>
                  <DefaultFlexG1 style={{ minWidth: "13rem" }}>
                    <MediumText style={{ color: "#FFC526" }}>
                      {Clans?.myClan?.xp}
                    </MediumText>
                    <TinyIcons.XpClans />
                  </DefaultFlexG1>
                  <ArrowStyle right={true} />
                </DefaultFlexG1>
              </BlockClan>
            </BlockCreateClan>
          </NoMyClansBlock>
        )}
        {/* TOP-MyCLAN */}
        <BlockMode>
          <ButMode Activ={mode == 1} onClick={() => setMode(1)}>
            Сезонный топ
          </ButMode>
          <ButMode Activ={mode == 0} onClick={() => setMode(0)}>
            Глобальный топ
          </ButMode>
        </BlockMode>
        {mode == 0 ? (
          <WrapClans>
            {Clans &&
              Clans.clans.map((item, id) => {
                return (
                  <BlockClan
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/clans/${item.id}`)}
                  >
                    <ClanBlockss>
                      <DefaultFlexG1>
                        <TinyIcons.LeftBranch
                          style={{
                            fill:
                              id == 0
                                ? "#fff200"
                                : id == 1
                                ? "#509ba3"
                                : id == 2
                                ? "#DF805D"
                                : "",
                            fillOpacity: id < 3 ? "90%" : "5%",
                          }}
                        />
                        <DivBg>
                          <MediumText>{id + 1}</MediumText>
                        </DivBg>
                        <TinyIcons.RightBranch
                          style={{
                            fill:
                              id == 0
                                ? "#fff200"
                                : id == 1
                                ? "#509ba3"
                                : id == 2
                                ? "#DF805D"
                                : "",
                            fillOpacity: id < 3 ? "90%" : "5%",
                          }}
                        />
                      </DefaultFlexG1>
                      <DefaultFlexG1>
                        <Avatar size="small" src={item.avatar} />
                        <MediumText>{item.name}</MediumText>
                      </DefaultFlexG1>
                    </ClanBlockss>
                    <ClanBlockss isT={true}>
                      <DefaultFlexG1>
                        <MediumText>
                          {item.members}/{item.capacity}
                        </MediumText>
                        <TinyIcons.UsersClans />
                      </DefaultFlexG1>
                      <DefaultFlexG1>
                        <DefaultFlexG1 style={{ minWidth: "13rem" }}>
                          <MediumText style={{ color: "#FFC526" }}>
                            {item.xp}
                          </MediumText>
                          <TinyIcons.XpClans />
                        </DefaultFlexG1>
                        <ArrowStyle right={true} />
                      </DefaultFlexG1>
                    </ClanBlockss>
                  </BlockClan>
                );
              })}
          </WrapClans>
        ) : (
          <WrapClans>
            {ClansTop &&
              ClansTop.map((item, id) => {
                return (
                  <BlockClan
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/clans/${item.clan.id}`)}
                  >
                    <ClanBlockss isT={true}>
                      <DefaultFlexG1 style={{ gap: "3rem" }}>
                        <DefaultFlexG1>
                          <TinyIcons.LeftBranch
                            style={{
                              fill:
                                id == 0
                                  ? "#fff200"
                                  : id == 1
                                  ? "#509ba3"
                                  : id == 2
                                  ? "#DF805D"
                                  : "",
                              fillOpacity: id < 3 ? "90%" : "5%",
                            }}
                          />
                          <DivBg>
                            <MediumText>{id + 1}</MediumText>
                          </DivBg>
                          <TinyIcons.RightBranch
                            style={{
                              fill:
                                id == 0
                                  ? "#fff200"
                                  : id == 1
                                  ? "#509ba3"
                                  : id == 2
                                  ? "#DF805D"
                                  : "",
                              fillOpacity: id < 3 ? "90%" : "5%",
                            }}
                          />
                        </DefaultFlexG1>
                        <DefaultFlexG1>
                          <Avatar size="small" src={item.clan.avatar} />
                          <MediumText>{item.clan.name}</MediumText>
                        </DefaultFlexG1>
                      </DefaultFlexG1>
                    </ClanBlockss>
                    <ClanBlockss isT={true}>
                      <DefaultFlexG1 style={{ gap: "2rem" }}>
                        <DefaultFlexG1>
                          <MediumText>
                            {item.clan.members || 0}/{item.clan.capacity}
                          </MediumText>
                          <TinyIcons.UsersClans />
                        </DefaultFlexG1>
                        <DefaultFlexG1 style={{ minWidth: "13rem" }}>
                          <MediumText style={{ color: "#FFC526" }}>
                            {item.xp}
                          </MediumText>
                          <TinyIcons.XpClans />
                        </DefaultFlexG1>
                        <ArrowStyle right={true} />
                      </DefaultFlexG1>
                    </ClanBlockss>
                  </BlockClan>
                );
              })}
          </WrapClans>
        )}
      </WrapFullClans>
      <Modals.CreateClansModal
        isActive={isActiveCreateClan}
        setActive={setIsActiveCreateClan}
      />
    </Layout>
  );
}
