import Layout from "components/Layout";
import ProfileLayout from "components/ProfileLayout";
import {
  BigText,
  BlockSkins,
  DefFlexFCJC,
  DefFlexG1,
  DefFlexSB,
  ItemInMenuMenu,
  ItemMenu,
  ItemMenuInMenu,
  MediumText,
  SmallText,
  WrapMenuSkinsChan,
  WrapperMenuProfile,
  WrapperSkinchangerFull,
} from "./styled";
import SkinsChangersState from "./SkinsChangersState";
import { TinyIcons } from "assets/tiny-icons";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { Icons } from "assets/icons";
import { InventoryWeaponCard } from "components/Cards";
import { toast } from "react-toastify";
import {
  AgentChangeItem,
  CategoriesItems,
  Classes,
  SkinChangeItem,
  UsedCategoryEnum,
} from "interfaces/SkinChanger";
import { toJS } from "mobx";
import { Modals } from "components/Modal";
import { set } from "lodash";

function SkinsChangers() {
  // const [indexItem, setIndexItem] = useState<number>(-1);
  const [cl, setCl] = useState<boolean>(false);
  const [activeModal, setActiveModal] = useState<boolean>(false);
  const [ActiveSkin, setActiveSkin] = useState<SkinChangeItem>();

  useEffect(() => {
    SkinsChangersState.updateOpenCategoryId(-1);
    SkinsChangersState.updateOpenSkinId(72, "Нож-бабочка", true);
    SkinsChangersState.updateUsedCategoryId(1);
  }, [1]);

  // useEffect(() => {
  //   const a: CategoriesItems | undefined = SkinsChangersState.categories
  //     .find((item) => item.id == SkinsChangersState.openCategoryId)
  //     ?.items.find((items) => items.id == SkinsChangersState.openSkinId);
  //   if (SkinsChangersState.openSkinId) {
  //     SkinsChangersState.updateOneCategory(a);
  //   }
  // }, [SkinsChangersState.openSkinId]);

  const skinsMemo = useMemo(() => {
    return SkinsChangersState.SkinsIdArray.map((item, index) => (
      <InventoryWeaponCard
        key={index}
        src={item.image}
        rarity={SkinsChangersState.getRarity(index, false)}
        weapon={SkinsChangersState.NameSkinId}
        paint_kit_id={item.paint_kit_id}
        skin={item.paint_name}
        skind={item.options && item.options.classes}
        cl={cl}
        // skind={item.options?.classes}
        skinChangerStatus={SkinsChangersState.getStatus(index, false)}
        onClick={async () => {
          console.log(item);
          if (item.can_be_used && item.paint_kit_id !== 1) {
            setActiveSkin(item);
            setActiveModal(true);
          } else if (item.paint_kit_id == 1) {
            const result = await SkinsChangersState.ApplyItems(
              SkinsChangersState.openSkinId,
              Classes.BOTH,
              false,
              item.paint_kit_id
            );
            if (result) {
              SkinsChangersState.updateOpenSkinId(
                SkinsChangersState.openSkinId,
                SkinsChangersState.NameSkinId,
                true
              );
              toast.success("Успешно");
            }
          }
        }}
        // onClick={() => {
        //   if (item.can_be_used == true) {
        //     SkinsChangersState.onClick(index);
        //   }

        //   setPrefab(item.prefab);
        //   updateModalSetting();
        //   // SkinChangerState.resetSkins(indexItem);

        //   // {item.paint_kit_id == 1 ? SkinChangerState.apply() : null}
        //   if (item.paint_kit_id === 1) {
        //     SkinsChangersState.apply(false);
        //     toast("Сброшено!");
        //   }
        //   // SkinChangerState.apply(item.paint_kit_id);
        // }}
        onMouseEnter={() => {
          // setIndexItem(index);
          SkinsChangersState.setHover(index, true, false);
          // SkinChangerState.resetSkins(indexItem);
        }}
        onMouseLeave={() => SkinsChangersState.setHover(index, false, false)}
      />
    ));
  }, [SkinsChangersState.SkinsIdArray]);

  const agentMemo = useMemo(() => {
    return SkinsChangersState.agent.map((item, index) => (
      <InventoryWeaponCard
        key={index}
        src={item.image}
        rarity={SkinsChangersState.getRarity(index, true)}
        weapon={""}
        paint_kit_id={1}
        skin={item.class_ru}
        // skind={item.options.classes}
        cl={cl}
        skinChangerStatus={SkinsChangersState.getStatus(index, true)}
        onClick={
          item.can_be_used
            ? async () => {
                const Class: Classes =
                  item.used_by_ct && item.used_by_ct
                    ? Classes.BOTH
                    : item.used_by_ct
                    ? Classes.CT
                    : Classes.TT;

                const result: { success: boolean } | undefined =
                  await SkinsChangersState.ApplyItems(
                    Number(item.id),
                    Class,
                    true
                  );
                if (result?.success) {
                  console.log(item);
                  toast.success("Агент выбран!");
                  SkinsChangersState.getAgents();
                }
              }
            : undefined
        }
        // {
        //       item.can_be_used
        //         ? applyAgent(kolClickAgent, item)
        //         : undefined;
        //     }
        onMouseEnter={() => {
          SkinsChangersState.setHover(index, true, true);
        }}
        onMouseLeave={() => SkinsChangersState.setHover(index, false, true)}
      />
    ));
  }, [SkinsChangersState.agent]);

  return (
    <Layout leftElement={<div />} nonChat={true} headerElement={<div />}>
      <ProfileLayout>
        <WrapperSkinchangerFull>
          <WrapMenuSkinsChan>
            {SkinsChangersState.categories.map((item, index) => {
              return (
                <ItemMenu style={{ userSelect: "none" }}>
                  <DefFlexSB
                    onClick={() => {
                      if (!item.single) {
                        if (SkinsChangersState.openCategoryId == item.id) {
                          SkinsChangersState.updateOpenCategoryId(-1);
                        } else {
                          SkinsChangersState.updateOpenCategoryId(
                            item.id,
                            false
                          );
                        }
                      } else {
                        SkinsChangersState.updateOpenSkinId(
                          item.id,
                          item.name,
                          true
                        );
                        SkinsChangersState.getAgents();
                        SkinsChangersState.updateCategoryUsed(
                          UsedCategoryEnum.Agent
                        );
                      }
                    }}
                    style={{ cursor: "pointer", padding: "2rem 1.6rem" }}
                  >
                    <MediumText>{item.name}</MediumText>
                    {!item.single ? (
                      <WrapperMenuProfile
                        Active={SkinsChangersState.openCategoryId == item.id}
                      >
                        <TinyIcons.Arrow />
                      </WrapperMenuProfile>
                    ) : null}
                  </DefFlexSB>
                  <ItemMenuInMenu
                    active={SkinsChangersState.openCategoryId == item.id}
                  >
                    {SkinsChangersState.openCategoryId == item.id &&
                      item.items.map((items) => {
                        return (
                          <DefFlexSB
                            style={
                              SkinsChangersState.openSkinId == items.id
                                ? { opacity: "100%", cursor: "pointer" }
                                : { opacity: "60%", cursor: "pointer" }
                            }
                            onClick={() => {
                              SkinsChangersState.updateOpenSkinId(
                                items.id,
                                items.class_ru,
                                false
                              );

                              SkinsChangersState.getAndUpdateSkins(items.id);
                              SkinsChangersState.updateCategoryUsed(
                                UsedCategoryEnum.Skins
                              );
                              SkinsChangersState.updateOpenCategoryId(
                                item.id,
                                true
                              );
                            }}
                          >
                            <ItemInMenuMenu>{items.class_ru}</ItemInMenuMenu>
                            {SkinsChangersState.openSkinId == items.id ? (
                              <Icons.EarnedBP />
                            ) : null}
                          </DefFlexSB>
                        );
                      })}
                  </ItemMenuInMenu>
                </ItemMenu>
              );
            })}
          </WrapMenuSkinsChan>

          <BlockSkins>
            <BigText>{SkinsChangersState.NameSkinId}</BigText>
            <DefFlexG1 style={{ flexWrap: "wrap" }}>
              {SkinsChangersState.UseCategory == UsedCategoryEnum.Skins
                ? skinsMemo
                : agentMemo}
            </DefFlexG1>
          </BlockSkins>
        </WrapperSkinchangerFull>
      </ProfileLayout>
      <Modals.SkinChangerModal
        isActive={activeModal}
        setActive={setActiveModal}
        state={SkinsChangersState}
        skin={ActiveSkin}
      />
    </Layout>
  );
}

export default observer(SkinsChangers);
