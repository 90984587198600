export default function Support() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 0C5.8318 0 0 5.8318 0 13V18.3859C0 19.7171 1.1661 20.8 2.6 20.8H3.9C4.24478 20.8 4.57544 20.663 4.81924 20.4192C5.06304 20.1754 5.2 19.8448 5.2 19.5V12.8141C5.2 12.4693 5.06304 12.1387 4.81924 11.8949C4.57544 11.6511 4.24478 11.5141 3.9 11.5141H2.7196C3.4424 6.4831 7.7714 2.6 13 2.6C18.2286 2.6 22.5576 6.4831 23.2804 11.5141H22.1C21.7552 11.5141 21.4246 11.6511 21.1808 11.8949C20.937 12.1387 20.8 12.4693 20.8 12.8141V20.8C20.8 22.2339 19.6339 23.4 18.2 23.4H15.6V22.1H10.4V26H18.2C21.0678 26 23.4 23.6678 23.4 20.8C24.8339 20.8 26 19.7171 26 18.3859V13C26 5.8318 20.1682 0 13 0Z"
        fill="white"
        fillOpacity="0.7"
      />
    </svg>
  );
}
