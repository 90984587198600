import styled from "styled-components";
import { Wrapper } from "components/Avatar/Styles";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  gap: 5rem;
  justify-content: space-between;
  ${Wrapper} {
    margin-right: 1rem;
  }
  svg {
    cursor: pointer;
    path {
      transition: 0.15s stroke-opacity;
    }
    &:hover {
      path {
        stroke-opacity: 1;
      }
    }
  }
`;

export const Title = styled.h4`
  position: relative;
  ${(props) => props.theme.fonts.GothamMedium20};
  color: ${(props) => props.theme.colors.white.white100};
`;

export const Time = styled.p`
  ${(props) => props.theme.fonts.GothamRegular16};
  color: ${(props) => props.theme.colors.white.white70};
`;

export const Body = styled.p`
  ${(props) => props.theme.fonts.GothamRegular20};
  color: ${(props) => props.theme.colors.white.white100};
`;

export const Highlighted = styled.strong`
  color: ${(props) => props.theme.colors.blue.accent};
  ${(props) => props.theme.fonts.GothamRegular20};
`;

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
  }
`;

export const BlockButton = styled.div`
  display: block;
  @media (max-width: 1250px) {
    display: none;
  }
`;
