import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;

  margin-top: 2rem;
  @media (max-width: 1250px) {
    align-items: center;
  }
  @media (max-width: 768px) {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

export const SupperHeaderInfo = styled.div<{
  Active?: Boolean;
  ActiveBan?: Boolean;
}>`
  display: flex;
  /* background: red; */

  ${(props) =>
    props.Active ? `background:${props.theme.colors.menu_bg}` : null};
  ${(props) =>
    props.Active ? `background:${props.theme.colors.menu_bg}` : null};
  ${(props) => (props.Active ? `cursor:pointer` : null)};
  ${(props) =>
    props.Active && props.ActiveBan
      ? `background:${props.theme.colors.ct}`
      : null};
  padding: 3rem;
  justify-content: space-around;
  border-radius: 15px;
  :hover {
    ${(props) => (props.Active ? `background:${props.theme.colors.ct}` : null)};
  }
  @media (max-width: 1250px) {
    min-width: 100rem;
    /* overflow: hidden; */
  }
`;
export const HeadItem = styled.span`
  ${({ theme }) => theme.fonts.GothamMedium16};
  color: ${({ theme }) => theme.colors.white.white70};
  width: 14rem;
  text-wrap: nowrap;
  overflow: hidden;
  /* max-height: 2rem; */

  /* color: red; */
`;
export const WrapBans = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  @media (max-width: 1250px) {
    width: 100%;
    overflow-x: scroll;
  }
`;
export const NonBan = styled.div`
  text-align: center;
  ${(props) => props.theme.fonts.GothamMedium24}
  padding: 5rem;
  width: 100%;
  background: ${(props) => props.theme.gradients.shadow};
  margin-top: 10rem;
  border-radius: 2rem;
`;
