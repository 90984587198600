import styled from "styled-components";
import Image from "components/Image";

export const Wrapper = styled.div<{ marginTop?: string }>`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
  height: 100%;
  margin-bottom: 3rem;
  margin-top: ${(props) => props.marginTop};
  /* ${(props) => (props.title ? "text-align:center" : "")} */
  /* margin-left: 1rem; */
`;
export const HeaderWrap = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;
export const WrapTitle = styled.span`
  text-align: center;
  ${(props) => props.theme.fonts.GothamBold32};
`;

export const Title = styled.h1`
  ${(props) => props.theme.fonts.GothamMedium32};
  color: ${(props) => props.theme.colors.white.white100};
  margin: 50px 0 50px 0;
`;

export const Container = styled.div`
  background: transparent;
  ${(props) => props.theme.mixins.defaultShadow};
  border-radius: 2.5rem;
  padding: 2rem;
  /* max-width: 80rem; */
  /* width: 100%; */
  width: 78.4rem;
  height: fit-content;
  margin-bottom: 5rem;
`;

export const Grid = styled.div`
  margin-top: 3rem;
  width: 100%;
  display: grid;
  gap: 3rem;
  grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
`;

export const ImageSuccess = styled(Image)`
  width: 100%;
  height: 10rem;
  border-radius: 2.5rem;
  object-fit: contain;
  margin-bottom: 30px;
`;

export const ImageBack = styled(Image)`
  width: 32px;
  height: 22px;
  cursor: pointer;
  /* border-radius: 2.5rem; */
  /* object-fit: contain; */
  /* margin-bottom: 30px; */
`;
export const Text = styled.span<{ active?: boolean; color?: string }>`
  ${(props) => props.theme.fonts.GothamBold24}

  color: ${(props) =>
    props.color ? props.color : props.theme.colors.white.white100};

  /* margin-bottom: ${(props) => (props.active ? "30rem" : "5px")}; */
`;
