import { Button } from "components/UI/Button/Styles";
import styled from "styled-components";
import { Wrapper as TabsWrapperL } from "components/Templates/Tabs/Styles";

interface Props {
  gap?: string;
}

export const Wrapper = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.gap ? props.gap : "3rem")};
  /* margin-left: 1rem; */
`;

export const WrapperFlexBase = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
  }
`;

export const AdditionalButtons = styled.div`
  /* width: max-content; */
  display: flex;
  /* grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr)); */
  flex-wrap: wrap;
  gap: 1rem;
  /* padding: 0 10rem; */
  /* a, */
  /* button {
    width: 100%;
  } */
  @media (max-width: 768px) {
    padding: 0;
    margin-top: 1rem;
    justify-content: center;
  }
`;

export const TabsWrapper = styled.div<{ isYou: boolean }>`
  display: flex;
  align-self: center;
  justify-content: center;
  background: ${(props) => props.theme.gradients.shadow};
  padding: 0.5rem 2rem;
  border-radius: 1.5rem;
  width: 100%;
  ${TabsWrapperL} {
    ${Button}, a {
      width: 100%;
    }
    width: 100%;
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(${(props) => (props.isYou ? "21rem" : "31.7%")}, 1fr)
    );
    transition: all 0.3s;
    gap: 1.5rem;
    @media (max-width: 1250px) {
      display: flex;
      /* justify-content: start; */
      flex-direction: column;
      /* overflow-y: scroll; */
      /* width: 100%; */
    }
  }
`;
