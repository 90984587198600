import styled, { css } from "styled-components";
interface WrapperProps {
  direction: "column" | "row";
}

export const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => props.direction};

  gap: 3rem;
  margin-bottom: 3rem;
  @media (max-width: 1250px) {
    overflow: scroll;
    /* margin-left: 1rem; */
  }
`;

export const Container = styled.div`
  width: 100%;
  max-height: 72rem;
  height: 100%;
  ${(props) => props.theme.mixins.defaultShadow};
  border-radius: 2.5rem;
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  /* overflow-x: auto; */
  min-width: 100rem;
`;

export const Head = styled.div`
  display: grid;
  grid-template-columns: 0.13fr 0.37fr 0.2fr 0.2fr 0.25fr 0.4fr;
  padding: 0.6rem 2rem;
`;

export const HeadItem = styled.span`
  ${(props) => props.theme.fonts.GothamRegular20};
  color: ${(props) => props.theme.colors.white.white100};
`;

export const BodyRow = styled.div`
  position: relative;
  /* overflow: hidden; */
  display: grid;
  grid-template-columns: 0.13fr 0.37fr 0.2fr 0.2fr 0.25fr 0.4fr;
  padding: 0.6rem 2rem;
  height: 7rem;
  align-items: center;
  border-radius: 2rem;
  border-radius: 2.5rem;
  background: ${(props) => props.theme.colors.menu_bg};
  /* overflow: scroll; */
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  /* min-width: 100rem; */
  /* overflow: scroll; */
`;

export const PlaceItem = styled.div`
  ${(props) => props.theme.fonts.GothamRegular20};
  color: ${(props) => props.theme.colors.white.white100};
  z-index: 5;
`;

export const BodyItem = styled.div`
  ${(props) => props.theme.fonts.GothamRegular20};
  color: ${(props) => props.theme.colors.white.white100};
  z-index: 5;
  cursor: pointer;
`;

export const Gradient = styled.div<{ place: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  border-radius: 2.5rem;
  ${(props) =>
    props.place === 0 &&
    css`
      background: ${(props) => props.theme.gradients.rating_you};
    `}
  ${(props) =>
    props.place === 1 &&
    css`
      background: ${(props) => props.theme.gradients.rating_first};
    `}
	${(props) =>
    props.place === 2 &&
    css`
      background: ${(props) => props.theme.gradients.rating_second};
    `}
	${(props) =>
    props.place === 3 &&
    css`
      background: ${(props) => props.theme.gradients.rating_third};
    `}
`;
