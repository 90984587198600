import { CaseServise as CaseService } from "API/CaseService";
import { TinyIcons } from "assets/tiny-icons";
import Image from "components/Image";
import { LabelAvatar } from "components/Labels";
import UIKIT from "components/UI";
import { MarketplaceContext } from "context/marketplace";
import { useActions } from "hooks/useActions";
import { useAppSelector } from "hooks/useAppSelector";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { filterMarketInventory } from "redux/slices/user.slice";
import { useTheme } from "styled-components";
import rarityConvector, {
  priceRarityConvector,
  rarityConvectorToInt,
} from "utils/rarityConvector";
import Modal from "../Modal";
import {
  BlockHr,
  BlockMarketCommision,
  Bottom,
  BuyMode,
  Container,
  DefFlexSB,
  Fsdf,
  Gun,
  Info,
  Name,
  OnSellMode,
  SellMode,
  SubName,
  Text,
  TextSmall,
  TextSmallT,
  Weapon,
} from "./Styles";
import { Icons } from "assets/icons";

interface Props {
  isActive: boolean;
  setActive: React.SetStateAction<any>;
  mode: number;
  marketInventory?: any[];
  modalStyle?: any;
  priceUpdate?: () => void;
  compare?: {
    set: any;
    get: any;
    all: any;
    btn: any;
  };
}

export default function MarketplaceWeaponMOdal({
  isActive,
  setActive,
  mode,
  compare,
}: Props) {
  const currentWeapon = useContext(MarketplaceContext);

  const Theme = useTheme();
  const dispatch = useDispatch();
  const {
    fetchPutItem,
    fetchRemoveItem,
    fetchBuyItems,
    fetchUpdateItem,
    setBalance,
  } = useActions();

  const { user } = useAppSelector((state) => state.user);

  //Ллогика просчетов
  const [sellSkinPrice, setSellSkinPrice] = useState("");
  const [commission, setCommission] = useState<number>();
  const [GetPrice, setGetPrice] = useState<number>();

  // useEffect(() => {
  //   if (Number(sellSkinPrice) >= 1000) {
  //     if (user?.group.flags.includes("v")) {
  //       if (
  //         Number(sellSkinPrice) >= 2000000 &&
  //         Number(sellSkinPrice) <= 5000000
  //       ) {
  //         setCommission((Number(sellSkinPrice) / 100) * 14);
  //         setGetPrice(
  //           Number(sellSkinPrice) - (Number(sellSkinPrice) / 100) * 14
  //         );
  //       }
  //       if (
  //         Number(sellSkinPrice) >= 500000 &&
  //         Number(sellSkinPrice) < 2000000
  //       ) {
  //         setCommission((Number(sellSkinPrice) / 100) * 11);
  //         setGetPrice(
  //           Number(sellSkinPrice) - (Number(sellSkinPrice) / 100) * 11
  //         );
  //       }
  //       if (Number(sellSkinPrice) >= 1000 && Number(sellSkinPrice) < 500000) {
  //         setCommission((Number(sellSkinPrice) / 100) * 9);
  //         setGetPrice(
  //           Number(sellSkinPrice) - (Number(sellSkinPrice) / 100) * 9
  //         );
  //       }
  //     } else if (user?.group.flags.includes("p")) {
  //       if (
  //         Number(sellSkinPrice) >= 2000000 &&
  //         Number(sellSkinPrice) <= 5000000
  //       ) {
  //         setCommission((Number(sellSkinPrice) / 100) * 13);
  //         setGetPrice(
  //           Number(sellSkinPrice) - (Number(sellSkinPrice) / 100) * 13
  //         );
  //       }
  //       if (
  //         Number(sellSkinPrice) >= 500000 &&
  //         Number(sellSkinPrice) < 2000000
  //       ) {
  //         setCommission((Number(sellSkinPrice) / 100) * 10);
  //         setGetPrice(
  //           Number(sellSkinPrice) - (Number(sellSkinPrice) / 100) * 10
  //         );
  //       }
  //       if (Number(sellSkinPrice) >= 1000 && Number(sellSkinPrice) < 500000) {
  //         setCommission((Number(sellSkinPrice) / 100) * 8);
  //         setGetPrice(
  //           Number(sellSkinPrice) - (Number(sellSkinPrice) / 100) * 8
  //         );
  //       }
  //       ////p
  //     } else {
  //       if (
  //         Number(sellSkinPrice) >= 2000000 &&
  //         Number(sellSkinPrice) <= 10000000
  //       ) {
  //         setCommission((Number(sellSkinPrice) / 100) * 15);
  //         setGetPrice(
  //           Number(sellSkinPrice) - (Number(sellSkinPrice) / 100) * 15
  //         );
  //       }
  //       if (
  //         Number(sellSkinPrice) >= 500000 &&
  //         Number(sellSkinPrice) < 2000000
  //       ) {
  //         setCommission((Number(sellSkinPrice) / 100) * 12);
  //         setGetPrice(
  //           Number(sellSkinPrice) - (Number(sellSkinPrice) / 100) * 12
  //         );
  //       }
  //       if (Number(sellSkinPrice) >= 1000 && Number(sellSkinPrice) < 500000) {
  //         setCommission((Number(sellSkinPrice) / 100) * 10);
  //         setGetPrice(
  //           Number(sellSkinPrice) - (Number(sellSkinPrice) / 100) * 10
  //         );
  //       }
  //     }
  //   } else {
  //     setCommission(undefined);
  //     setGetPrice(undefined);
  //   }
  // }, [sellSkinPrice]);

  useEffect(() => {
    if (Number(sellSkinPrice) <= 4000000 && Number(sellSkinPrice) >= 1000) {
      setCommission((Number(sellSkinPrice) / 100) * 10);
      setGetPrice(Number(sellSkinPrice) - (Number(sellSkinPrice) / 100) * 10);
    } else {
      setCommission(undefined);
      setGetPrice(undefined);
    }
  }, [sellSkinPrice]);
  ///--

  const isAvatar = () => {
    if (mode === 100 || mode === 1 || mode === 2) {
      return false;
    }

    return true;
  };

  const isPrice = () => {
    if (mode === 2 || mode === 100) {
      return false;
    }

    return true;
  };

  const [error, setError] = useState<boolean | undefined>(undefined);

  const updateSellingPrice = (price: string) => {
    if (/[^0-9]/.test(price)) return;
    setSellSkinPrice(price);
  };

  const buyItem = (id = "0") => {
    fetchBuyItems(id);
    setActive(false);
  };
  const updateItem = (obj: any) => {
    fetchUpdateItem(obj);
    setActive(false);
  };
  const removeItem = (id = "0") => {
    fetchRemoveItem(id);
    setActive(false);
  };

  useEffect(() => {
    setSellSkinPrice("");
    setCommission(undefined);
    setGetPrice(undefined);
  }, [isActive, mode]);

  const [isActiveFlag, setIsActiveFlag] = useState<boolean>(false);
  const [isFading, setIsFading] = useState(false); // Отвечает за состояние угасания

  const handleButtonClick = () => {
    if (!isActiveFlag && !isFading) {
      // Если элемент не горит и не угасает
      setIsActiveFlag(true); // Зажигаем красным
      setTimeout(() => {
        setIsFading(true); // Запускаем угасание
        setTimeout(() => {
          setIsFading(false); // Отключаем угасание
          setIsActiveFlag(false); // Возвращаем в белый
        }, 2000); // Длительность угасания
      }, 0);
    }
  };

  const windowInnerWidth = document.documentElement.clientWidth;
  return (
    <Modal isActive={isActive} setActive={setActive}>
      <Container>
        <DefFlexSB>
          <div style={{ width: "15px" }} />
          <Text>
            {currentWeapon.weapon} | {currentWeapon.skin}
          </Text>
          <Icons.Close onClick={() => setActive(false)} />
        </DefFlexSB>
        <Weapon rarity={rarityConvector(+currentWeapon.rarity)}>
          <Gun src={currentWeapon?.src} />
        </Weapon>
        <Bottom>
          {/* <Info>
            <Name>
              {currentWeapon.weapon ? currentWeapon.weapon + " |" : null}
              <SubName rarity={rarityConvector(+currentWeapon.rarity)}>
                &nbsp;{currentWeapon.skin}
              </SubName>
            </Name>
          </Info> */}
          {isAvatar() && (
            <NavLink to={`/profile/${currentWeapon.steamid}`}>
              <LabelAvatar
                minName={true}
                src={currentWeapon.avatar}
                params={{
                  color: Theme.colors.white.white70,
                  avatarSize: "nano",
                }}
              >
                {currentWeapon.nickname}
              </LabelAvatar>
            </NavLink>
          )}
          {mode === 0 && (
            <BuyMode>
              <Fsdf>
                <Text>{currentWeapon.price}</Text>
                <TinyIcons.Credit />
              </Fsdf>

              <UIKIT.Button
                height="5rem"
                variant="default"
                color="blue"
                style={{ borderRadius: "1rem" }}
                onClick={() => {
                  buyItem(currentWeapon.id);
                  if (
                    user.balance.virtual - (Number(currentWeapon.price) || 0) >=
                    0
                  ) {
                    setBalance({
                      real: user.balance.real,
                      virtual:
                        user.balance.virtual -
                        (Number(currentWeapon.price) || 0),
                    });
                  } else {
                    toast.error("Недостаточно баланса");
                  }
                }}
              >
                <div
                  style={{ display: "flex", gap: "1rem", alignItems: "center" }}
                >
                  <TinyIcons.MarketCart />
                  Купить снаряжение
                </div>
              </UIKIT.Button>
            </BuyMode>
          )}
          {mode === 1 && (
            <SellMode>
              <UIKIT.Input
                // label="Введите цену"
                placeholder="Введите цену"
                maxLength={7}
                params={{
                  labelPosition: "center",
                  labelColor: Theme.colors.white.white100,
                  inputColor: Theme.colors.white.white100,
                  height: "5rem",
                }}
                onChange={(e) => {
                  updateSellingPrice(e.target.value.replace(/(?<=^|-)0+/, ""));
                  if (
                    Number(sellSkinPrice) > 1000 &&
                    Number(sellSkinPrice) < 4000000
                  ) {
                    setError(undefined);
                  }
                }}
                value={sellSkinPrice}
              />
              <TextSmall active={isActiveFlag} isFading={isFading}>
                Лимиты 1 000 - 4 000 000
              </TextSmall>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2rem",
                }}
              >
                <BlockMarketCommision>
                  <TextSmall style={{ color: "white" }}>
                    Покупатель заплатит
                  </TextSmall>
                  <BlockHr />
                  <TextSmall
                    style={{
                      color: "white",
                      alignItems: "center",
                      display: "flex",
                      gap: "0.5rem",
                    }}
                  >
                    {Number(sellSkinPrice) >= 1000 &&
                    Number(sellSkinPrice) <= 4000000
                      ? sellSkinPrice
                      : "0"}
                    <TinyIcons.Credit />
                  </TextSmall>
                </BlockMarketCommision>
                <BlockMarketCommision>
                  <TextSmall style={{ color: "white" }}>Комиссия</TextSmall>
                  <BlockHr />
                  <TextSmall
                    style={{
                      color: "white",
                      alignItems: "center",
                      display: "flex",
                      gap: "0.5rem",
                    }}
                  >
                    {Math.floor(commission || 0) || "0"}
                    <TinyIcons.Credit />
                  </TextSmall>
                </BlockMarketCommision>
                <BlockMarketCommision>
                  <TextSmallT style={{ color: "white" }}>
                    Вы получите
                  </TextSmallT>
                  <BlockHr />
                  <TextSmallT
                    style={{
                      color: "white",
                      alignItems: "center",
                      display: "flex",
                      gap: "0.5rem",
                    }}
                  >
                    {Math.floor(GetPrice || 0) || "0"}
                    <TinyIcons.Credit />
                  </TextSmallT>
                </BlockMarketCommision>
              </div>

              <UIKIT.Button
                height="5rem"
                variant="default"
                style={{ borderRadius: "1.5rem", fontWeight: "500" }}
                color="blue"
                onClick={() => {
                  const { item = 0, group = 0 } = currentWeapon.skinInfo || {};
                  const struct = {
                    steamid: currentWeapon.steamid,
                    item_id: currentWeapon.id,
                    price: sellSkinPrice,
                  };
                  if (
                    Number(sellSkinPrice) >= 1000 &&
                    Number(sellSkinPrice) <= 4000000
                  ) {
                    fetchPutItem(struct);

                    setActive(false);
                    setSellSkinPrice("");
                    setError(false);
                  } else {
                    // toast.error("Мин : 1000 Макс : 5 000 000");
                    setError(true);
                    setIsActiveFlag(true);
                    handleButtonClick();
                  }
                }}
              >
                Выставить на продажу
              </UIKIT.Button>
              <UIKIT.Button
                variant="picker"
                height={"52"}
                color="grayy"
                style={{ borderRadius: "1.5rem" }}
                params={{ width: "250px" }}
                onClick={async () => {
                  const result: { success: boolean } =
                    await CaseService.SoldItem([currentWeapon.id || 0]);
                  // console.log(result.success);
                  if (result) {
                    setBalance({
                      real: user.balance.real,
                      virtual:
                        user.balance.virtual +
                        priceRarityConvector(+currentWeapon.rarity),
                    });
                    setActive(false);
                    // priceUpdate && priceUpdate();
                    // console.log(marketInventory);
                    // marketInventory = [];
                    dispatch(filterMarketInventory(Number(currentWeapon.id)));
                    toast.success("Успешная продажа");
                  } else {
                    toast.error("Ошибка");
                  }
                }}
              >
                Быстрая продажа за {priceRarityConvector(+currentWeapon.rarity)}{" "}
                {currentWeapon.price}
                &nbsp; <TinyIcons.Credit />
              </UIKIT.Button>
            </SellMode>
          )}
          {mode === 2 && (
            <OnSellMode>
              <UIKIT.Input
                // label="Цена"
                placeholder="Введите цену"
                maxLength={7}
                value={sellSkinPrice}
                params={{
                  labelPosition: "center",
                  labelColor: Theme.colors.white.white100,
                  inputColor: Theme.colors.white.white100,
                }}
                onChange={(e) => {
                  updateSellingPrice(e.target.value);
                }}
                // value={currentWeapon.price}
              />{" "}
              <TextSmall active={isActiveFlag} isFading={isFading}>
                Лимиты 1 000 - 4 000 000
              </TextSmall>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2rem",
                }}
              >
                <BlockMarketCommision>
                  <TextSmall style={{ color: "white" }}>
                    Покупатель заплатит
                  </TextSmall>
                  <BlockHr />
                  <TextSmall
                    style={{
                      color: "white",
                      alignItems: "center",
                      display: "flex",
                      gap: "0.5rem",
                    }}
                  >
                    {Number(sellSkinPrice) >= 1000 &&
                    Number(sellSkinPrice) <= 4000000
                      ? sellSkinPrice
                      : "0"}
                    <TinyIcons.Credit />
                  </TextSmall>
                </BlockMarketCommision>
                <BlockMarketCommision>
                  <TextSmall style={{ color: "white" }}>Комиссия</TextSmall>
                  <BlockHr />
                  <TextSmall
                    style={{
                      color: "white",
                      alignItems: "center",
                      display: "flex",
                      gap: "0.5rem",
                    }}
                  >
                    {Math.floor(commission || 0) || "0"}
                    <TinyIcons.Credit />
                  </TextSmall>
                </BlockMarketCommision>
                <BlockMarketCommision>
                  <TextSmallT style={{ color: "white" }}>
                    Вы получите
                  </TextSmallT>
                  <BlockHr />
                  <TextSmallT
                    style={{
                      color: "white",
                      alignItems: "center",
                      display: "flex",
                      gap: "0.5rem",
                    }}
                  >
                    {Math.floor(GetPrice || 0) || "0"}
                    <TinyIcons.Credit />
                  </TextSmallT>
                </BlockMarketCommision>
              </div>
              <UIKIT.Button
                height="5rem"
                variant="default"
                style={{ borderRadius: "1.5rem", gap: "1rem" }}
                color="blue"
                onClick={() => {
                  if (
                    Number(sellSkinPrice) >= 1000 &&
                    Number(sellSkinPrice) <= 4000000
                  ) {
                    updateItem({
                      id: currentWeapon.id || 0,
                      price: sellSkinPrice,
                    });
                  } else {
                    // toast.error("Мин : 1000 Макс : 5 000 000");
                    setError(true);
                    setIsActiveFlag(true);
                    handleButtonClick();
                  }
                }}
              >
                <TinyIcons.EditText />
                Сохранить изменения
              </UIKIT.Button>
              <UIKIT.Button
                height="5rem"
                style={{ borderRadius: "1.5rem", gap: "1rem" }}
                variant="picker"
                color="grayy"
                onClick={() => {
                  removeItem(currentWeapon.id);
                  setSellSkinPrice("");
                }}
              >
                <TinyIcons.Trashcan />
                Снять с продажи
              </UIKIT.Button>
            </OnSellMode>
          )}
          {mode === 100 && (
            <SellMode>
              <UIKIT.Input
                label="Колличество"
                compare
                compareText={compare?.all}
                compareSet={compare?.set}
                compareGet={compare?.get}
                params={{
                  labelPosition: "center",
                  labelColor: Theme.colors.black.black100,
                  inputColor: Theme.colors.black.black100,
                }}
              />
              <UIKIT.Button
                height="5rem"
                variant="default"
                color="red"
                onClick={() => {
                  compare?.btn(currentWeapon, true);
                }}
              >
                Подтвердить
              </UIKIT.Button>
            </SellMode>
          )}
        </Bottom>
      </Container>
    </Modal>
  );
}
