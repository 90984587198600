import { asideItems } from "consts/aside";
import Image from "../Image";
import { Item, ItemOrange, Logo, StyledAside, Wrapper } from "./Styles";
import { useAppSelector } from "hooks/useAppSelector";

export default function Aside() {
  const { isAuth, user } = useAppSelector((state) => state.user);

  function extractNumbersFromUrl(url: string) {
    const match = url.match(/\/(\d+)(\/|$)/); // регулярное выражение для поиска цифр после последнего слэша

    if (match) {
      return match[1]; // match[1] содержит первую группу захвата, то есть цифры
    } else {
      return null; // Если цифры не найдены, возвращаем null
    }
  }

  return (
    <StyledAside
      className="customScroll"
      redUser={
        user?.user?.steamid64 == "76561198799170541" ||
        user?.user?.steamid64 == "76561198842913345" ||
        user?.user?.steamid64 == "76561199079069591" ||
        extractNumbersFromUrl(window.location.href) == "76561199079069591" ||
        extractNumbersFromUrl(window.location.href) == "76561198799170541" ||
        extractNumbersFromUrl(window.location.href) == "76561198842913345"
      }
    >
      <Logo>
        <Image src="/static/images/logo.svg" alt="Logo" width="55px" />
      </Logo>
      <Wrapper>
        {asideItems.map((item, index) => {
          if (item.label !== "BattlePass") {
            return (
              <Item to={item.link} key={index}>
                {item.icon}
                {item.label}
              </Item>
            );
          } else {
            return (
              <ItemOrange to={item.link} key={index}>
                {item.icon}
                {item.label}
              </ItemOrange>
            );
          }
        })}
      </Wrapper>
    </StyledAside>
  );
}
