import API from "API";
import { MarketplaceService } from "API/MarketplaceService";
import { SocketProvider, TradeAction } from "API/SocketProvider";
import { Icons } from "assets/icons";
import { TinyIcons } from "assets/tiny-icons";
import { InventoryWeaponCard } from "components/Cards";
import Layout from "components/Layout";
import Row from "components/Row";
import SubHeader from "components/SubHeader";
import Filters from "components/Templates/Filters";
import UIKIT from "components/UI";
import Button from "components/UI/Button";
import { useActions } from "hooks/useActions";
import { useAppSelector } from "hooks/useAppSelector";
import { TradeStatus } from "interfaces/Trade";
import { observer } from "mobx-react-lite";
import { IItemSend } from "models/InventoryWeaponCard";
import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import rarityConvector from "utils/rarityConvector";
import TradeColumn from "../components/TradeColumn";
import { ButtonContainer } from "../Styles";
import TradeState from "../TradeState";
import {
  Grid,
  Wait,
  Wrapper,
  WrapperColumnTrade,
  WrapperInventory,
  WrapperSwap,
  WrapperTradeRoom,
} from "./styles";

interface filters {
  id: number;
  single: boolean;
  name: string;
  items: filterItem[];
}
interface filterItem {
  id: number;
  item_id: number;
  class_ru: string;
}

const TradeRoom = () => {
  const { user } = useAppSelector((state) => state.user);
  const socketProvider = new SocketProvider();
  const isSender = TradeState.tradeData.sender;
  const navigate = useNavigate();

  /////////////////////////////////inv
  const windowInnerWidth = document.documentElement.clientWidth;
  const [_1, setFoo1] = useState("");
  const [filItem, setFilitem] = useState([""]);
  const [_, setFoo] = useState("Все");
  const [useClass, setUseClass] = useState<boolean>();
  const [classId1, setClassId] = useState<number>(1);
  const [weaponId, setWeapon] = useState<number>();
  const [raritySort, setRaritySort] = useState<string | undefined>(undefined);
  const [filters, setFilters] = useState<Array<filters>>([]);
  const { inventory } = useAppSelector((state) => state.user);
  const [useSortRarity, setUseSortRarity] = useState<boolean | undefined>(
    undefined
  );
  const [priceSort, setPriceSort] = useState<string | undefined>(undefined);

  const [useSortPrice, setUseSortPrice] = useState<boolean | undefined>(
    undefined
  );
  const { total } = useAppSelector((state) => state.user);
  const [page, setPage] = useState(1);
  const { fetchInventory } = useActions();
  const perPage: number = PageWidth();
  function PageWidth() {
    const screenWidth = window.screen.width;
    switch (true) {
      case screenWidth < 4638 && screenWidth >= 3795:
        return 51;
      case screenWidth < 3795 && screenWidth >= 3583:
        return 48;
      case screenWidth < 3583 && screenWidth >= 3371:
        return 45;
      case screenWidth < 3371 && screenWidth >= 3159:
        return 42;
      case screenWidth < 3159 && screenWidth >= 2947:
        return 39;
      case screenWidth < 2947 && screenWidth >= 2735:
        return 36;
      case screenWidth < 2735 && screenWidth >= 2523:
        return 33;
      case screenWidth < 2523 && screenWidth >= 2311:
        return 30;
      case screenWidth < 2311 && screenWidth >= 2099:
        return 27;
      case screenWidth < 2099 && screenWidth >= 1887:
        return 32;
      case screenWidth < 1887 && screenWidth >= 1675:
        return 28;
      case screenWidth < 1675 && screenWidth >= 1463:
        return 24;
      case screenWidth < 1463 && screenWidth >= 1078:
        return 25;
      case screenWidth < 1078 && screenWidth >= 861:
        return 24;
      case screenWidth < 861 && screenWidth >= 100:
        return 24;
      default:
        return 30;
    }
  }
  useEffect(() => {
    const fetchFilters = async () => {
      const filters = await MarketplaceService.getFilters();
      setFilters(filters.classes);
    };
    // PageWidth();
    fetchFilters();
  }, [1]);

  useMemo(() => {
    setPage(1);
    fetchInventory({
      steamid64: user?.user?.steamid64 || "",
      page: 1,
      classId: 1,
      perPage: perPage,
    });
  }, [user]);

  useMemo(() => {
    setPage(1);
  }, [weaponId, classId1, raritySort]);

  useMemo(() => {
    setUseClass(false);
    filters.map((item) => {
      item.items.map((i) => {
        if (i.class_ru == _1) {
          setWeapon(i.id);
        }
      });
    });
  }, [_1]);

  useMemo(() => {
    fetchInventory({
      steamid64: user?.user?.steamid64 || "",
      page: 1,
      classId: classId1,
      perPage: perPage,
      raritySort: raritySort,
      weapon: weaponId,
    });
  }, [raritySort, weaponId, classId1]);

  useMemo(() => {
    fetchInventory({
      steamid64: user?.user?.steamid64 || "",
      page: page,
      classId: classId1,
      perPage: perPage,
      raritySort: raritySort,
      weapon: weaponId,
    });
  }, [page]);

  useMemo(() => {
    setUseClass(true);
    let a;
    setFoo1("");
    filters.map((item) => {
      if (_ == item.name) {
        setClassId(item.id);
        setWeapon(undefined);
        a = item.items.map((item) => {
          return item.class_ru;
        });
      }
      return;
    });

    if (a !== null && a !== undefined) {
      setFilitem(a);
    }
  }, [_]);

  function sortRarity() {
    setPriceSort(undefined);
    setUseSortRarity(true);
    setUseSortPrice(false);
    if (raritySort == undefined) {
      setRaritySort("DESC");
    }
    if (raritySort == "ASC") {
      setRaritySort("DESC");
    }
    if (raritySort == "DESC") {
      setRaritySort("ASC");
    }
  }
  /////////////////////////////////////

  const isFirstModalState =
    TradeState.tradeData.status == TradeStatus.NoConfirmed ||
    TradeState.tradeData.status == TradeStatus.SenderAgree ||
    TradeState.tradeData.status == TradeStatus.ReceiverAgree;

  const cancel = () => {
    API.TradeService.cancel(TradeState.tradeData.uuid);
    // fetchCancelTrade(trade.uuid)
    //console.log(TradeState.tradeData.uuid);
    // setShowModal(false);
  };

  const handleLeftItem = (item: IItemSend) => {
    // if (isFirstModalState) {
    socketProvider.changeItem(
      TradeState.tradeData.uuid,
      item.id,
      TradeAction.ADD
    );
    // }
  };

  const handleRightItem = (item: IItemSend) => {
    if (isFirstModalState) {
      socketProvider.changeItem(
        TradeState.tradeData.uuid,
        item.id,
        TradeAction.DELETE
      );
    }
  };

  const confirmToggle = () => {
    if (!isFirstModalState) {
      return socketProvider.apply(TradeState.tradeData.uuid);
    }

    socketProvider.confirmationTrade(TradeState.tradeData.uuid);
  };

  const renderFooter = () => {
    const getFooterText = () => {
      if (TradeState.tradeData.status == TradeStatus.SenderAgree) {
        if (isSender) {
          return <Wait>Ожидание партнёра...</Wait>;
        } else {
          return <Wait>Партнёр отправил предложение и ожидает вас.</Wait>;
        }
      }

      if (TradeState.tradeData.status == TradeStatus.ReceiverAgree) {
        if (isSender) {
          return <Wait>Партнёр отправил предложение и ожидает вас.</Wait>;
        } else {
          return <Wait>Ожидание партнёра...</Wait>;
        }
      }

      if (
        TradeState.tradeData.status == TradeStatus.BothAgree &&
        isFirstModalState
      ) {
        return <Wait>Предложения приняты, ожидайте...</Wait>;
      }
      if (TradeState.tradeData.status == TradeStatus.SenderApplied) {
        if (isSender) {
          return <Wait>Ожидание партнёра...</Wait>;
        } else {
          return (
            <Wait>
              Партнёр подтвердил обмен. Ожидание вашего подтверждения.
            </Wait>
          );
        }
      }

      if (TradeState.tradeData.status == TradeStatus.ReceiverApplied) {
        if (isSender) {
          return (
            <Wait>
              Партнёр подтвердил обмен. Ожидание вашего подтверждения.
            </Wait>
          );
        } else {
          return <Wait>Ожидание партнёра...</Wait>;
        }
      }

      return "";
    };

    return (
      <div>
        {getFooterText()}
        <ButtonContainer>
          <UIKIT.Button
            style={{ width: "30rem" }}
            color="blue"
            height="5rem"
            variant="default"
            onClick={confirmToggle}
            disabled={
              (TradeState.tradeData.status === TradeStatus.SenderAgree &&
                isSender !== undefined) ||
              (TradeState.tradeData.status === TradeStatus.SenderApplied &&
                isSender !== undefined) ||
              (TradeState.tradeData.status === TradeStatus.ReceiverApplied &&
                isSender === undefined) ||
              (TradeState.tradeData.status === TradeStatus.ReceiverAgree &&
                isSender === undefined)
            }
          >
            Отправить предложение
          </UIKIT.Button>
          <UIKIT.Button
            style={{ width: "30rem" }}
            color="red"
            height="5rem"
            variant="default"
            onClick={() => {
              cancel();
              navigate("/");
            }}
          >
            Отменить
          </UIKIT.Button>
        </ButtonContainer>
      </div>
    );
  };
  <Helmet>
    <title>FREEZ-BLOOD - Обмены</title>
    <meta name="description" content={`FREEZ-BLOOD - Обмены`} />
  </Helmet>;

  const [idTradeInv, setIdTradeInv] = useState<Array<number>>([]);

  useEffect(() => {
    if (TradeState) {
      const newIds = TradeState.tradeData.myItems.map((item) => item.id);
      setIdTradeInv(newIds);
    }
    // console.log("TradeState.tradeData.myItems", TradeState.tradeData.myItems);
  }, [TradeState.tradeData.myItems]);

  useEffect(() => {
    // console.log("idTradeInv", idTradeInv);
  }, [idTradeInv]);

  const [actPage, setActPafe] = useState<boolean>(true);

  useEffect(() => {
    setActPafe(isFirstModalState);
  }, [isFirstModalState]);

  if (TradeState.tradeData.uuid) {
    return (
      <Layout
        headerElement={<div />}
        leftElement={<div />}
        total={total}
        setPage={setPage}
        page={page}
        perPage={perPage}
        activePages={actPage}
      >
        <Helmet>
          <title>FREEZ-BLOOD - Обмены</title>
          <meta name="description" content="FREEZ-BLOOD -Обмены" />
        </Helmet>
        <WrapperTradeRoom>
          <WrapperColumnTrade>
            <TradeColumn
              avatar={user?.user?.avatar}
              kolItem={TradeState.tradeData.myItems.length}
              text={"Мои Предметы"}
              data={TradeState.tradeData.myItems}
              onClick={handleRightItem}
            />
            <WrapperSwap>
              {isFirstModalState ? <Wait>1 этап</Wait> : <Wait>2 этап</Wait>}
              <Icons.Swap />
            </WrapperSwap>

            <TradeColumn
              avatar={TradeState.tradeData.avatar}
              kolItem={TradeState.tradeData.receivedItems.length}
              text={`${TradeState.tradeData.name}`}
              data={TradeState.tradeData.receivedItems}
              // onClick={handleLeftItem}
            />
          </WrapperColumnTrade>
          {renderFooter()}
          {isFirstModalState ? (
            <WrapperInventory>
              <Wrapper>
                <Filters params={{ width: "unset" }}>
                  <UIKIT.DropDown
                    onChange={setFoo}
                    setUse={useClass}
                    items={filters.map((item) => {
                      return item.name;
                    })}
                    headParams={{
                      title: "Класс:",
                      icon: <TinyIcons.Arrow />,
                    }}
                  />
                  {_ !== undefined && _ !== "Все" && _ !== "Агенты" ? (
                    <UIKIT.DropDown
                      onChange={setFoo1}
                      items={filItem}
                      setUse={useClass}
                      headParams={{
                        title: "Тип:",
                        icon: <TinyIcons.Arrow />,
                      }}
                    />
                  ) : null}

                  <Row
                    gap="3rem"
                    fullWidth
                    justifyContent="space-between"
                    style={
                      windowInnerWidth > 1250
                        ? {
                            minWidth: "38.1rem",
                            maxWidth: "43.2rem",
                          }
                        : {
                            minWidth: "",
                            maxWidth: "",
                          }
                    }
                  >
                    <Button
                      height="0"
                      variant="Sort"
                      params={{ width: "0", padding: "0" }}
                      color="Gray"
                      onClick={() => sortRarity()}
                    >
                      <UIKIT.DropDown
                        onChange={null}
                        useSort={useSortRarity}
                        items={["rar"]}
                        headParams={{
                          title: "Редкость:",
                          icon: <TinyIcons.Arrow />,
                        }}
                      />
                    </Button>
                  </Row>
                </Filters>
              </Wrapper>
              <Grid>
                {inventory.map((item, index) => {
                  if (!idTradeInv.includes(item.id)) {
                    return (
                      <InventoryWeaponCard
                        key={index}
                        src={item.src}
                        rarity={rarityConvector(+item.rarity)}
                        weapon={item.weapon}
                        skin={item.skin}
                        cl={false}
                        counter={item.skinInfo.amount}
                        onClick={() => handleLeftItem(item)}
                      />
                    );
                  } else {
                    return (
                      <InventoryWeaponCard
                        key={index}
                        src={item.src}
                        rarity={"green"}
                        weapon={item.weapon}
                        skin={item.skin}
                        cl={false}
                        counter={item.skinInfo.amount}
                        onClick={() => handleLeftItem(item)}
                      />
                    );
                  }
                })}
              </Grid>
            </WrapperInventory>
          ) : null}
        </WrapperTradeRoom>
      </Layout>
    );
  } else {
    return (
      <Layout
        headerElement={<SubHeader>Комната обмена</SubHeader>}
        leftElement={<div />}
      >
        <SubHeader>Обмен либо завершен, либо еще не начат...</SubHeader>
      </Layout>
    );
  }
};

export default observer(TradeRoom);
