import Layout from "components/Layout";
import Button from "components/UI/Button";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { HeaderWrap, Text, Title, Wrapper, WrapTitle } from "./Styles";

export default function Offer() {
  const navigate = useNavigate();
  const windowInnerWidth = document.documentElement.clientWidth;
  const windowInnerHeight = document.documentElement.clientHeight;
  return (
    <Layout leftElement={<div />} headerElement={<div />}>
      <Helmet>
        <title>FREEZ-BLOOD - Подсказки</title>
        <meta name="description" content={`FREEZ-BLOOD - Контакты`} />
      </Helmet>
      <HeaderWrap>
        {/* <UIKIT.ArrowBackMoney /> */}
        {/* <SubHeader>Договор – оферта</SubHeader> */}
        <Button
          variant="picker"
          height="5rem"
          onClick={() => navigate("/rules")}
        >
          Правила
        </Button>
        <Button
          variant="picker"
          height="5rem"
          onClick={() => navigate("/rules/offer")}
        >
          Оферта
        </Button>
        <Button
          variant="picker"
          height="5rem"
          onClick={() => navigate("/rules/PrivatePolicy")}
        >
          Политика конфидициальности
        </Button>
        <Button
          variant="picker"
          height="5rem"
          onClick={() => navigate("/rules/contact")}
        >
          Контакты
        </Button>
        <Button variant="picker" height="5rem" className="active">
          Подсказки
        </Button>
      </HeaderWrap>
      <Wrapper>
        <WrapTitle>Подсказки</WrapTitle>

        <Title>Контракты</Title>
        <Text>
          Это система улучшения снаряжения, в которой вы гарантированно получите
          снаряжение.
        </Text>
        <Text>
          Для создания контракта вам понадобится 5 предметов одинаковой редкости
        </Text>
        <Text>Ширпотреб → Промышленное</Text>
        <Text>Промышленное → Армейское</Text>
        <Text>Армейское качество → Запрещенное</Text>
        <Text>Запрещенное → Засекреченное</Text>
        <Text>Засекреченное → Тайное</Text>
        <Text>Тайное → Тайное/Контрабанда(нож) 50/50</Text>
        <Text>Контрабанда(ножи) → Контрабанда(нож)</Text>
        <Text>Перчатки → Перчатки</Text>
        <Title>Торговая площадка</Title>
        <Text>Комиссия - 10%</Text>
      </Wrapper>
      <Title>Подписка на VIP/PREMIUM</Title>
      <Text>
        Автоматическая попытка продления подписки на привилегию происходит,
        когда действие подписки заканчивается.
      </Text>
    </Layout>
  );
}
