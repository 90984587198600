import { SupportServise } from "API/SupportServise";
import AppLink from "components/AppLink";
import Layout from "components/Layout";
import SupportModal from "components/Modal/Support/Support";
// import { StatusVisual } from "components/StatusVisual/StatusVisual";
import UserSupportBlock from "components/Templates/UserSupportBlock";
import { useAppSelector } from "hooks/useAppSelector";
import { MyBans, TicketTypes } from "interfaces/Support";
import { ITicket, TicketAndBlockStatus } from "models/Ticket";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import {
  Body,
  BodyItem,
  BodyRow,
  Head,
  Header,
  HeadItem,
  SubHeader,
  Tickets,
  TicketStatus,
  Wrapper,
  WrapperObr,
} from "./Styles";
import { endpoints, url } from "API";

export default function Support() {
  const Theme = useTheme();
  const [modal, setModal] = useState<boolean>(false);
  const [idModal, setIdModal] = useState<boolean>(false);
  const [myBans, setMyBans] = useState<MyBans[]>([]);
  const [myTicket, setMyTicket] = useState<ITicket[]>([]);
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.user);

  useEffect(() => {
    async function getU() {
      setMyBans(await SupportServise.GetBansMy());
      setMyTicket(await SupportServise.GetMyTicket());
    }

    getU();
  }, [1]);

  function formatDate(timestampString: string) {
    const months = [
      "января",
      "февраля",
      "марта",
      "апреля",
      "мая",
      "июня",
      "июля",
      "августа",
      "сентября",
      "октября",
      "ноября",
      "декабря",
    ];

    const date = new Date(timestampString);
    date.setHours(date.getHours() - 3);

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day} ${month} ${year}, ${hours}:${minutes}`;
  }

  // useEffect(() => {
  //   console.log(myTicket);
  // }, [myTicket]);
  function CheckStatus(stat: TicketAndBlockStatus, act?: boolean) {
    if (act && stat == TicketAndBlockStatus.CLOSED) {
      return "Не разблокирован";
    }
    if (stat == TicketAndBlockStatus.CLOSED) {
      return "Решен";
    }
    if (stat == TicketAndBlockStatus.IN_PROGRESS) {
      return "На рассмотрении";
    }
    if (stat == TicketAndBlockStatus.CREATED) {
      return "На рассмотрении";
    }
    if (stat == TicketAndBlockStatus.DELETED) {
      return "Закрыт";
    }
    if (stat == TicketAndBlockStatus.UNBANNED) {
      return "Разбанен";
    }
  }

  function redirectToFullTicket(id: number | string) {
    navigate(`/tickets/${id}`);
  }

  // useEffect(() => {
  //   console.log(idModal);
  // }, [idModal]);

  const { isAuth } = useAppSelector((state) => state.user);
  function loginHandler() {
    window.location.href = url + endpoints.auth.login;
  }

  return (
    <Layout leftElement={<div />} headerElement={<div />}>
      <Helmet>
        <title>FREEZ-BLOOD - Поддержка</title>
        <meta name="description" content="FREEZ-BLOOD -Поддержка" />
      </Helmet>
      <Wrapper>
        {/* <SubHeader>Поддержка</SubHeader> */}
        {isAuth ? (
          <UserSupportBlock
            title={
              <>
                Вы можете найти ответы на многие вопросы{" "}
                <AppLink href="/rules">здесь</AppLink> . Если же этого не
                случилось, то создайте тикет.
              </>
            }
            buttonText="Создать обращение"
            params={{ titleFont: Theme.fonts.GothamRegular20 }}
            callback={() => {
              setModal(true);
              setIdModal(false);
            }}
            callback2={() => {
              setModal(true);
              setIdModal(true);
            }}
          />
        ) : (
          <UserSupportBlock
            title={
              <>
                Вы можете найти ответы на многие вопросы{" "}
                <AppLink href="/rules">здесь</AppLink> . Если же этого не
                случилось, то создайте тикет.
              </>
            }
            buttonText="Авторизоваться"
            params={{ titleFont: Theme.fonts.GothamRegular20 }}
            callback={() => {
              loginHandler();
            }}
            callback2={() => {
              loginHandler();
            }}
          />
        )}
        <WrapperObr>
          {/* <NotSended>Вы не создавали тикетов</NotSended> */}
          {myTicket && myTicket?.length ? (
            <>
              {" "}
              <Header>Ваши обращения</Header>
              <Tickets>
                <Head>
                  <HeadItem>Тема</HeadItem>
                  <HeadItem>Статус</HeadItem>
                  <HeadItem>Дата</HeadItem>
                </Head>
                {myTicket?.map((item, index) => {
                  if (item.type == TicketTypes.HELP)
                    return (
                      <Body onClick={() => redirectToFullTicket(item.id)}>
                        <BodyRow key={item.id}>
                          <BodyItem>{item.topic}</BodyItem>
                          <TicketStatus status={item.status}>
                            {CheckStatus(item.status)}
                          </TicketStatus>
                          <BodyItem>{formatDate(item.date)}</BodyItem>
                        </BodyRow>
                      </Body>
                    );
                })}
              </Tickets>
              <Header>Заявки на разблокировку</Header>
              <Tickets>
                <Head>
                  <HeadItem>Ник на сервере</HeadItem>
                  <HeadItem>Статус</HeadItem>
                  <HeadItem>Дата</HeadItem>
                </Head>
                {myTicket &&
                  myTicket.map((item, index) => {
                    if (item.type == TicketTypes.BLOCK)
                      return (
                        <Body onClick={() => redirectToFullTicket(item.id)}>
                          <BodyRow key={item.id}>
                            <BodyItem>{item?.user?.name || ""}</BodyItem>
                            <TicketStatus status={item.status}>
                              {CheckStatus(item.status, true)}
                            </TicketStatus>
                            <BodyItem>{formatDate(item.date)}</BodyItem>
                          </BodyRow>
                        </Body>
                      );
                  })}
              </Tickets>
            </>
          ) : (
            <SubHeader>Вы не создавали тикеты</SubHeader>
          )}
        </WrapperObr>
      </Wrapper>

      {user.user ? (
        <SupportModal setActive={setModal} isActive={modal} idModal={idModal} />
      ) : (
        <></>
      )}
    </Layout>
  );
}
