export default function BattlPass({
  ...props
}: React.HTMLAttributes<SVGElement>) {
  return (
    <svg
      width="26"
      height="18"
      viewBox="0 0 26 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 1L18.3333 9L25 3.66667L22.3333 17L3.66667 17L1 3.66667L7.66667 9L13 1Z"
        fill="#EFEFEF"
        fillOpacity="0.7"
        strokeOpacity="0.7"
        stroke="#EFEFEF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        {...props}
      />
    </svg>
  );
}
