import { Icons } from "assets/icons";
import { memo, useEffect, useState } from "react";
import Button from "../../UI/Button";
import {
  Auth,
  AvatarWrapper,
  Balance,
  Card,
  Highlighted,
  NonAuth,
  WrapperMenuProfile,
  Menu,
  WrapperProfile,
  Title,
  WrapperTitle,
  WrapperItem,
  DefFlex,
  Name,
  Dot,
  BuyMoneyText,
} from "./Styles";
import Avatar from "components/Avatar";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppSelector } from "hooks/useAppSelector";
import { endpoints, url } from "API";
import { useActions } from "hooks/useActions";
import { TinyIcons } from "assets/tiny-icons";
import { UserService } from "API/UserService";
import { useCookies } from "react-cookie";
import ym from "react-yandex-metrika";
import UIKIT from "components/UI";

function HeaderUserCard() {
  const { isAuth, user } = useAppSelector((state) => state.user);
  const [activMenu, setActiveMenu] = useState<boolean>(false);
  const { fetchLogin } = useActions();
  const [cookie, setCookie, removeCookie] = useCookies(["authentication"]);

  function loginHandler() {
    window.location.href = url + endpoints.auth.login;
  }

  useEffect(() => {
    if (!isAuth) {
      fetchLogin();
    }
  }, []);
  const navigate = useNavigate();

  // function removeCookie(arg0: string) {
  //   throw new Error("Function not implemented.");
  // }

  return (
    <Card>
      {isAuth ? (
        <Auth>
          <Balance>
            <DefFlex>
              {user.balance.real}&nbsp;
              <Highlighted>
                <TinyIcons.Rub />
              </Highlighted>
            </DefFlex>
            <Dot />
            <DefFlex>
              {user.balance.virtual}&nbsp;
              <Highlighted>
                <TinyIcons.Credit />
              </Highlighted>
            </DefFlex>
          </Balance>

          <AvatarWrapper>
            <UIKIT.Button
              color="blue"
              variant="default"
              height="4rem"
              style={{
                borderRadius: "1.2rem",
                marginLeft: "1rem",
                padding: "1rem",
              }}
              onClick={() => navigate("/purse")}
            >
              <Icons.Add />
              <BuyMoneyText>Пополнить</BuyMoneyText>
            </UIKIT.Button>
            {/* <NavLink to="/purse">
              <Icons.Wallet />
            </NavLink> */}

            <WrapperMenuProfile
              Active={true}
              onClick={() => setActiveMenu(!activMenu)}
            >
              <Avatar size="small" src={user.user.avatar} />
            </WrapperMenuProfile>
          </AvatarWrapper>
          <WrapperMenuProfile
            Active={activMenu}
            onClick={() => setActiveMenu(!activMenu)}
          >
            <TinyIcons.Arrow />
          </WrapperMenuProfile>
          <Menu Active={activMenu} onClick={(e) => e.stopPropagation()}>
            <WrapperTitle
              onClick={() => navigate(`/profile/${user.user.steamid64}`)}
            >
              <Avatar size="small" src={user.user.avatar} />
              <WrapperProfile>
                <Title>{user.user.name}</Title>
                <Name>Перейти в профиль</Name>
              </WrapperProfile>
            </WrapperTitle>
            <WrapperItem
              onClick={() =>
                navigate(`/profile/${user.user.steamid64}/inventory`)
              }
            >
              Инвентарь
            </WrapperItem>
            <WrapperItem
              onClick={() =>
                navigate(`/profile/${user.user.steamid64}/skinchanger`)
              }
            >
              Управление снаряжением
            </WrapperItem>
            <WrapperItem
              onClick={() =>
                navigate(`/profile/${user.user.steamid64}/privileges`)
              }
            >
              Привилегии
            </WrapperItem>
            <hr />
            <WrapperItem
              onClick={() => {
                window.location.reload();
                localStorage.clear();

                // window.location.reload();
              }}
            >
              Выйти
            </WrapperItem>
          </Menu>
        </Auth>
      ) : !localStorage.token ? (
        <NonAuth>
          <Button
            height="5rem"
            variant="default"
            style={{ borderRadius: "1.2rem" }}
            color="blue"
            rightIcon={<Icons.Steam />}
            onClick={() => {
              loginHandler();
              ym("96449339", "reachGoal", "login");
            }}
          >
            Войти
          </Button>
        </NonAuth>
      ) : null}
    </Card>
  );
}

export default memo(HeaderUserCard);
