import styled from "styled-components";

export const WrapperBlockRep = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  padding: 2rem;
  background: ${(props) => props.theme.gradients.shadow};
  border-radius: 2rem;
  height: max-content;
  @media (max-width: 1900px) {
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  @media (max-width: 400px) {
    justify-content: center;
    align-items: center;
    /* padding: 2rem 1rem; */
    width: max-content;
    width: 100%;
  }
`;

export const Title = styled.div`
  ${(props) => props.theme.fonts.GothamBold20}
`;

export const Flex = styled.div<{ act?: Boolean }>`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: ${(props) => (props.act ? "left" : "center")};
  @media (max-width: 1000px) {
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: ${(props) => (props.act ? "row" : "column")}; */
    justify-content: ${(props) => (props.act ? "left" : "center")};
  }
`;

export const Flex2 = styled.div`
  display: flex;
  gap: 3rem;
  @media (max-width: 400px) {
    flex-direction: column;
  }
`;

export const WrapperReplenishment = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  @media (max-width: 1900px) {
    justify-content: center;
    align-items: center;
    /* width: 100%; */
    /* margin-left: 0.5rem; */
    flex-wrap: wrap;
  }

  @media (max-width: 768px) {
    margin-bottom: 7rem;
  }
`;

export const WrapBlockRep = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: max-content;
  min-width: 45rem;
  @media (max-width: 1900px) {
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  @media (max-width: 768px) {
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: auto;
  }
`;

export const WrapBonuse = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background: ${(props) => props.theme.gradients.shadow};
  border-radius: 2rem;
  padding: 3rem;
  /* align-items: center; */
  @media (max-width: 1900px) {
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

export const WrapBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
export const TextGreen = styled.span`
  ${(props) => props.theme.fonts.GothamMedium32};
  color: ${(props) => props.theme.colors.green.selected};
`;

export const Textt = styled.span`
  ${(props) => props.theme.fonts.GothamMedium20}
`;

export const WrapBuy = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 3rem;
  gap: 1.5rem;

  background: ${(props) => props.theme.gradients.shadow};
  border-radius: 2rem;
  @media (max-width: 400px) {
    padding: 1rem;
  }
`;

export const TextWhj = styled.span`
  ${(props) => props.theme.fonts.GothamMedium16}
  opacity:0.5;
  /* color:${(props) => props.theme.colors.white.white20} */
`;
export const Offer = styled.span`
  display: flex;
  cursor: pointer;
  align-items: center;
  width: max-width;
  gap: 2rem > div {
    margin: 0 20px 0 0;
  }
`;

export const Textttt = styled.span<{ active?: boolean; color?: string }>`
  /* margin-left: 1rem; */
  ${(props) =>
    props.active
      ? props.theme.fonts.GothamBold24
      : props.theme.fonts.GothamRegular16};
  color: ${(props) =>
    props.color ? props.color : props.theme.colors.white.white70};

  margin-bottom: ${(props) => (props.active ? "30rem" : "")};
`;

export const BlueText = styled.span<{ active?: boolean; color?: string }>`
  ${(props) =>
    props.active
      ? props.theme.fonts.GothamBold24
      : props.theme.fonts.GothamRegular16};
  color: ${(props) =>
    props.color ? props.color : props.theme.colors.white.white70};

  margin-bottom: ${(props) => (props.active ? "30rem" : "")};
`;
