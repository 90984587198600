import { BanServise } from "API/BanServise";
import { Icons } from "assets/icons";
import Column from "components/Column";
import Image from "components/Image";
import Layout from "components/Layout";
import LayoutIcon from "components/LayoutIcon";
import BanModal from "components/Modal/Ban";
import SubHeader from "components/SubHeader";
import UIKIT from "components/UI";
import { useAppSelector } from "hooks/useAppSelector";
import { FullAdmins, OneBan } from "interfaces/Bans";
import { Violation, ViolationType } from "models/Violation";
import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { getBansAndMutesTitle } from "utils/getBansAndMutesTitle";
import {
  BlockFilter,
  BlockItemFilter,
  Body,
  BodyElement,
  BodyItem,
  Duration,
  Filters,
  Head,
  HeadItem,
  HeadItemAdmin,
  Reason,
  Server,
  Table,
  UserItem,
  UserNameItem,
  Wrapper,
} from "./Styles";
import { useActions } from "hooks/useActions";

interface SortType {
  label: string;
  value: ViolationType;
}

interface banAndMut {
  admin: string;
  created_at: string;
  expired_at: string;
}

export default function BansAndMutes() {
  const [mode, setMode] = useState(0);
  // const [deedm, setdedde] = useState([]);
  const [modal, setModal] = useState<boolean>(false);
  const [violationType, setViolationType] = useState<SortType>({
    label: "",
    value: "ban",
  });
  const [serverOne, setServer] = useState<any>();
  const [current, setCurrent] = useState<Violation>({} as Violation);
  const [applicationType, setApplicationType] = useState(null);
  const { isAdmin } = useAppSelector((state) => state.user.user);
  const { servers } = useAppSelector((state) => state.servers);
  const [fullbans, setFullBans] = useState<FullAdmins>();
  const [searhUser, setsearhUser] = useState<string>("");
  const [searhUserSteam, setsearhUserSteam] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [activeBans, setActiveBans] = useState<OneBan>();
  const { fetchServers, setShowModal, setHistoryShowModal, setBalance } =
    useActions();

  useEffect(() => {
    // console.log(BanServise.getBans());
    async function getInf() {
      const index = await BanServise.getBans(
        page,
        mode == 0 ? "ban" : "voice_mute"
      );
      setFullBans(index);
    }
    getInf();
    setsearhUser("");
    setsearhUserSteam("");
    fetchServers();
  }, [1]);

  useMemo(() => {
    servers.forEach(async (item) => {
      if (item.server_name == serverOne) {
        const index = await BanServise.getBans(
          page,
          mode == 0 ? "ban" : "voice_mute",
          Number(item.id)
        );
        setFullBans(index);
      }
    });
    setsearhUser("");
    setPage(1);
    setsearhUserSteam("");
  }, [serverOne]);

  useMemo(() => {
    async function getInf() {
      const index = await BanServise.getBans(
        1,
        mode == 0 ? "ban" : "voice_mute"
      );
      setFullBans(index);
      setPage(1);
    }
    getInf();
    setsearhUser("");
    setsearhUserSteam("");
  }, [mode]);

  useMemo(() => {
    async function getInf() {
      if (serverOne !== undefined) {
        servers.forEach(async (item) => {
          if (item.server_name == serverOne) {
            const index = await BanServise.getBans(
              page,
              mode == 0 ? "ban" : "voice_mute",
              Number(item.id)
            );
            setFullBans(index);
          }
        });
      } else {
        const index = await BanServise.getBans(
          page,
          mode == 0 ? "ban" : "voice_mute"
        );
        setFullBans(index);
      }
    }

    setsearhUser("");
    setsearhUserSteam("");
    getInf();
    // console.log("lox");
  }, [page]);
  async function GiveOneUser() {
    const index = await BanServise.getBans(
      1,
      mode == 0 ? "ban" : "voice_mute",
      undefined,
      searhUser || undefined,
      searhUserSteam || undefined
    );
    setFullBans(index);
    setPage(1);
    return;
  }

  function setModePage(index: number, sortType: SortType) {
    setMode(index);
    setViolationType(sortType);
  }
  const { isAuth, user } = useAppSelector((state) => state.user);

  // console.log(user);

  const UsersMemo = useMemo(() => {
    // console.log("LOOOx");
    return fullbans?.blocks.map((item: OneBan) => (
      // onClick={() => setCurrentBan(index)}
      <BodyElement
        key={item.id}
        onClick={() => {
          setActiveBans(item);
          setModal(true);
        }}
      >
        <UserNameItem act={mode == 0 ? true : false}>
          {item.banned.name || "Игрок"}
        </UserNameItem>
        {serverOne ? null : <Server>{item.server.name}</Server>}
        <BodyItem>
          {item.date.slice(0, 10)}&ensp;{item.date.slice(11, 16)}
        </BodyItem>
        <Duration>
          {item.expiration.slice(0, 10)}&ensp;
          {item.expiration.slice(11, 16)}
        </Duration>
        {mode == 0 ? null : <BodyItem>{item.banned.type}</BodyItem>}

        <Reason nonBan={item.banned.state == "banned" ? false : true}>
          {item.reason}
        </Reason>
        <HeadItemAdmin>
          <Image
            src={item.admin.avatar}
            alt="avatar"
            width="30px"
            height="30px"
          />
          {item.admin.name}
        </HeadItemAdmin>
      </BodyElement>
    ));
  }, [fullbans]);

  if (user) {
    return (
      <Layout
        headerElement={<div />}
        setPage={setPage}
        total={fullbans?.total}
        perPage={15}
        page={page}
        activePages={true}
        leftElement={<div />}
      >
        <BlockFilter>
          <BlockItemFilter
            active={mode === 0 ? true : false}
            onClick={() => setModePage(0, { label: "Баны", value: "ban" })}
          >
            Баны
          </BlockItemFilter>
          <BlockItemFilter
            active={mode === 1 ? true : false}
            onClick={() =>
              setModePage(1, { label: "Муты", value: "voice_mute" })
            }
          >
            Муты
          </BlockItemFilter>
        </BlockFilter>
        <Wrapper>
          <Filters>
            <>
              {mode === 2 && (
                <UIKIT.Select
                  items={[
                    { label: "Муты", value: "voice_mute" },
                    { label: "Баны", value: "ban" },
                  ]}
                  label={"Тип"}
                  params={{ width: "100%", headHeight: "5rem" }}
                  onChange={setApplicationType}
                />
              )}
              <UIKIT.Select
                items={servers.map((item) => {
                  return item.server_name;
                })}
                label={"Сервер"}
                params={{ width: "100%", headHeight: "5rem" }}
                onChange={setServer}
                defaultValue="Все"
              />
              <UIKIT.Input
                label="Поиск"
                params={{ width: "100%", height: "5rem" }}
                placeholder="Ник игрока"
                value={searhUser}
                onChange={(e) => setsearhUser(e.target.value)}
              />
              <UIKIT.Input
                params={{ width: "100%", height: "5rem" }}
                placeholder="Steam id игрока"
                value={searhUserSteam}
                onChange={(e) => setsearhUserSteam(e.target.value)}
              />
              <UIKIT.Button
                variant="picker"
                height="5rem"
                color="blue"
                onClick={() => {
                  let conditionMet = false;
                  if (serverOne) {
                    servers.forEach(async (item) => {
                      if (conditionMet) return;
                      if (item.server_name == serverOne) {
                        if (searhUser !== "" || searhUserSteam !== "") {
                          const index = await BanServise.getBans(
                            1,
                            mode == 0 ? "ban" : "voice_mute",
                            Number(item.id),
                            searhUser || undefined,
                            searhUserSteam || undefined
                          );
                          setFullBans(index);
                          setPage(1);
                          conditionMet = true;
                        } else {
                          const index = await BanServise.getBans(
                            1,
                            mode == 0 ? "ban" : "voice_mute",
                            Number(item.id),
                            searhUser || undefined,
                            searhUserSteam || undefined
                          );
                          setFullBans(index);
                          setPage(1);
                          conditionMet = true;
                        }
                      }
                    });
                  } else {
                    GiveOneUser();
                    return;
                  }
                }}
              >
                Поиск
              </UIKIT.Button>
            </>
          </Filters>
          <Helmet>
            <title>FREEZ-BLOOD - Баны-муты</title>
            <meta name="description" content={`Баны-муты`} />
            <meta name="robots" content="noindex, nofollow"></meta>
          </Helmet>
          <Table>
            <Head>
              <UserItem act={mode == 0 ? true : false}>Ник</UserItem>
              {!serverOne ? <HeadItem act>Сервер</HeadItem> : null}
              <HeadItem>Дата</HeadItem>

              <HeadItem>Срок</HeadItem>
              {mode == 0 ? null : <HeadItem>Идентификатор</HeadItem>}
              <HeadItem>Причина</HeadItem>
              <HeadItem>Забанил</HeadItem>
            </Head>
            <Body>{UsersMemo}</Body>
          </Table>
        </Wrapper>
        <BanModal
          isActive={modal}
          setActive={setModal}
          violation={current}
          activeBan={activeBans}
          fullBan={fullbans}
          setFullBans={setFullBans}
        />
      </Layout>
    );
  } else {
    return null;
  }
}
