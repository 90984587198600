import Chat from "modules/Chat";
import React, {
  memo,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Header from "../Header";
import {
  AsideITEMBlock,
  BlockAsideModal,
  BlockFullAside,
  BlockItemIcon,
  ChatSpan,
  ChatWrapper,
  Container,
  FooterMain,
  Item,
  ItemIcons,
  ItemModal,
  LeftItemFooter,
  MediumSpan,
  MediumSpanLink,
  RightitemFooter,
  // ScrollWrapper,
  StyledLayout,
  ToggleChat,
  WrapChatItem,
  WrapExtrItem,
  WrapItem,
  WrapMobile,
  Wrapper,
  WrapSmallWidth,
} from "./Styles";

import { appSocket } from "App";
import Toast from "components/UI/Toast";
import { useAppSelector } from "hooks/useAppSelector";

import { ChatService } from "API/ChatService";
import { Icons } from "assets/icons";
import { TinyIcons } from "assets/tiny-icons";
import Pagination from "components/PagePagination/PagePagination";
import { asideItems, footerAsideItem } from "consts/aside";
import { IMessage } from "models/Message";
import Image from "../Image";
import { StandartFlex } from "components/Modal/Clans/CreateClans/styled";

interface Props {
  children: any;
  headerElement?: React.ReactElement | string;
  footerElement?: React.ReactElement | string;
  leftElement?: React.ReactElement | string;
  activePages?: boolean;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  total?: number;
  page?: number;
  nonChat?: boolean;
  perPage?: number;
  NoneGridConteiner?: boolean;
}

function Layout({
  children,
  activePages,
  total,
  page,
  setPage,
  nonChat,
  perPage,
  NoneGridConteiner,
  headerElement,
  footerElement,
  leftElement,
}: Props) {
  const { user } = useAppSelector((state) => state.user);

  const [historyChatOnly, setHistoryChatOnly] = useState<IMessage[]>([]);
  const [activeAside, setActiveAside] = useState<boolean>(false);

  useEffect(() => {
    appSocket.on("recMessage", (data: IMessage) => {
      setHistoryChatOnly((prevHistory) => {
        const newHistory = [...prevHistory, data];
        if (newHistory.length > 100) {
          newHistory.shift(); // Удаляет первый элемент массива, если длина превысила 100
        }
        return newHistory;
      });
    });
    async function Mes() {
      setHistoryChatOnly(await ChatService.getHistory());
    }
    Mes();
  }, [1]);

  const [isChatActive, setChatActive] = useState(false);
  const windowInnerWidth = document.documentElement.clientWidth;

  if (activeAside) {
    return (
      <BlockFullAside Active={activeAside}>
        <StandartFlex>
          <Image src="/static/images/logo.svg" alt="Logo" width="35px" />
          <div style={{ width: "26px" }} onClick={() => setActiveAside(false)}>
            <Icons.Close />
          </div>
        </StandartFlex>
        <BlockAsideModal>
          {asideItems.map((item, index) => {
            return (
              <ItemModal to={item.link} key={index} Active={activeAside}>
                {item.icon}
                {item.label}
              </ItemModal>
            );
          })}
        </BlockAsideModal>
      </BlockFullAside>
    );
  } else {
    return (
      <>
        <StyledLayout>
          <Header>
            <div />
          </Header>
          <Container
            isChatActive={isChatActive}
            NoneGridConteiner={NoneGridConteiner}
          >
            {windowInnerWidth > 1250 ? leftElement : ""}

            {children}
            {user ? (
              <ChatWrapper isChatActive={isChatActive}>
                <Chat
                  isActive={isChatActive}
                  items={historyChatOnly}
                  source={appSocket}
                  setActive={setChatActive}
                  isFileInput={true}
                  // onlineChat={onlineChat}
                />
                <ToggleChat
                  onClick={async () => {
                    setChatActive(!isChatActive);
                  }}
                >
                  <TinyIcons.Chat />
                  <ChatSpan>Чат</ChatSpan>
                </ToggleChat>
              </ChatWrapper>
            ) : null}
          </Container>
          {activePages ? (
            <Pagination
              total={total}
              page1={page}
              perPage={perPage}
              setPage={setPage}
            />
          ) : null}

          {footerElement}

          <FooterMain>
            <LeftItemFooter>
              <Image
                src="/static/images/logo.svg"
                alt="Logo"
                width="30px"
                height="40px"
              />
              <MediumSpan>Copyright 2019-2024</MediumSpan>
              <MediumSpan>ИП Барсамов Кирилл Валерьевич</MediumSpan>
              <ItemIcons>
                <BlockItemIcon
                  onClick={() => window.open("https://discord.gg/freez-blood")}
                >
                  <Icons.Discord />
                </BlockItemIcon>
                <BlockItemIcon
                  onClick={() =>
                    window.open(
                      "https://youtube.com/@fb-life?si=iN-hrkth-4iTa8fk"
                    )
                  }
                >
                  <Icons.Youtube />
                </BlockItemIcon>
                <BlockItemIcon
                  onClick={() => window.open("https://t.me/freezblood")}
                >
                  <Icons.Telegram />
                </BlockItemIcon>
                <BlockItemIcon
                  onClick={() => window.open("https://vk.com/freezblood")}
                >
                  <Icons.VK />
                </BlockItemIcon>
              </ItemIcons>
              <Icons.Banc />
            </LeftItemFooter>

            <RightitemFooter>
              <AsideITEMBlock>
                {footerAsideItem.map((item, index) => {
                  return (
                    <MediumSpanLink to={item.link} key={index}>
                      {item.label} <Icons.IconHref />
                    </MediumSpanLink>
                  );
                })}
              </AsideITEMBlock>
            </RightitemFooter>
          </FooterMain>
          <Toast params={{ autoClose: 1000, limit: 2 }} />
          {/* <WrapSmallWidth active={activMod}>
        <div onClick={() => setActivMod(!activMod)}>
          <TinyIcons.Arrow />
        </div>
        <Wrapper>
          {asideItems.map((item, index) => (
            <Item to={item.link} key={index} act={activMod}>
              {item.label}
            </Item>
          ))}
        </Wrapper>
      </WrapSmallWidth> */}
        </StyledLayout>

        <WrapMobile>
          <WrapItem>
            {asideItems.map((item, index) => (
              // <Item to={item.link} key={index} act={activMod}>
              //   {item.label}
              // </Item>
              <>
                <Item to={item.link} key={index}>
                  {item.icon}
                </Item>
              </>
              //тут нужно нарисовать кнопку если был перенос
            ))}
          </WrapItem>
          <WrapChatItem onClick={() => setChatActive(true)}>
            <TinyIcons.Chat />
          </WrapChatItem>
          <WrapExtrItem onClick={() => setActiveAside(true)}>
            <TinyIcons.Ellipsis />
          </WrapExtrItem>
        </WrapMobile>
      </>
    );
  }
}

export default memo(Layout);
