import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: start;
    justify-content: center;
    /* align-items: center; */
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-bottom: 2rem;
  width: 50%;
`;

export const VerticalLineE = styled.div<{ bot: Boolean; active: Boolean }>`
  /* width: 3px;
  min-height: 20rem;

  background-color: #ffc700; */

  z-index: 1000;
  position: absolute;
  top: -0.8rem;
  bottom: ${(props) => (props.bot ? "0" : "11rem")};
  left: 40.5rem;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 15px 10px 15px;
  border-color: transparent transparent
    ${(props) => props.theme.colors.white.white20} transparent;
  transform: rotate(-180deg);
  display: ${(props) => (props.active ? "block" : "none")};
  @media (max-width: 1250px) {
    top: 1.7rem;
    left: 36.5rem;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;
export const BlockItemIcon = styled.div`
  cursor: pointer;
  opacity: 60%;
  :hover {
    opacity: 80%;
  }
`;

export const MainFlex = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;
export const ActvBan = styled.span<{ active: Boolean }>`
  display: ${(props) => (props.active ? "block" : "none")};
  ${(props) => props.theme.fonts.GothamMedium15}
  position: absolute;
  top: -3.2rem;
  width: 30rem;
  background: ${(props) => props.theme.colors.white.white20};
  /* right: 5rem; */
  text-align: center;
  left: 27.5rem;
  padding: 0.5rem 0;
  border-radius: 1rem;

  @media (max-width: 1250px) {
    top: -0.7rem;
    left: 23.5rem;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ProfileData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: max-content;
  gap: 1.2rem;
  /* align-self: center; */
`;
export const GrounPlayer = styled.span`
  color: ${(props) => props.theme.colors.white};
  ${(props) => props.theme.fonts.GothamMedium15};
  padding: 1rem;
  background: ${(props) => props.theme.gradients.shadow};
  width: fit-content;
  border-radius: 2rem;
  word-wrap: break-word;
`;

export const BansState = styled.span`
  padding: 0.5rem 1rem;
  background: rgba(255, 101, 101, 0.25);
  /* background-color: red; */
  /* position: relative; */
  border-radius: 2rem;
  color: red;
  ${(props) => props.theme.fonts.GothamMedium15}
  align-items: center;
  text-align: center;
  /* display: flex; */
  /* justify-content: center; */
  line-height: 2.3rem;
  gap: 1rem;
  cursor: pointer;
  min-width: 15rem;
  max-width: 25rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Nickname = styled.div`
  /* display: flex; */
  align-items: center;
  ${(props) => props.theme.fonts.GothamMedium32};
  color: ${(props) => props.theme.colors.blue.accent};
  @media (max-width: 768px) {
    min-width: 15rem;
    max-width: 20rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const OnlineStatus = styled.div`
  ${(props) => props.theme.fonts.GothamRegular20};
`;

export const ServerOnline = styled.span`
  ${(props) => props.theme.fonts.GothamRegular20};
`;

export const SiteOnline = styled.span<{ online: boolean }>`
  ${(props) => props.theme.fonts.GothamRegular20};
  color: ${(props) =>
    props.online
      ? props.theme.colors.green.selected
      : props.theme.colors.red.accent};
`;
