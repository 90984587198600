import { endpoints, url, refreshToken, token, flagRef, headers } from "API";
import axios from "axios";

export class UserService {
  static getAuthToken() {
    throw new Error("Method not implemented.");
  }
  static convertSkinsToBackEnd(skins) {
    return skins
      .filter((skin) => skin.isSelected)
      .map((skin) => {
        return {
          steamid: skin.steamid,
          category: "EXITEMS_WS",
          item: skin.skinInfo.item,
          group: skin.skinInfo.group,
          amount: skin.selectedCount,
        };
      });
  }

  static async getProfile() {
    // const fetchToken = async () => {
    //   const authToken = this.gitTokenAuth();

    //   localStorage.setItem("authToken", authToken);
    // };
    // fetchToken();
    try {
      const response = await axios.get(url + endpoints.user.profile, {
        withCredentials: true,
        headers: headers,
      });

      return response.data;
    } catch (error) {
      if (error.response.status == 401) {
        // console.log(1)

        this.refreshToken();
      }
      // if (error.statusCode == 401) {
      //   window.location.href = url + endpoints.auth.login;
      // }
      //  const authToken = await SkinChangerService.getAuthToken();
      // if (!authToken) {
      //   localStorage.clear();
      // } else {
      //   localStorage.setItem("token", authToken);
      // }
      //console.log(error);
    }
  }

  static async refreshToken() {
    try {
      const response = await axios.get(url + "/auth/refresh", {
        withCredentials: true,
        headers: {
          Authorization: "Bearer " + refreshToken,
        },
      });
      // console.log(url + endpoints.user.exit);
      localStorage.setItem("token", response.data.accessToken);
      localStorage.setItem("refreshToken", response.data.refreshToken);
      this.getProfile();
      return;
    } catch (error) {
      if (error.response.status == 401 && flagRef) {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        // window.location.reload();
      }
      flagRef = true;
      // console.log(error);
    }
  }

  static async ExitProfile() {
    try {
      const response = await axios.get(url + endpoints.user.exit, {
        withCredentials: true,
        headers: headers,
      });
      // console.log(url + endpoints.user.exit);
      return response.data;
    } catch (error) {
      // console.log(error);
    }
  }

  // static async RefreshToken() {
  //   try {
  //     const response = await axios.get(url + "/auth/refresh", {
  //       withCredentials: true,
  //       token: `Bearer + ${localStorage.getItem("refreshToken")}`,
  //     });
  //     console.log(response);
  //     // console.log(url + endpoints.user.exit);
  //     return response.data;
  //   } catch (error) {
  //     // console.log(error);
  //   }
  // }

  static async StatistickGet(steamid) {
    try {
      const response = await axios.get(
        url + endpoints.user.profile + "/" + steamid + endpoints.user.statsss,
        {
          withCredentials: true,
          headers: headers,
        }
      );
      // console.log(url + endpoints.user.exit);
      return response.data;
    } catch (error) {
      // console.log(error);
    }
  }

  static async getAnotherProfile(steamid64) {
    try {
      const response = await axios(
        url + endpoints.user.profile + `/${steamid64}`,
        {
          withCredentials: true,
          headers: headers,
        }
      );
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }
  static async getFriends(steamid64) {
    try {
      const response = await axios.get(
        url + `/profile/${steamid64}` + endpoints.user.friends,
        {
          withCredentials: true,
          headers: headers,
        }
      );
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  static async addFriend(steamid64) {
    try {
      const response = await axios.get(
        url + `/profile/createFriendRequest/${steamid64}`,
        {
          withCredentials: true,
          headers: headers,
        }
      );
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  static async getFriendRequests(steamid64) {
    try {
      const response = await axios.get(
        url + `/profile/friendsReceive/${steamid64}`,
        {
          withCredentials: true,
          headers: headers,
        }
      );
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  static async acceptFriend(steamid64, status) {
    try {
      const response = await axios.post(
        url + `/profile/acceptFriend/${steamid64}`,
        { status },
        {
          withCredentials: true,
          headers: headers,
        }
      );
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  static async cancelRequest(mysteamid, steamid64, status) {
    try {
      const response = await axios.post(
        url + `/profile/cancelRequest/${mysteamid}`,
        { steamid64 },
        {
          withCredentials: true,
          headers: headers,
        }
      );
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  static async getFriendRequests(steamid64) {
    try {
      const response = await axios.get(
        url + `/profile/friendsReceive/${steamid64}`,
        {
          withCredentials: true,
          headers: headers,
        }
      );
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  static async getFriendSent(steamid64) {
    try {
      const response = await axios.get(
        url + `/profile/friendsSent/${steamid64}`,
        {
          withCredentials: true,
          headers: headers,
        }
      );
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  static async getInventory(
    steamid64,
    page,
    classId,
    weapon,
    perPage,
    raritySort,
    rarity,
    isKeychain
  ) {
    try {
      if (steamid64) {
        const response = await axios.post(
          url + `/profile/${steamid64}` + endpoints.user.inventory,
          {
            page: page,
            classId: classId,
            weapon: weapon,
            perPage: perPage,
            raritySort: raritySort,
            rarity: rarity,
            isKeychain: isKeychain,
          },
          {
            withCredentials: true,
            headers: headers,
          }
        );
        return response.data;
      }
    } catch (error) {
      //console.log(error);
    }
  }

  static async getServerInventory(steamid64) {
    try {
      const response = await axios.get(
        url + `/profile/${steamid64}` + endpoints.user.serverInventory,
        {
          withCredentials: true,
          headers: headers,
        }
      );
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  static async sendInventoryToServer(steamid64, skins) {
    let finalSkins = this.convertSkinsToBackEnd(skins);

    try {
      const response = await axios.post(
        url + `/profile/${steamid64}` + endpoints.user.inventoryToServer,
        finalSkins,
        {
          withCredentials: true,
          headers: headers,
        }
      );
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  static async sendInventoryFromServer(steamid64, skins) {
    let finalSkins = this.convertSkinsToBackEnd(skins);

    try {
      const response = await axios.post(
        url + `/profile/${steamid64}` + endpoints.user.inventoryFromServer,
        finalSkins,
        {
          withCredentials: true,
          headers: headers,
        }
      );
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  static async gitTokenAuth() {
    try {
      const response = await axios.get(url + endpoints + "/token", {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  static async GetDiscord() {
    try {
      const response = await axios.get(
        url + endpoints.user.profile + endpoints.user.discord,
        {
          withCredentials: true,
          headers: headers,
        }
      );

      if (response.status === 302 && response.headers.location) {
        return response.headers.location;
      } else {
        // Если ответ корректный, возвращаем данные
        return response.data;
      }
      // return response.data;
    } catch (error) {
      return error.response;
      //console.log(error);
    }
  }

  static async GetVk() {
    try {
      const response = await axios.get(url + endpoints.user.vk, {
        withCredentials: true,
        headers: headers,
      });

      if (response.status === 302 && response.headers.location) {
        return response.headers.location;
      } else {
        // Если ответ корректный, возвращаем данные
        return response.data;
      }
      // return response.data;
    } catch (error) {
      return error.response;
      //console.log(error);
    }
  }

  // static async GetVk() {
  //   try {
  //     const response = await axios.get(url + endpoints.user.vk, {
  //       withCredentials: true,
  //       maxRedirects: 0, // Отключаем автоматические редиректы
  //       validateStatus: function (status) {
  //         return status >= 200 && status < 400; // Принимаем коды 2xx и 3xx как валидные
  //       },
  //     });
  //     console.log(response);

  //     // Если код ответа 302, возвращаем URL для редиректа
  //     if (response.status === 302 && response.headers.location) {
  //       return response.headers.location;
  //     } else {
  //       // Если ответ корректный, возвращаем данные
  //       return response.data;
  //     }
  //   } catch (error) {
  //     console.error("Error occurred:", error);

  //     if (error.code === "ERR_NETWORK") {
  //       // Обработка сетевой ошибки
  //       console.error("Network error: Could not connect to server.");
  //     } else if (error.response) {
  //       // Сервер вернул ответ с ошибкой
  //       console.error(
  //         "Server error:",
  //         error.response.status,
  //         error.response.data
  //       );
  //     } else {
  //       // Обработка других типов ошибок
  //       console.error("Unknown error:", error.message);
  //     }

  //     throw error; // Пробрасываем ошибку дальше для её обработки в компоненте
  //   }
  // }

  static async PostTradeString(steamid64, value) {
    try {
      const response = await axios.post(
        url + endpoints.user.profile + `/${steamid64}` + endpoints.user.Trade,
        {
          tradeOffer: value,
        },
        {
          withCredentials: true,
          headers: headers,
        }
      );
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }
}
