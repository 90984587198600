import styled from "styled-components";

export const StylesModal = styled.div`
  display: flex;
  align-items: stretch;
  clip-path: stroke-box;
  font-size: 20px;
  justify-content: center;
  list-style-type: none;
`;

export const LiStyles = styled.li<{ smalls?: Boolean }>`
  margin-right: 10px;
  margin-bottom: ${(props) => (props.smalls == true ? "10px" : "10px")};
  padding: ${(props) => (props.smalls == true ? "5px 0" : "10px 0")};
  background-color: ${(props) => props.theme.gradients.shadow};
  backdrop-filter: blur(100px);
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  min-width: ${(props) => (props.smalls == true ? "3rem" : "6rem")};
  display: flex;
  justify-content: center;
  user-select: none;
  border-radius: 10px;
  cursor: pointer;
  &.active {
    background-color: ${(props) => props.theme.colors.white.white100};
    cursor: pointer;
    font-weight: 500;
    color: ${(props) => props.theme.colors.blue.active};
  }
  @media (max-width: 768px) {
    min-width: 4.5rem;
    padding: 5px 5px;
    width: max-content;
    margin-right: 5px;
  }
`;
