import { Button } from "components/UI/Button/Styles";
import { Label } from "components/UI/Input/Styles";
import styled, {
  css,
  GradientKeys,
  keyframes,
  WeaponKeys,
} from "styled-components";

interface Props {
  rarity: WeaponKeys;
}

export const Container = styled.div`
  width: 43.4rem;
  height: fit-content;
  padding: 1.5rem 3rem 3rem;
  border-radius: 2rem;
  ${(props) => props.theme.shadows.defaultShadow};
  border: 0.3rem solid ${(props) => props.theme.stroke.stroke5};
  background: ${(props) => props.theme.gradients.bg};
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  @media (max-width: 1250px) {
    width: auto;
    padding: 1rem;
  }
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 2rem;
  ${Button}, ${Label} {
    width: 100%;
    max-width: 100%;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
`;

export const Name = styled.span`
  ${(props) => props.theme.fonts.GothamMedium32};
  color: white;
  font-weight: 400;
  text-wrap: wrap;
`;

export const SubName = styled.div<Props>`
  display: inline;
  font-weight: bold;
  ${(props) => props.theme.fonts.GothamBold32};
  color: ${(props) => props.theme.colors.weapon[props.rarity]};
`;

export const Price = styled.div`
  ${(props) => props.theme.fonts.GothamBold24};
  color: white;
`;

export const Text = styled.span`
  ${(props) => props.theme.fonts.GothamBold16};
  color: ${(props) => props.theme.colors.white.white100};
  text-align: center;
`;

const fadeToWhite = keyframes`
  from {
    color:red;
    opacity: 70%;
  }
  to {
    color:white;
    opacity: 70%;
  }
`;

export const TextSmall = styled.span<{ active?: boolean; isFading?: boolean }>`
  ${(props) => props.theme.fonts.GothamBold14};
  color: ${(props) =>
    props.active ? "red" : props.theme.colors.white.white70};
  ${({ isFading }) =>
    isFading &&
    css`
      animation: ${fadeToWhite} 2s forwards;
    `}
  text-align: left;
`;

export const TextSmallT = styled.span`
  ${(props) => props.theme.fonts.GothamBold16};
  color: ${(props) => props.theme.colors.white.white10};
`;

export const Fsdf = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.gradients.shadow};
  border-radius: 2rem;
  width: max-content;
  align-items: center;
  align-self: center;
  padding: 1rem;
`;

export const BuyMode = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const SellMode = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const OnSellMode = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const DefFlexSB = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

export const BlockMarketCommision = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: nowrap;
`;
export const BlockHr = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${(props) => props.theme.gradients.shadow};
  border-radius: 1px;
  display: flex;
  flex: 1;
`;

export const Weapon = styled.div<{ rarity: GradientKeys }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 18.8rem;
  border-radius: 2rem;
  background: ${(props) => props.theme.gradients[props.rarity]};
  position: relative;
  overflow: hidden;
  /* margin-top: 1.7rem; */

  /* Псевдоэлемент для фонового изображения */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("/static/images/BgImageChanger.png") center / 150% no-repeat;
    opacity: 0.3; /* Прозрачность для изображения */
    z-index: 1; /* Позади содержимого компонента */
    border-radius: 2rem; /* Совпадает с радиусом основного компонента */
  }
  &::after {
    content: "";
    position: absolute;
    top: 0%;
    width: 5rem;
    height: 0.5rem;
    background: ${(props) => props.theme.colors.weapon[props.rarity]};
    border-radius: 0 0 1rem 1rem;
  }

  /* @media (max-width: 1250px) {
    width: auto;
    height: fit-content;
  } */
`;

export const Gun = styled.img`
  width: 20rem;
  height: 16rem;
  z-index: 10;
  @media (max-width: 1250px) {
    /* width: 10rem; */
  }
`;
