import { TicketAndBlockStatus, TicketStatuses } from "models/Ticket";
import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5rem;
`;
export const Header = styled.div`
  display: flex;
  justify-content: left;
  ${(props) => props.theme.fonts.GothamBold32}
`;

export const NotSended = styled.h1`
  color: ${(props) => props.theme.colors.red.accent};
  ${(props) => props.theme.fonts.GothamMedium32};
  width: 100%;
  text-align: center;
`;

export const Tickets = styled.div`
  width: 100%;

  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const Head = styled.div`
  display: grid;
  gap: 5rem;
  grid-template-columns: 0.7fr 0.5fr 0.3fr 0.3fr 0.3fr;
`;
export const HeadBan = styled.div`
  display: grid;
  gap: 5rem;
  grid-template-columns: 0.3fr 0.3fr 0.3fr 0.3fr 0.3fr 0.3fr;
`;

export const HeadItem = styled.span`
  ${({ theme }) => theme.fonts.GothamMedium20};
  color: ${({ theme }) => theme.colors.white.white70};
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  cursor: pointer;
  ${({ theme }) => theme.colors.ct};
  background: ${(props) => props.theme.gradients.shadow};
  /* ${(props) => `background:${props.theme.colors.blue.accent}`} */
  border-radius: 2.5rem;
  padding: 3rem;
  /* opacity: 30%; */
`;

export const BodyRow = styled.div`
  cursor: pointer;
  display: grid;
  gap: 5rem;
  grid-template-columns: 0.7fr 0.5fr 0.3fr 0.3fr 0.3fr;
`;

export const BodyRowBan = styled.div`
  cursor: pointer;
  display: grid;
  gap: 5rem;
  grid-template-columns: 0.3fr 0.3fr 0.3fr 0.3fr 0.3fr 0.3fr;
`;

export const BodyItem = styled.span`
  ${({ theme }) => theme.fonts.GothamRegular20};
  color: ${({ theme }) => theme.colors.white.white100};
  overflow-wrap: anywhere;
`;

export const TicketStatus = styled(BodyItem)<{ status?: TicketAndBlockStatus }>`
  ${({ theme, status }) =>
    status === TicketAndBlockStatus.CREATED &&
    css`
      color: ${theme.colors.white.white70};
    `}
  ${({ theme, status }) =>
    status === TicketAndBlockStatus.IN_PROGRESS &&
    css`
      color: ${theme.colors.blue.accent};
    `}
	${({ theme, status }) =>
    status === TicketAndBlockStatus.CLOSED &&
    css`
      color: ${theme.colors.green.accent};
    `}
	${({ theme, status }) =>
    status === TicketAndBlockStatus.DELETED &&
    css`
      color: ${theme.colors.red.accent};
    `}
    ${({ theme, status }) =>
    status === TicketAndBlockStatus.UNBANNED &&
    css`
      color: ${theme.colors.green.accent};
    `}
`;
export const SubHeader = styled.span`
  display: flex;
  justify-content: center;
  ${(props) => props.theme.fonts.GothamBold32}
`;

export const BlockFilter = styled.div`
  display: flex;
  /* gap: 2rem; */
  /* height: max-content; */
  /* background: ${(props) => props.theme.colors.white.white10}; */
  width: 100%;
  gap: 1rem;
  border-radius: 1.5rem;
  /* flex-wrap: wrap; */
  @media (max-width: 1250px) {
    flex-direction: column;
  }
`;

export const BlockItemFilter = styled.div<{ active: boolean }>`
  height: 100%;
  background: ${(props) =>
    props.active
      ? props.theme.colors.white.white20
      : props.theme.gradients.shadow};
  border-radius: 1rem;
  padding: 1.5rem 2rem;
  align-items: center;
  display: flex;
  justify-content: center;
  ${(props) => props.theme.fonts.GothamMedium16} /* flex-wrap: wrap; */
  color:${(props) => props.theme.colors.white.white70};
  cursor: pointer;
  user-select: none;
`;
