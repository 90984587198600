import styled from "styled-components";

export const StyledHeader = styled.header`
  /* padding: 1rem 0 0 0; */
  /* padding-left: 5.5rem; */
  width: 100%;
  ${(props) => props.theme.mixins.dfcenter};
  justify-content: space-between;
  gap: 1rem;
  position: relative;
  height: max-content;
  padding-bottom: 1rem;
  border-bottom: 2px solid ${(props) => props.theme.colors.white.white10};
  @media (max-width: 1250px) {
    padding: 1rem;
    /* display: block; */
  }
`;
