export default function Clans({ ...props }: React.HTMLAttributes<SVGElement>) {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fillOpacity="0.7" clip-path="url(#clip0_2991_53017)">
        <path
          d="M3.89844 6.4998C3.89844 7.87893 4.44629 9.20157 5.42148 10.1768C6.39667 11.1519 7.71931 11.6998 9.09844 11.6998C10.4776 11.6998 11.8002 11.1519 12.7754 10.1768C13.7506 9.20157 14.2984 7.87893 14.2984 6.4998C14.2984 5.12068 13.7506 3.79804 12.7754 2.82285C11.8002 1.84766 10.4776 1.2998 9.09844 1.2998C7.71931 1.2998 6.39667 1.84766 5.42148 2.82285C4.44629 3.79804 3.89844 5.12068 3.89844 6.4998Z"
          stroke="white"
          fillOpacity="0.7"
          stroke-width="2"
          // strokeOpacity="0.7"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.29688 24.7004V22.1004C1.29688 20.7213 1.84473 19.3986 2.81992 18.4234C3.79511 17.4482 5.11775 16.9004 6.49688 16.9004H11.6969C13.076 16.9004 14.3986 17.4482 15.3738 18.4234C16.349 19.3986 16.8969 20.7213 16.8969 22.1004V24.7004"
          stroke="white"
          stroke-width="2"
          fillOpacity="0.7"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.2031 1.46875C19.3217 1.75514 20.3131 2.40566 21.0211 3.31775C21.729 4.22985 22.1133 5.35163 22.1133 6.50625C22.1133 7.66087 21.729 8.78265 21.0211 9.69475C20.3131 10.6068 19.3217 11.2574 18.2031 11.5437"
          stroke="white"
          stroke-width="2"
          fillOpacity="0.7"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M24.6969 24.7007V22.1007C24.6903 20.953 24.3042 19.8397 23.5987 18.9344C22.8933 18.0291 21.9081 17.3826 20.7969 17.0957"
          stroke="white"
          stroke-width="2"
          fillOpacity="0.7"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2991_53017">
          <rect width="26" height="26" fill="white" fillOpacity="0.7" />
        </clipPath>
      </defs>
    </svg>
  );
}
