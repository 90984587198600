import { SupportServise } from "API/SupportServise";
import { appSocket } from "App";
import Column from "components/Column";
import Layout from "components/Layout";
import BanModalSupport from "components/Modal/BanSupport";
import Row from "components/Row";
import SubHeader from "components/SubHeader";
import WithTitle from "components/Templates/WithTitle";
import PreviousNavigate from "components/UI/ArrowBack";
import Button from "components/UI/Button";
import { useAppSelector } from "hooks/useAppSelector";
import { TicketTypes } from "interfaces/Support";
import { MessageSupport } from "models/Message";
import { ITicket, TicketAndBlockStatus, TicketLabels } from "models/Ticket";
import ChatUser from "modules/ChatUsers/ChatUser";
import { TicketStatus } from "pages/Support/Styles";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { io } from "socket.io-client";

import { Helmet } from "react-helmet";
import {
  Content,
  Info,
  InfoItem,
  PlayerItem,
  WrapBlock,
  Wrapper,
} from "./Styles";

const source = io("");

export default function Ticket() {
  const { id } = useParams();

  const [myTicket, setMyTicket] = useState<ITicket>();
  const [messageHistory, setMessageHistory] = useState<MessageSupport[]>([]);
  const [activeModal, setActiveModal] = useState<boolean>(false);

  useEffect(() => {
    appSocket.on("ticketMsg", (data: MessageSupport) => {
      if (data?.ticketId == Number(id)) {
        setMessageHistory((prevHistory) => [...prevHistory, data]);
      }
      // console.log(data);
    });

    // Очистка подписки при размонтировании компонента
    return () => {
      appSocket.off("ticketMsg");
    };
  }, []);

  useEffect(() => {
    async function getU() {
      if (id) {
        setMyTicket(await SupportServise.GetMyTicketOne(id));
        setMessageHistory(await SupportServise.GetHistoryMessage(id));
      }
    }
    appSocket.emit("ticketRoom", {
      id: id,
    });
    getU();
  }, [1]);

  function SwapStatus(stat: TicketAndBlockStatus) {
    let i: ITicket | undefined = myTicket;
    if (i) {
      i.status = stat;
    }
    setMyTicket(i);
  }
  function CheckStatus(stat: TicketAndBlockStatus, act?: boolean) {
    if (act && stat == TicketAndBlockStatus.CLOSED) {
      return "Не разблокирован";
    }
    if (stat == TicketAndBlockStatus.CLOSED) {
      return "Решен";
    }
    if (stat == TicketAndBlockStatus.IN_PROGRESS) {
      return "На рассмотрении";
    }
    if (stat == TicketAndBlockStatus.CREATED) {
      return "На рассмотрении";
    }
    if (stat == TicketAndBlockStatus.DELETED) {
      return "Закрыт";
    }
    if (stat == TicketAndBlockStatus.UNBANNED) {
      return "Разбанен";
    }
  }
  function formatDate(timestampString: string) {
    const months = [
      "января",
      "февраля",
      "марта",
      "апреля",
      "мая",
      "июня",
      "июля",
      "августа",
      "сентября",
      "октября",
      "ноября",
      "декабря",
    ];

    // Создаем объект Date из строки времени (в UTC)
    let date = new Date(timestampString);

    date.setHours(date.getHours() - 3);

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day} ${month} ${year}, ${hours}:${minutes}`;
  }

  const navigate = useNavigate();

  const { user } = useAppSelector((state) => state.user);
  if (myTicket?.type == TicketTypes.HELP) {
    return (
      <Layout
        headerElement={
          <SubHeader>
            <PreviousNavigate />
            Тикет #{id}
          </SubHeader>
        }
        leftElement={<div />}
      >
        <Helmet>
          <title>FREEZ-BLOOD - Поддержка</title>
          <meta name="description" content={`FREEZ-BLOOD - Поддержка`} />
        </Helmet>
        <PreviousNavigate />
        <Wrapper>
          <Info>
            <WithTitle title="Информация">
              <Column
                gap="2rem"
                justifyContent="normal"
                alignItems="normal"
                width="100%"
              >
                <Row justifyContent="flex-start" alignItems="flex-start">
                  <InfoItem width="18.9rem">{TicketLabels.THEME}</InfoItem>
                  <InfoItem width="100%">{myTicket?.topic}</InfoItem>
                </Row>
                <Row justifyContent="flex-start" alignItems="flex-start">
                  <InfoItem width="18.9rem">{TicketLabels.AUTHOR}</InfoItem>
                  <PlayerItem
                    width="100%"
                    onClick={() =>
                      navigate(`/profile/${myTicket.user?.steamid64}`)
                    }
                  >
                    {myTicket?.user?.name}
                  </PlayerItem>
                </Row>
                <Row justifyContent="flex-start" alignItems="flex-start">
                  <InfoItem width="18.9rem">{TicketLabels.STATUS}</InfoItem>
                  <TicketStatus status={myTicket.status}>
                    {CheckStatus(myTicket.status)}
                  </TicketStatus>
                </Row>
                <Row justifyContent="flex-start" alignItems="flex-start">
                  <InfoItem width="18.9rem">
                    {TicketLabels.CREATION_DATE}
                  </InfoItem>
                  <InfoItem width="100%">
                    {formatDate(myTicket?.date || "2024-04-03T15:18:06.396Z")}
                  </InfoItem>
                </Row>
              </Column>
              <WrapBlock>
                {myTicket?.status !== TicketAndBlockStatus.CLOSED ? (
                  <Button
                    height="5rem"
                    variant="default"
                    color="blue"
                    params={{ width: "100%", padding: "1.5rem 7.5rem" }}
                    style={{ alignSelf: "center" }}
                    onClick={() => {
                      SupportServise.CloseTik(
                        myTicket?.id || 0,
                        TicketAndBlockStatus.CLOSED
                      );
                      navigate(
                        user?.group?.flags.includes("l")
                          ? "/supportAdmin"
                          : "/support"
                      );
                      SwapStatus(TicketAndBlockStatus.CLOSED);
                    }}
                  >
                    Закрыть тикет
                  </Button>
                ) : null}
                {myTicket?.status !== TicketAndBlockStatus.DELETED &&
                user?.group?.flags.includes("l") ? (
                  <Button
                    height="5rem"
                    variant="default"
                    color="red"
                    params={{ width: "100%", padding: "1.5rem 7.5rem" }}
                    style={{ alignSelf: "center" }}
                    onClick={() => {
                      SupportServise.CloseTik(
                        myTicket?.id || 0,
                        TicketAndBlockStatus.DELETED
                      );
                      navigate(
                        user?.group?.flags.includes("l")
                          ? "/supportAdmin"
                          : "/support"
                      );
                      SwapStatus(TicketAndBlockStatus.DELETED);
                    }}
                  >
                    Удалить тикет
                  </Button>
                ) : null}
              </WrapBlock>
            </WithTitle>
            <WithTitle title="Комментарий">
              <Content>{myTicket?.text}</Content>
            </WithTitle>
          </Info>
          <ChatUser
            source={appSocket}
            itemMessage={messageHistory || []}
            isActive={true}
            isFileInput={false}
            params={{ width: "100%", height: "90vh" }}
            id={Number(id)}
            nonChat={
              myTicket?.status == TicketAndBlockStatus.CLOSED ||
              myTicket?.status == TicketAndBlockStatus.DELETED ||
              myTicket?.status == TicketAndBlockStatus.UNBANNED
                ? true
                : false
            }
          />
        </Wrapper>
      </Layout>
    );
  } else {
    return (
      <Layout
        headerElement={
          <SubHeader>
            <PreviousNavigate />
            Тикет #{id}
          </SubHeader>
        }
        leftElement={<div />}
      >
        <Wrapper>
          <Info>
            <WithTitle title="Информация">
              <Column
                gap="2rem"
                justifyContent="normal"
                alignItems="normal"
                width="100%"
                a={true}
              >
                <Row justifyContent="flex-start" alignItems="flex-start">
                  <InfoItem width="18.9rem">
                    {TicketLabels.Nick} Админа
                  </InfoItem>
                  <PlayerItem
                    width="100%"
                    onClick={() =>
                      navigate(`/profile/${myTicket?.block?.admin.steamid64}`)
                    }
                  >
                    {myTicket?.block?.admin.name}
                  </PlayerItem>
                </Row>
                {/* <Row justifyContent="flex-start" alignItems="flex-start"> */}
                {/* <InfoItem width="18.9rem">{TicketLabels.Reason}</InfoItem> */}
                {/* <PlayerItem
                    width="100%"
                    onClick={() =>
                      navigate(`/profile/${myTicket?.user.steamid64}`)
                    }
                  >
                    {myTicket?.user.name}
                  </PlayerItem> */}
                {/* </Row> */}
                <Row justifyContent="flex-start" alignItems="flex-start">
                  <InfoItem width="18.9rem">{TicketLabels.Reason}</InfoItem>
                  <TicketStatus status={TicketAndBlockStatus.DELETED}>
                    {myTicket?.block?.reason}
                  </TicketStatus>
                </Row>
                <Row justifyContent="flex-start" alignItems="flex-start">
                  <InfoItem width="18.9rem">{TicketLabels.STATUS}</InfoItem>
                  <TicketStatus status={myTicket?.status}>
                    {CheckStatus(
                      myTicket?.status || TicketAndBlockStatus.CREATED,
                      true
                    )}
                  </TicketStatus>
                </Row>
                <Row justifyContent="flex-start" alignItems="flex-start">
                  <InfoItem width="18.9rem">{TicketLabels.Server}</InfoItem>
                  <InfoItem width="100%">
                    {myTicket?.block?.server.name}
                  </InfoItem>
                </Row>
                <Row justifyContent="flex-start" alignItems="flex-start">
                  <InfoItem width="18.9rem">{TicketLabels.Player}</InfoItem>
                  <PlayerItem
                    width="100%"
                    onClick={() =>
                      navigate(`/profile/${myTicket?.block?.banned.steamid64}`)
                    }
                  >
                    {myTicket?.block?.banned.name}
                  </PlayerItem>
                </Row>
                <Row justifyContent="flex-start" alignItems="flex-start">
                  <InfoItem width="18.9rem">{TicketLabels.Discord}</InfoItem>
                  <InfoItem width="100%">{myTicket?.discord}</InfoItem>
                </Row>
                <Row justifyContent="flex-start" alignItems="flex-start">
                  <InfoItem width="18.9rem">{TicketLabels.date}</InfoItem>
                  <InfoItem width="100%">
                    {formatDate(myTicket?.date || "")}
                  </InfoItem>
                </Row>
                {myTicket?.support?.name ? (
                  <Row justifyContent="flex-start" alignItems="flex-start">
                    <InfoItem width="18.9rem">
                      {TicketLabels.ForEachBan}
                    </InfoItem>
                    <PlayerItem
                      width="100%"
                      onClick={() =>
                        navigate(`/profile/${myTicket?.support?.steamid64}`)
                      }
                    >
                      {myTicket?.support?.name}
                    </PlayerItem>
                  </Row>
                ) : null}

                {/* <Row justifyContent="flex-start" alignItems="flex-start">
                  <InfoItem width="18.9rem">
                    {TicketLabels.CREATION_DATE}
                  </InfoItem>
                  <InfoItem width="100%">
                    {formatDate(myTicket?.date || "2024-04-03T15:18:06.396Z")}
                  </InfoItem>
                </Row> */}
              </Column>
              <WrapBlock>
                {user?.group?.flags.includes("m") &&
                myTicket?.status !== TicketAndBlockStatus.UNBANNED &&
                myTicket?.status !== TicketAndBlockStatus.CLOSED ? (
                  <Button
                    height="5rem"
                    variant="default"
                    color="green"
                    params={{ width: "100%", padding: "1.5rem 7.5rem" }}
                    style={{ alignSelf: "center" }}
                    onClick={() => {
                      SupportServise.CloseTik(
                        myTicket?.id || 0,
                        TicketAndBlockStatus.UNBANNED
                      );
                      navigate(
                        user?.group?.flags.includes("l")
                          ? "/supportAdmin"
                          : "/support"
                      );
                      SwapStatus(TicketAndBlockStatus.UNBANNED);
                    }}
                  >
                    Разбанен
                  </Button>
                ) : null}
                {user?.group?.flags.includes("m") &&
                myTicket?.status !== TicketAndBlockStatus.UNBANNED &&
                myTicket?.status !== TicketAndBlockStatus.CLOSED ? (
                  <Button
                    height="5rem"
                    variant="default"
                    color="red"
                    params={{ width: "100%", padding: "1.5rem 7.5rem" }}
                    style={{ alignSelf: "center" }}
                    onClick={() => {
                      SupportServise.CloseTik(
                        myTicket?.id || 0,
                        TicketAndBlockStatus.CLOSED
                      );
                      navigate(
                        user?.group?.flags.includes("l")
                          ? "/supportAdmin"
                          : "/support"
                      );
                      SwapStatus(TicketAndBlockStatus.CLOSED);
                    }}
                  >
                    Не разбанен
                  </Button>
                ) : null}
              </WrapBlock>
              <Button
                height="5rem"
                variant="default"
                color="blue"
                params={{ width: "100%", padding: "1.5rem 7.5rem" }}
                style={{ alignSelf: "center" }}
                onClick={() => setActiveModal(true)}
              >
                Подробнее
              </Button>
            </WithTitle>
            <WithTitle title="Комментарий">
              <Content>{myTicket?.text}</Content>
            </WithTitle>
          </Info>
          <ChatUser
            source={appSocket}
            itemMessage={messageHistory || []}
            isActive={true}
            isFileInput={false}
            params={{ width: "100%", height: "90vh" }}
            id={Number(id)}
            nonChat={
              myTicket?.status == TicketAndBlockStatus.CLOSED ||
              myTicket?.status == TicketAndBlockStatus.DELETED ||
              myTicket?.status == TicketAndBlockStatus.UNBANNED
                ? true
                : false
            }
          />
        </Wrapper>
        <BanModalSupport
          isActive={activeModal}
          setActive={setActiveModal}
          violation={myTicket?.block}
          activeBan={myTicket?.block}
        />
      </Layout>
    );
  }
}
