export default function Add() {
  return (
    <svg
      width="2rem"
      height="2rem"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_40_22888)">
        <path
          d="M12 4.99954V18.9995"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 11.9995H19"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_40_22888">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 -0.000457764)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
