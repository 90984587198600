import styled, { GradientKeys } from "styled-components";

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-top: 3rem;
  /* height: 100%; */
  /* width: max-content; */
  min-height: 55rem;

  @media (max-width: 430px) {
    justify-content: center;
    width: 100%;
    gap: 0.5rem;
    margin: 0;
  }
`;

export const ModalContainerStic = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-top: 3rem;
  /* height: 100%; */
  /* width: max-content; */
  min-height: 55rem;
  @media (max-width: 780px) {
    display: none;
  }
`;

export const ModalContainerSticMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  /* height: 100%; */
  /* width: max-content; */
  min-height: 55rem;
`;
export const Full = styled.div`
  display: flex;
  gap: 2rem;
  @media (max-width: 430px) {
    justify-content: center;
    width: 100%;
    gap: 0.5rem;
  }
`;
export const Weapon = styled.div<{ rarity: GradientKeys }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 18.8rem;
  border-radius: 2rem;
  background: ${(props) =>
    props.theme.gradients[props.rarity]}; /* Цвет фона компонента */
  position: relative;
  overflow: hidden;
  /* margin-top: 1.7rem; */

  /* Псевдоэлемент для фонового изображения */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("/static/images/BgImageChanger.png") center / 150% no-repeat;
    opacity: 0.3; /* Прозрачность для изображения */
    z-index: 1; /* Позади содержимого компонента */
    border-radius: 2rem; /* Совпадает с радиусом основного компонента */
  }
  &::after {
    content: "";
    position: absolute;
    top: 0%;
    width: 5rem;
    height: 0.5rem;
    background: ${(props) => props.theme.colors.weapon[props.rarity]};
    border-radius: 0 0 1rem 1rem;
  }

  /* @media (max-width: 1250px) {
    width: auto;
    height: fit-content;
  } */
`;
export const Gun = styled.img`
  width: 20rem;
  height: 16rem;
  z-index: 10;
  @media (max-width: 1250px) {
    /* width: 10rem; */
  }
`;

export const DefFlexSB = styled.div<{ stic?: boolean }>`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  width: 100%;
  @media (max-width: 430px) {
    flex-direction: ${(props) => (props.stic ? "row " : "column")};
    justify-content: ${(props) => (props.stic ? "space-between" : "center")};
  }
`;
export const DefFlexG1 = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const StikerPick = styled.div<{ active: boolean }>`
  width: 5rem;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 5rem;
  border-radius: 1.5rem;
  background: ${(props) => props.theme.gradients.shadow};
  border: 1px solid
    ${(props) =>
      props.active ? (props) => props.theme.colors.white.white20 : "null"};
  cursor: pointer;
  @media (max-width: 430px) {
    width: 4rem;
    height: 4rem;
    border-radius: 1rem;
  }
`;

export const BlockUseCommand = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  background: ${(props) => props.theme.gradients.shadow};
  border-radius: 1.5rem;
  /* height: 5rem; */
  /* max-height: 5rem; */
  padding: 0.5rem;
  @media (max-width: 430px) {
    width: 100%;
    justify-content: center;
    padding: 0;
  }
`;

export const BlockCommand = styled.div<{ active: boolean }>`
  display: flex;
  background: ${(props) =>
    props.active ? props.theme.colors.white.white20 : null};
  width: 4rem;
  height: 4rem;
  border-radius: 1.5rem;
  padding: 0.5rem;
  cursor: pointer;
`;

export const BlockStikers = styled.div<{ notPrem?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  min-width: 33rem;
  max-width: 33rem;
  /* min-height: 40rem; */
  max-height: 53rem;

  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 0.4rem;
    border: 0.1rem solid transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.blue.accent} !important;
    border-radius: 0.5rem !important;
  }
  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.white.white10} !important;
  }

  &::before {
    width: ${(props) => (props.notPrem ? "43%" : 0)};
    height: ${(props) => (props.notPrem ? "75%" : 0)};
    content: "";
    border-radius: 2rem;
    position: absolute;
    bottom: 1rem;
    backdrop-filter: blur(5px);
    left: 2rem;
    overflow: none;
  }
  @media (max-width: 1250px) {
    &:before {
      width: ${(props) => (props.notPrem ? "45%" : 0)};
      height: ${(props) => (props.notPrem ? "77%" : 0)};
      left: 0rem;
    }
  }
`;

export const BlockStikersMobile = styled.div<{ notPrem?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  min-width: 33rem;
  max-width: 33rem;
  /* min-height: 40rem; */
  max-height: 47rem;

  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 0.4rem;
    border: 0.1rem solid transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.blue.accent} !important;
    border-radius: 0.5rem !important;
  }
  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.white.white10} !important;
  }

  &::before {
    width: ${(props) => (props.notPrem ? "100%" : 0)};
    height: ${(props) => (props.notPrem ? "75%" : 0)};
    content: "";
    border-radius: 2rem;
    position: absolute;
    bottom: 1rem;
    backdrop-filter: blur(5px);
    left: 2rem;
    overflow: none;
    top: 12rem;
  }
  @media (max-width: 430px) {
    &:before {
      left: 0;
    }
  }
`;

export const NonePremium = styled.div<{ nonPrem: boolean }>`
  position: absolute;
  display: ${(props) => (props.nonPrem ? "block" : "none")};
  ${(props) => props.theme.fonts.GothamBold16}
  color:#FF8A2E;
  z-index: 200;
  top: 45%;
  left: 8rem;
`;

export const NonePremiumModal = styled.div<{ nonPrem: boolean }>`
  position: absolute;
  display: ${(props) => (props.nonPrem ? "block" : "none")};
  ${(props) => props.theme.fonts.GothamBold16}
  color:#FF8A2E;
  z-index: 200;
  top: 45%;
  left: 8rem;
`;

export const Sticker = styled.div`
  display: flex;
  flex-direction: column;

  gap: 1rem;
  width: 10rem;
  height: 11rem;
  background: ${(props) => props.theme.gradients.shadow};
  border-radius: 1.5rem;
  padding: 1rem;
  cursor: pointer;
  user-select: none;
  :hover {
    background: ${(props) => props.theme.colors.white.white10};
  }
`;

export const SmallText = styled.span`
  ${(props) => props.theme.fonts.GothamRegular14}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TextPrem = styled.span`
  display: flex;
  flex-wrap: wrap;
  ${(props) => props.theme.fonts.GothamBold16}
  color:#F4C857;
`;

export const BlockModalSkin = styled.div<{ active: boolean }>`
  display: none;
  background: ${(props) => props.theme.gradients.bg};
  border-radius: 2rem;
  border: 1px solid black;
  padding: 2rem;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
  left: ${(props) => (props.active == true ? "-0.1rem" : "-150rem")};
  transition: 0.5s;
  /* opacity: 50%; */
  /* bottom: -6rem; */
  /* backdrop-filter: blur(5px); */
  top: 0rem;
  overflow: hidden;
  /* padding-bottom: 5rem; */

  @media (max-width: 780px) {
    display: block;
  }
`;

export const MediumText = styled.span`
  ${(props) => props.theme.fonts.GothamMedium16}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
