import Layout from "components/Layout";
import ProfileLayout from "components/ProfileLayout";
import SubHeader from "components/SubHeader";
import { useAppSelector } from "hooks/useAppSelector";

import { UserService } from "API/UserService";
import { Icons } from "assets/icons";
import { StatsProfile } from "interfaces/Stats";
import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import {
  Body,
  BodyItem,
  Head,
  HeadItem,
  History,
  Row,
  RowBody,
  Wrapper,
} from "./Styles";

export default function Stats() {
  const { userData } = useAppSelector((state) => state.user);
  const [statsUser, setStatsUser] = useState<StatsProfile[]>();

  useMemo(() => {
    // console.log(userData);
    // setStatsUser(undefined);
    async function a() {
      const res = await UserService.StatistickGet(userData?.user?.steamid64);
      setStatsUser(res);
    }
    if (userData?.user?.steamid64) {
      a();
    }
  }, [userData?.user?.steamid64]);

  function secondsToHoursOrMinutes(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    if (hours > 0) {
      return `${hours} ч.`;
    } else {
      return `${minutes} мин.`;
    }
  }

  return (
    <Layout
      leftElement={<div />}
      headerElement={<SubHeader>Статистика</SubHeader>}
    >
      <Helmet>
        <title>FREEZ-BLOOD - Профиль</title>
        <meta name="description" content={`FREEZ-BLOOD - Профиль`} />
      </Helmet>
      <ProfileLayout gap="3rem">
        <Wrapper>
          {statsUser !== undefined && statsUser.length !== 0 ? (
            <History>
              <Head>
                <Row>
                  <HeadItem>СЕРВЕР</HeadItem>
                  <HeadItem>УБИЙСТВ</HeadItem>
                  <HeadItem>СМЕРТИ</HeadItem>
                  <HeadItem>ОЧКИ</HeadItem>
                  <HeadItem>ПРОВЕДЕНО ВРЕМЕНИ</HeadItem>
                </Row>
              </Head>
              <Body>
                {statsUser &&
                  statsUser.map((item) => {
                    return (
                      <RowBody>
                        <BodyItem>{item.server.serverName}</BodyItem>
                        <BodyItem>
                          <Icons.Kills />
                          {item.kills}
                        </BodyItem>
                        <BodyItem>
                          {" "}
                          <Icons.Death />
                          {item.deaths}
                        </BodyItem>
                        <BodyItem>{item.points}</BodyItem>
                        <BodyItem>
                          <Icons.Time />

                          {secondsToHoursOrMinutes(item.playtime)}
                        </BodyItem>
                      </RowBody>
                    );
                  })}
              </Body>
            </History>
          ) : null}
        </Wrapper>
      </ProfileLayout>
    </Layout>
  );
}
