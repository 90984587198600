import Layout from "components/Layout";
import ProfileLayout from "components/ProfileLayout";
import SubHeader from "components/SubHeader";
import { Helmet } from "react-helmet";
import {
  DefWrap,
  TextBig,
  TextNormal,
  WrapBlock,
  WrapButtons,
  WrapFullSettings,
} from "./styled";
import Button from "components/UI/Button";
import { Icons } from "assets/icons";
import { useAppSelector } from "hooks/useAppSelector";
import UIKIT from "components/UI";
import { UserService } from "API/UserService";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { endpoints, url } from "API";

export default function Settings() {
  const { isAuth, user } = useAppSelector((state) => state.user);
  const [TradeString, setTradeString] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const [copied, setCopied] = useState(false);
  const timeoutRef = useRef<number | null>(null);
  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = window.setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <Layout
      leftElement={<div />}
      headerElement={<SubHeader>Привилегии</SubHeader>}
    >
      <Helmet>
        <title>FREEZ-BLOOD - Настройки</title>
        <meta name="description" content={`FREEZ-BLOOD - Настройки`} />
      </Helmet>
      <ProfileLayout>
        <WrapFullSettings>
          <WrapBlock>
            <TextBig>Соц.сети</TextBig>
            <DefWrap>
              <WrapButtons
                active={user?.user?.steamid64 !== null}
                onClick={() =>
                  window.open(
                    `https://steamcommunity.com/profiles/${user?.user?.steamid64}`
                  )
                }
              >
                <DefWrap>
                  <Icons.Steam style={{ width: "34px" }} />
                  <TextNormal>Steam</TextNormal>
                  <Icons.ArrowBack
                    style={{
                      transform: "rotate(135deg)",
                      width: "20px",
                      height: "10px",
                    }}
                  />
                </DefWrap>
                {/* {user?.user?.steamid64 !== null ? <Icons.Detach /> : null} */}
              </WrapButtons>
              <WrapButtons
                active={user?.user?.discord !== null}
                onClick={async (e) => {
                  e.stopPropagation();
                  if (user?.user?.discord == null) {
                    const result = await UserService.GetDiscord();
                    if (result) {
                      if (result == "This soc already exist") {
                        toast.error("Этот аккаунт уже привязан");
                      } else {
                        window.open(result, "_self");
                      }
                    }
                  }
                }}
              >
                <DefWrap
                  onClick={(e) => {
                    if (user?.user?.discord) {
                      handleCopy(user?.user?.discord);
                      toast.success("Успешно скопированно");
                    }
                  }}
                >
                  <Icons.Discord
                    style={
                      user?.user?.discord !== null
                        ? { opacity: "100%", width: "34px" }
                        : { opacity: "50%", width: "34px" }
                    }
                  />
                  <TextNormal>
                    {user?.user?.discord !== null
                      ? "Дискорд"
                      : "Подключить Discord"}
                  </TextNormal>
                  {user?.user?.discord !== null ? (
                    <Icons.ArrowBack
                      style={{
                        transform: "rotate(135deg)",
                        width: "20px",
                        height: "10px",
                      }}
                    />
                  ) : null}
                </DefWrap>
                {user?.user?.discord !== null ? (
                  <div
                    onClick={async () => {
                      const result = await UserService.GetDiscord();
                      if (result) {
                        window.open(result, "_self");
                        if (result == "This soc already exist") {
                          toast.error("Этот аккаунт уже привязан");
                        } else {
                          handleCopy(result);
                        }
                      }
                    }}
                  >
                    <Icons.Detach />
                  </div>
                ) : null}
              </WrapButtons>
              <WrapButtons
                active={user?.user?.vk !== null}
                onClick={async (e) => {
                  const result = await UserService.GetVk();
                  console.log(result);
                  if (result) {
                    window.open(result, "_self");
                    if (result == "This soc already exist") {
                      toast.error("Этот аккаунт уже привязан");
                    } else {
                      handleCopy(result);
                    }
                  }
                }}
              >
                <DefWrap>
                  <Icons.VK
                    style={
                      user?.user?.vk !== null
                        ? { opacity: "100%", width: "34px" }
                        : { opacity: "50%", width: "34px" }
                    }
                  />
                  <TextNormal>
                    {user?.user?.vk !== null ? "VK" : "Подключить VK"}
                  </TextNormal>
                  {user?.user?.vk !== null ? (
                    <Icons.ArrowBack
                      style={{
                        transform: "rotate(135deg)",
                        width: "20px",
                        height: "10px",
                      }}
                    />
                  ) : null}
                </DefWrap>
                {user?.user?.vk !== null ? (
                  <div
                    onClick={async () => {
                      window.open(url + endpoints.user.vk, "_self");
                    }}
                  >
                    <Icons.Detach />
                  </div>
                ) : null}
              </WrapButtons>
              {/* <Button height={"5rem"} variant={"default"} color="shadow">
                d
              </Button>
              <Button height={"5rem"} variant={"default"} color="shadow">
                d
              </Button> */}
            </DefWrap>
          </WrapBlock>
          <WrapBlock>
            <TextBig>Профиль</TextBig>
            <DefWrap
              style={{ cursor: "pointer" }}
              onClick={() =>
                window.open(`
                  https://steamcommunity.com/profiles/${user?.user?.steamid64}/tradeoffers/privacy
                `)
              }
            >
              <TextNormal>
                Trade ссылка{" "}
                <Icons.ArrowBack
                  style={{
                    transform: "rotate(135deg)",
                    width: "20px",
                    height: "10px",
                  }}
                />
              </TextNormal>
              <TextNormal style={{ opacity: "30%" }}>
                (Получить ссылку)
              </TextNormal>
            </DefWrap>

            <UIKIT.Input
              params={{ width: "90%" }}
              placeholder={
                user?.user?.tradeOffer !== null
                  ? user?.user?.tradeOffer
                  : "Введите ссылку"
              }
              onChange={(e) => setTradeString(e.target.value)}
            />
            <Button
              color="blue"
              variant="default"
              height="5rem"
              disabled={success}
              style={{ marginTop: "5rem" }}
              params={{ width: "max-content" }}
              onClick={async () => {
                if (TradeString) {
                  const result = await UserService.PostTradeString(
                    user?.user?.steamid64,
                    TradeString
                  );
                  if (result) {
                    setSuccess(true);
                    toast.success("Успешно");
                  } else {
                    toast.error("Проверьте ссылку на корректность");
                  }
                }
              }}
            >
              Сохранить изменения
            </Button>
          </WrapBlock>
        </WrapFullSettings>
      </ProfileLayout>
    </Layout>
  );
}
