export default function EditText() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3582_56177)">
        <path
          d="M7.5 7.5H6.5C5.96957 7.5 5.46086 7.71071 5.08579 8.08579C4.71071 8.46086 4.5 8.96957 4.5 9.5V18.5C4.5 19.0304 4.71071 19.5391 5.08579 19.9142C5.46086 20.2893 5.96957 20.5 6.5 20.5H15.5C16.0304 20.5 16.5391 20.2893 16.9142 19.9142C17.2893 19.5391 17.5 19.0304 17.5 18.5V17.5"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.885 7.08499C21.2788 6.69114 21.5001 6.15697 21.5001 5.59998C21.5001 5.043 21.2788 4.50883 20.885 4.11498C20.4912 3.72114 19.957 3.49988 19.4 3.49988C18.843 3.49988 18.3088 3.72114 17.915 4.11498L9.5 12.5V15.5H12.5L20.885 7.08499Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.5 5.5L19.5 8.5"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3582_56177">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
