import React, { useEffect, useMemo, useState } from "react";
import Layout from "components/Layout";
import {
  BattlPassName,
  BattlPassPrizeLine,
  BattlPassWrapperPrize,
  BigText,
  BlockEarn,
  BlockPrize,
  BlockTask,
  BlockTopTool,
  MediumText100,
  PlayerBlockTOp,
  SmallText100,
  SmallText70,
  TimeBattlPassRemeining,
  Verticaltext,
  WrapBlockPlayer,
  WrapFlexSpaceBeet,
  WrapFlexSpaceBeet2,
  WrappDefaultFlex,
  WrappDefaultFlex2,
  WrapperBattlePassMain,
  XpPrizeTask,
} from "./styled";
import Button from "components/UI/Button";
import { TinyIcons } from "assets/tiny-icons";
import Avatar from "components/Avatar";
import { BattlPassServise } from "API/BattlPassServise";
import Image from "components/Image";
import {
  BattlPassInterf,
  BatttInfoMe,
  levels,
  PrizeEnum,
  TaskEnum,
  TastkStatsInfo,
  TimeObject,
} from "interfaces/BattlPass";
import { useNavigate } from "react-router-dom";
import { ServersService } from "API/ServersService";
import { CategoryMods } from "interfaces/CategoryMap";
import { exit } from "process";
import { Icons } from "assets/icons";
import EarnProgressBar from "./EarnProgressBar/EarnProgressBar";
import QuechenButt from "./QuechenButt/QuechenButt";
import { useActions } from "hooks/useActions";
import { useAppSelector } from "hooks/useAppSelector";
import Modal from "components/Modal/Modal";
import { Modals } from "components/Modal";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

export default function BattlPass() {
  const navigate = useNavigate();
  const [battlPassStat, setBattlPassStat] = useState<BattlPassInterf>();
  const [battlPassMe, setBattlPassMe] = useState<BatttInfoMe>();
  const [timeRemaining, setTimeRemaining] = useState<TimeObject>({
    day: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [timeRemainingTasks, setTimeRemainingTasks] = useState<TimeObject>({
    day: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const { setBalance } = useActions();
  const { user } = useAppSelector((state) => state.user);
  const [serverMode, serServerMode] = useState<CategoryMods[]>();
  const [activeModalXp, setActiveModalXp] = useState<boolean>(false);
  const [isActiveSoc, setIsActiveSoc] = useState<boolean>(false);
  const [itemLevel, setItemLevel] = useState<TastkStatsInfo>();

  useEffect(() => {
    async function FirstLogin() {
      setBattlPassStat(await BattlPassServise.getBattlPass());
      setBattlPassMe(await BattlPassServise.getMe());
      serServerMode(await ServersService.getMods());
    }
    FirstLogin();
  }, []);

  //включение таймера отсчета
  useMemo(() => {
    if (battlPassStat?.expired) {
      let dateBattl = new Date(battlPassStat.expired);
      // dateBattl.setHours(dateBattl.getHours() - 3);

      startCountdown(dateBattl, setTimeRemaining);
    }
    if (battlPassMe?.tasks.expired) {
      let dateTask = new Date(battlPassMe.tasks.expired);
      startCountdown(dateTask, setTimeRemainingTasks);
    }
  }, [battlPassStat, battlPassMe]);

  function convertDateToTimeObject(targetDate: Date): TimeObject {
    const now = new Date();
    const diff = targetDate.getTime() - now.getTime();

    if (diff <= 0) {
      return { day: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const seconds = Math.floor((diff / 1000) % 60);
    const minutes = Math.floor((diff / 1000 / 60) % 60);
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
    const day = Math.floor(diff / (1000 * 60 * 60 * 24));

    return { day, hours, minutes, seconds };
  }

  function startCountdown(
    targetDate: Date,
    onTick: (time: TimeObject) => void
  ) {
    const intervalId = setInterval(() => {
      const timeRemaining = convertDateToTimeObject(targetDate);
      onTick(timeRemaining);

      // Останавливаем таймер, если время истекло
      if (
        timeRemaining.day === 0 &&
        timeRemaining.hours === 0 &&
        timeRemaining.minutes === 0 &&
        timeRemaining.seconds === 0
      ) {
        clearInterval(intervalId);
      }
    }, 1000);
  }
  //включение таймера отсчета

  //функция возвращающая задание, поиск по enum

  function SearchTaskEnum(task: TaskEnum, target: number, desk?: number) {
    switch (task) {
      case TaskEnum.Cases:
        return `Откройте ${target} кейсов`;
      case TaskEnum.Contracts:
        return `Сделайте ${target} контрактов`;
      case TaskEnum.Dies:
        return `Умрите ${target} раз`;
      case TaskEnum.KillByMode:
        return `Сделайте ${target} убийств на ${
          desk && serverMode?.find((item) => item.value == desk)?.label
        }`;
      case TaskEnum.KillHeadshot:
        return `Сделайте ${target} убийств в голову`;
      case TaskEnum.KillNoscope:
        return `Сделайте ${target} убийств без прицела с AWP`;
      case TaskEnum.Kills:
        return `Сделайте ${target} убийств`;
      case TaskEnum.PlayTimeMode:
        return `Сыграйте ${target / 60} минут на ${
          desk && serverMode?.find((item) => item.value == desk)?.label
        }`;
      case TaskEnum.Playtime:
        return `Сыграйте ${target / 60} минут на проекте`;
      case TaskEnum.Socials:
        return `Подпишитесь на соцсети`;
      case TaskEnum.SpendCredits:
        return `Потратьте ${target} кредитов`;
    }
  }

  function SearchPrizeTextBattl(prize: PrizeEnum, value: string | null) {
    switch (prize) {
      case PrizeEnum.Vip:
        return `Vip ${Number(value) / 60 / 60 / 24} дней`;
      case PrizeEnum.Premium:
        return `PREMIUM ${Number(value) / 60 / 60 / 24} дней`;
      case PrizeEnum.Nothing:
        return "";
      case PrizeEnum.Cash:
        return `${value} Рублей`;
      case PrizeEnum.Credits:
        return `${value} Кредитов`;
      case PrizeEnum.Skin:
        return `Скин (${value}ур)`;
    }
  }
  function SearchPrizeImgBatll(prize: PrizeEnum) {
    switch (prize) {
      case PrizeEnum.Cash:
        return (
          <Image
            src="/static/images/RubBattlPass.png"
            width="180px"
            alt="prize"
          />
        );
      case PrizeEnum.Credits:
        return (
          <Image
            src="/static/images/CreditBattlPass.png"
            width="180px"
            alt="prize"
          />
        );
      case PrizeEnum.Nothing:
        return null;
      case PrizeEnum.Premium:
        return (
          <Image
            src="/static/images/PREMIUMBattlPass.png"
            width="180px"
            alt="prize"
          />
        );
      case PrizeEnum.Skin:
        return (
          <Image
            src="/static/images/SkinBattlPass.webp"
            width="180px"
            alt="prize"
          />
        );
      case PrizeEnum.Vip:
        return (
          <Image
            src="/static/images/VIPBattlPass.png"
            width="180px"
            alt="prize"
          />
        );
    }
  }

  async function getPrize(item: levels, Vip: boolean) {
    const vipNum = Vip ? 1 : 0;
    const result = await BattlPassServise.getPrize(item.id, vipNum);
    if (result == true && battlPassStat) {
      const updatedBattlPassStat = { ...battlPassStat };

      const levelIndex = updatedBattlPassStat.levels.findIndex(
        (level) => level.id === item.id
      );
      // console.log(levelIndex);
      // Обновляем значение earned в зависимости от Vip
      if (Vip) {
        updatedBattlPassStat.levels[levelIndex].earnedVip = true;
      } else {
        updatedBattlPassStat.levels[levelIndex].earned = true;
      }

      // Обновляем состояние с новым значением
      setBattlPassStat(updatedBattlPassStat);
      toast.success("Успешно");
    } else {
      if (result == "Not enough rights") {
        toast.error("У Вас нет Premium");
      } else if (result == "Trade offer not linked") {
        toast.error("У Вас не указана Trade ссылка");
      } else if (result == "НУ ты дурак?") {
        toast.error("Вы не можете активировать услугу ниже");
      }
    }
    if (result == true && Vip) {
      switch (item.vipPrize) {
        case PrizeEnum.Cash:
          setBalance({
            real: user.balance.real + Number(item.vipValue),
            virtual: user.balance.virtual,
          });
          return;
        case PrizeEnum.Credits:
          setBalance({
            real: user.balance.real,
            virtual: user.balance.virtual + Number(item.vipValue),
          });
          return;
      }
    }
    if (result == true && !Vip) {
      switch (item.prize) {
        case PrizeEnum.Cash:
          setBalance({
            real: user.balance.real + Number(item.value),
            virtual: user.balance.virtual,
          });
          return;
        case PrizeEnum.Credits:
          setBalance({
            real: user.balance.real,
            virtual: user.balance.virtual + Number(item.value),
          });
          return;
      }
    }
  }

  return (
    <Layout headerElement={<div />} leftElement={<div />}>
      <Helmet>
        <title>FREEZ-BLOOD - Боевой пропуск</title>
        <meta name="description" content={`FREEZ-BLOOD - Контракты`} />
      </Helmet>
      <WrapperBattlePassMain>
        <WrapFlexSpaceBeet>
          <BattlPassName>
            <SmallText70>Сезон {battlPassStat?.id}</SmallText70>
            <BigText>Боевой пропуск</BigText>
            {/* <BigText>Бета-тестирование</BigText> */}
            <WrappDefaultFlex>
              <SmallText70>До конца сезона:</SmallText70>
              <WrappDefaultFlex>
                <TimeBattlPassRemeining>
                  {timeRemaining.day < 10
                    ? `0${timeRemaining.day}`
                    : timeRemaining.day}
                  д
                </TimeBattlPassRemeining>
                :
                <TimeBattlPassRemeining>
                  {timeRemaining.hours < 10
                    ? `0${timeRemaining.hours}`
                    : timeRemaining.hours}
                  ч
                </TimeBattlPassRemeining>
                :
                <TimeBattlPassRemeining>
                  {timeRemaining.minutes < 10
                    ? `0${timeRemaining.minutes}`
                    : timeRemaining.minutes}
                  м
                </TimeBattlPassRemeining>
                :
                <TimeBattlPassRemeining>
                  {timeRemaining.seconds < 10
                    ? `0${timeRemaining.seconds}`
                    : timeRemaining.seconds}
                  с
                </TimeBattlPassRemeining>
              </WrappDefaultFlex>
            </WrappDefaultFlex>
          </BattlPassName>
          <WrappDefaultFlex>
            <TinyIcons.MedalPoints
              style={{ fill: "#FAB700", stroke: "#FAB700" }}
            />
            {battlPassMe?.xpToNextLevel == 99999999 ? (
              <SmallText100>У Вас {battlPassMe?.xp} опыта</SmallText100>
            ) : (
              <SmallText100>
                {battlPassMe?.xpToNextLevel} XP до следующего уровня
              </SmallText100>
            )}

            <Button
              variant="default"
              height="5rem"
              color="blue"
              onClick={() => setActiveModalXp(true)}
            >
              <TinyIcons.MedalPoints />
              Купить опыт
            </Button>
          </WrappDefaultFlex>
        </WrapFlexSpaceBeet>

        <BattlPassWrapperPrize>
          <BattlPassPrizeLine>
            <Verticaltext orange={false}> Бесплатный</Verticaltext>

            {battlPassMe &&
              battlPassStat?.levels.map((item) => {
                return (
                  <BlockPrize
                    noupBG={item.prize == PrizeEnum.Nothing}
                    prem={false}
                  >
                    <WrapFlexSpaceBeet2 style={{ width: "100%" }}>
                      {item.earned == true &&
                      item.prize !== PrizeEnum.Nothing ? (
                        <BlockEarn>
                          <Icons.EarnedBP />
                        </BlockEarn>
                      ) : (
                        <div />
                      )}
                      {item.prize == PrizeEnum.Skin ? <QuechenButt /> : <div />}
                    </WrapFlexSpaceBeet2>
                    {SearchPrizeImgBatll(item.prize)}
                    {item.xp <= battlPassMe?.xp &&
                    item.prize !== PrizeEnum.Nothing &&
                    item.earned == false ? (
                      <Button
                        height="5rem"
                        color="blue"
                        variant="default"
                        style={{ width: "100%" }}
                        onClick={() => {
                          getPrize(item, false);
                        }}
                      >
                        Получить
                      </Button>
                    ) : (
                      <SmallText100
                        style={{
                          height: "5rem",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        {SearchPrizeTextBattl(item.prize, item.value)}
                      </SmallText100>
                    )}
                  </BlockPrize>
                );
              })}
          </BattlPassPrizeLine>
          <BattlPassPrizeLine style={{ justifyContent: "center", gap: "0" }}>
            <Verticaltext
              orange={true}
              style={{ marginRight: "9.5rem" }}
            ></Verticaltext>
            {battlPassMe &&
              battlPassStat?.levels.map((item) => {
                return (
                  <EarnProgressBar
                    earn={item.earned}
                    xpLevel={item.xp}
                    xpMe={battlPassMe.xp}
                    level={item.level}
                    maxLevel={battlPassStat.levels.length}
                  />
                );
              })}
          </BattlPassPrizeLine>
          <BattlPassPrizeLine>
            <Verticaltext orange={true}>
              <Icons.BattlPass style={{ fill: "#FAB700", stroke: "#FAB700" }} />
              Premium
            </Verticaltext>
            {battlPassMe &&
              battlPassStat?.levels.map((item) => {
                return (
                  <BlockPrize
                    noupBG={item.vipPrize == PrizeEnum.Nothing}
                    prem={true}
                  >
                    <WrapFlexSpaceBeet2
                      style={{ width: "100%", flexDirection: "row" }}
                    >
                      {item.earnedVip == true &&
                      item.vipPrize !== PrizeEnum.Nothing ? (
                        <BlockEarn>
                          <Icons.EarnedBP />
                        </BlockEarn>
                      ) : (
                        <div />
                      )}
                      {item.vipPrize == PrizeEnum.Skin ? (
                        <QuechenButt />
                      ) : (
                        <div />
                      )}
                    </WrapFlexSpaceBeet2>
                    {SearchPrizeImgBatll(item.vipPrize)}
                    {item.xp <= battlPassMe?.xp &&
                    item.vipPrize !== PrizeEnum.Nothing &&
                    item.earnedVip == false ? (
                      <Button
                        height="5rem"
                        color="blue"
                        variant="default"
                        style={{ width: "100%" }}
                        onClick={() => {
                          getPrize(item, true);
                        }}
                      >
                        Получить
                      </Button>
                    ) : (
                      <SmallText100
                        style={{
                          height: "5rem",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        {SearchPrizeTextBattl(item.vipPrize, item.vipValue)}
                      </SmallText100>
                    )}
                  </BlockPrize>
                );
              })}
          </BattlPassPrizeLine>
        </BattlPassWrapperPrize>

        <WrapFlexSpaceBeet>
          <BlockTopTool>
            <WrappDefaultFlex>
              <TinyIcons.MedalPoints
                style={{ fill: "#FAB700", stroke: "#FAB700" }}
              />
              <MediumText100>Топ по XP</MediumText100>
            </WrappDefaultFlex>
            <WrapBlockPlayer>
              {battlPassStat?.stats.map((item, id) => (
                <PlayerBlockTOp key={id}>
                  <WrappDefaultFlex
                    onClick={() => {
                      navigate(`/profile/${item.steamid64}`);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <Avatar size="super-small" src={item.avatar} />
                    <SmallText100
                      style={{
                        maxWidth: "15rem",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {item.name}
                    </SmallText100>
                  </WrappDefaultFlex>
                  <WrappDefaultFlex>
                    <SmallText100>{item.xp}</SmallText100>
                    <TinyIcons.MedalPoints
                      style={{ fill: "#FAB700", stroke: "#FAB700" }}
                    />
                  </WrappDefaultFlex>
                </PlayerBlockTOp>
              ))}
            </WrapBlockPlayer>
          </BlockTopTool>
          <BlockTask>
            <WrapFlexSpaceBeet style={{ alignItems: "center" }}>
              <WrappDefaultFlex>
                <TinyIcons.TaskBP />
                <MediumText100>Список заданий</MediumText100>
              </WrappDefaultFlex>
              <SmallText100 style={{ width: "21rem" }}>
                До обновления:&nbsp;
                {timeRemainingTasks.hours < 10
                  ? `0${timeRemainingTasks.hours}`
                  : timeRemainingTasks.hours}
                :
                {timeRemainingTasks.minutes < 10
                  ? `0${timeRemainingTasks.minutes}`
                  : timeRemainingTasks.minutes}
                :
                {timeRemainingTasks.seconds < 10
                  ? `0${timeRemainingTasks.seconds}`
                  : timeRemainingTasks.seconds}
              </SmallText100>
            </WrapFlexSpaceBeet>
            <WrapBlockPlayer>
              {battlPassMe?.tasks.stats.map((item, index) => (
                <PlayerBlockTOp key={index} pad={true}>
                  <SmallText100>
                    {item.type == TaskEnum.PlayTimeMode ||
                    item.type == TaskEnum.KillByMode
                      ? SearchTaskEnum(
                          item.type,
                          item.target,
                          Number(item.description)
                        )
                      : SearchTaskEnum(item.type, item.target)}
                  </SmallText100>
                  <WrappDefaultFlex2 style={{ gap: "1rem" }}>
                    {item.type == TaskEnum.Socials ? (
                      <Button
                        color="shadow"
                        variant="default"
                        height="5rem"
                        onClick={() => {
                          setIsActiveSoc(true);
                          setItemLevel(item);
                        }}
                      >
                        Подписаться
                        <Icons.ArrowBack
                          style={{
                            transform: "rotate(135deg)",
                            width: "20px",
                            height: "10px",
                          }}
                        />
                      </Button>
                    ) : null}
                    {item.type !== TaskEnum.Socials ? (
                      <SmallText100>
                        {item.type == TaskEnum.PlayTimeMode ||
                        item.type == TaskEnum.Playtime
                          ? `${Math.round(item.stats / 60)}/${item.target / 60}`
                          : `${item.stats}/${item.target}`}
                      </SmallText100>
                    ) : null}

                    <XpPrizeTask>
                      {item.stats >= item.target ? (
                        <Icons.EarnedBP />
                      ) : (
                        <>
                          +{item.exp}
                          <TinyIcons.MedalPoints
                            style={{ fill: "#FAB700", stroke: "#FAB700" }}
                          />
                        </>
                      )}
                    </XpPrizeTask>
                  </WrappDefaultFlex2>
                </PlayerBlockTOp>
              ))}
            </WrapBlockPlayer>
          </BlockTask>
        </WrapFlexSpaceBeet>
      </WrapperBattlePassMain>
      <Modals.BuyModal
        isActive={activeModalXp}
        setActive={setActiveModalXp}
        xpNextLevel={battlPassMe?.xpToNextLevel}
        xp={battlPassMe?.xp}
        BattlPassMe={battlPassMe}
        setBattlPassMe={setBattlPassMe}
      />
      {itemLevel && (
        <Modals.SocialBattlPass
          setActive={setIsActiveSoc}
          isActive={isActiveSoc}
          BattlPassMeSocial={itemLevel}
        />
      )}
    </Layout>
  );
}
