import Button from "components/UI/Button";

import { Container, DefaultFlexWrap, Title } from "./Styles";
import { useAppSelector } from "hooks/useAppSelector";
import { endpoints, url } from "API";

interface Props {
  title?: React.ReactElement | string;
  buttonText?: string;
  callback?: () => void;
  params?: Params;
  callback2?: () => void;
}

export interface Params {
  titleFont?: string;
}

export default function UserSupportBlock({
  title,
  buttonText,
  callback,
  params,
  callback2,
}: Props) {
  const { isAuth, user } = useAppSelector((state) => state.user);
  function loginHandler() {
    window.location.href = url + endpoints.auth.login;
  }
  return (
    <Container params={params}>
      <Title params={params}>{title}</Title>
      <DefaultFlexWrap>
        <Button color="blue" variant="default" height="5rem" onClick={callback}>
          {buttonText}
        </Button>
        {callback2 && isAuth ? (
          <Button
            color="blue"
            variant="default"
            height="5rem"
            onClick={callback2}
          >
            Заявка на разблокировку
          </Button>
        ) : (
          <Button
            color="blue"
            variant="default"
            height="5rem"
            onClick={() => loginHandler()}
          >
            Авторизоваться
          </Button>
        )}
      </DefaultFlexWrap>
    </Container>
  );
}
