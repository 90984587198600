export default function Home() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.28066 14.9247V26.9999C4.28066 27.5522 4.72838 27.9999 5.28066 27.9999H24.2628C24.815 27.9999 25.2627 27.5522 25.2627 26.9999V14.9247C25.2627 14.3724 25.7105 13.9247 26.2627 13.9247H26.9995C27.5518 13.9247 27.9995 13.477 27.9995 12.9247V12.5602C27.9995 12.2386 27.8449 11.9366 27.5839 11.7487L23.8539 9.06307C23.5929 8.87514 23.4382 8.57316 23.4382 8.25154V3.66449C23.4382 3.11221 22.9905 2.66449 22.4382 2.66449H21.2453C20.693 2.66449 20.2453 3.11221 20.2453 3.66449V5.74527C20.2453 5.82831 20.15 5.87517 20.0842 5.82445L15.3969 2.20847C15.0304 1.92568 14.5176 1.93149 14.1576 2.22249L2.37143 11.7478C2.13652 11.9377 2 12.2235 2 12.5256V12.9247C2 13.477 2.44772 13.9247 3 13.9247H3.28066C3.83295 13.9247 4.28066 14.3724 4.28066 14.9247ZM11.5789 16.8366V23.8208C11.5789 24.3731 12.0266 24.8208 12.5789 24.8208H17.4208C17.9731 24.8208 18.4208 24.3731 18.4208 23.8208V16.8366C18.4208 16.2843 17.9731 15.8366 17.4208 15.8366H12.5789C12.0266 15.8366 11.5789 16.2843 11.5789 16.8366Z"
        fill="white"
        fillOpacity="0.7"
      />
    </svg>
  );
}
