import { endpoints, url } from "API";
import { MarketplaceService } from "API/MarketplaceService";
import { socketListener } from "API/SocketProvider";
import { appSocket } from "App";
import { Icons } from "assets/icons";
import { TinyIcons } from "assets/tiny-icons";
import Avatar from "components/Avatar";
import WeaponCard from "components/Cards/WeaponCard";
import Column from "components/Column";
import Layout from "components/Layout";
import LayoutIcon from "components/LayoutIcon";
import { Modals } from "components/Modal";
import Row from "components/Row";
import Filters from "components/Templates/Filters";
import UIKIT from "components/UI";
import Button from "components/UI/Button";
import { MarketplaceContext } from "context/marketplace";
import { useActions } from "hooks/useActions";
import { useAppSelector } from "hooks/useAppSelector";
import { Banss } from "models/User";
import { IWeaponCard } from "models/WeaponCard";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { tradeData } from "utils/convertData";
import rarityConvector from "utils/rarityConvector";
import { UpdateBalance, UpItem } from "./MarketplaceState";
import {
  BlockFilter,
  BlockItemFilter,
  Body,
  BodyElement,
  BodyItem,
  BodyItemItem,
  // Server,
  Duration,
  Head,
  HeadItem,
  Reason,
  Table,
  Weapons,
} from "./Styles";

interface test {
  data: IWeaponCard[];
}

enum ModeList {
  buy = 0,
  sell = 1,
  change = 2,
  history = 3,
}

enum HistoryType {
  sell = 0,
  buy = 1,
}
interface marketplaceHistory {
  success: boolean;
  data: HistoryItem[];
}

interface HistoryItem {
  steamid64: string;
  avatar: string;
  date: string;
  name: string;
  price: number;
  type: HistoryType;
  skinInfo: {
    name: string;
    rarity: number;
  };
}

export default function Marketplace() {
  const { marketInventory } = useAppSelector((state) => state.user);
  const { totalInv } = useAppSelector((state) => state.user);
  const { selling } = useAppSelector((state) => state.marketplace);
  const { total } = useAppSelector((state) => state.marketplace);
  const { isAuth } = useAppSelector((state) => state.user);
  ////

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [classId1, setClassId] = useState<number>(1);
  const [weapon, setWeapon] = useState<number>();
  const [raritySort, setRaritySort] = useState<string | undefined>(undefined);
  const [priceSort, setPriceSort] = useState<string | undefined>(undefined);

  const [useSortPrice, setUseSortPrice] = useState<boolean | undefined>(
    undefined
  );
  const [useSortRarity, setUseSortRarity] = useState<boolean | undefined>(
    undefined
  );
  const [useClass, setUseClass] = useState<boolean>();
  // console.log(stateUp);
  const { user } = useAppSelector((state) => state.user);
  const [modal, setModal] = useState(false);
  const [mode, setMode] = useState(0);
  const perPage: number = PageWidth();

  // useEffect(() => {
  //   console.log("lox", _);
  // }, []);

  // useEffect(() => {}, [page, total, totalInv, weapon, raritySort, classId1]);
  useEffect(() => {
    setPage(1);
  }, [mode, weapon, classId1, raritySort, priceSort]);
  // console.log("perPage", perPage);
  useEffect(() => {
    setClassId(1);
    setWeapon(undefined);
    setRaritySort(undefined);
    setPriceSort(undefined);
    setUseClass(undefined);
  }, [mode]);

  useEffect(() => {
    if (mode == ModeList.buy) {
      setTotalPage(total);
    } else if (mode == ModeList.sell) {
      setTotalPage(totalInv || 0);
    }
  }, [mode, total, totalInv]);

  const [currentWeapon, setCurrentWeapon] = useState<IWeaponCard>({
    steamid: "",
    category: "",
    item: 1,
    group: 1,
    price: "1",
    src: "",
    cl: false,
    skin: "",
    weapon: "",
    rarity: "mil_spec",
    paint_kit_id: null,
  });

  function PageWidth() {
    const screenWidth = window.screen.width;
    switch (true) {
      case screenWidth < 4604 && screenWidth >= 3799:
        return 75;
      case screenWidth < 3799 && screenWidth >= 3627:
        return 80;
      case screenWidth < 3627 && screenWidth >= 3455:
        return 76;
      case screenWidth < 3455 && screenWidth >= 3284:
        return 72;
      case screenWidth < 3284 && screenWidth >= 3114:
        return 68;
      case screenWidth < 3114 && screenWidth >= 2943:
        return 80;
      case screenWidth < 2943 && screenWidth >= 2771:
        return 75;
      case screenWidth < 2771 && screenWidth >= 2599:
        return 70;
      case screenWidth < 2599 && screenWidth >= 2427:
        return 65;
      case screenWidth < 2427 && screenWidth >= 2255:
        return 60;
      case screenWidth < 2255 && screenWidth >= 2083:
        return 55;
      case screenWidth < 2083 && screenWidth >= 1911:
        return 60;
      case screenWidth < 1911 && screenWidth >= 1739:
        return 54;
      case screenWidth < 1739 && screenWidth >= 1567:
        return 48;
      case screenWidth < 1567 && screenWidth >= 1395:
        return 42;
      case screenWidth < 1395 && screenWidth >= 1251:
        return 36;
      case screenWidth < 1251 && screenWidth >= 1197:
        return 42;
      case screenWidth < 1197 && screenWidth >= 3627:
        return 60;
      case screenWidth < 1395 && screenWidth >= 1251:
        return 36;
      case screenWidth < 1251 && screenWidth >= 1030:
        return 36;
      case screenWidth < 1030 && screenWidth >= 863:
        return 30;
      case screenWidth < 863 && screenWidth >= 696:
        return 28;
      case screenWidth < 696 && screenWidth >= 100:
        return 30;
      default:
        return 30;
    }
  }

  const { fetchMarketplace, fetchSalesItems, fetchMarketInventory } =
    useActions();
  const { data }: test = useAppSelector((state) => state.marketplace);
  const [history, setHistory] = useState<marketplaceHistory>({
    success: false,
    data: [],
  });

  // useEffect(() => {
  //   setTotal
  // },[mode])

  //------------------------------------------------------------------------------------------------------------------------
  const [newArrayData, setNewArrayData] = useState<IWeaponCard[]>([]);
  const [newArraySell, setNewArraySell] = useState<IWeaponCard[]>([]);
  const [newArrayMarket, setNewArrayMarket] = useState<IWeaponCard[]>([]);
  const [stateUpInv, setStateUpInv] = useState<UpItem>();
  // const [timer, setTimer] = useState(false);
  const [stateUp, setStateUp] = useState<UpdateBalance>();
  // const [idNumberMarket, setIdNumberMarket] = useState<number[]>([]);

  useEffect(() => {
    appSocket.on(socketListener.marketUpdate, (action: UpdateBalance) => {
      // console.log("action", action);
      setStateUp(action);
    });
    appSocket.on(socketListener.updateInventory, (action: UpItem) => {
      setStateUpInv(action);
    });
  }, []);

  useEffect(() => {
    if (!stateUp) {
      setNewArrayData(data);
      setNewArraySell(selling);
      setNewArrayMarket(marketInventory);
    }
  }, [data, selling, marketInventory]);

  useEffect(() => {
    setNewArrayMarket(marketInventory);
  }, [marketInventory]);
  useEffect(() => {
    setNewArrayData(data);
  }, [data]);

  useEffect(() => {
    if (stateUp?.action == "DELETE" && mode === ModeList.buy) {
      DeleteItemMarket();
    }
    if (stateUp?.action == "UPDATE" && mode === ModeList.buy) {
      UpdateItemMarket();
    }
    if (
      stateUp?.action == "ADD" &&
      page === Math.round(total / perPage) + 1 &&
      classId1 == 1
    ) {
      // console.log(1);
      AddItemMarket();
    }
    if (stateUp?.action == "ADD" && mode === ModeList.sell) {
      DeleteAddItemInventory();
    }
    if (stateUp?.action == "DELETE" && mode === ModeList.change) {
      DeleteItemChange();
    }
    if (stateUp?.action == "UPDATE" && mode === ModeList.change) {
      UpdateItemChange();
    }
    console.log("stateUpInv1", stateUpInv);
    console.log("mode", mode);
    if (
      stateUp?.action == "ADD" &&
      mode === ModeList.sell &&
      stateUp.item.item_id === stateUpInv?.item.id &&
      stateUpInv?.item.price !== null &&
      stateUpInv
    ) {
      console.log("stateUpInv2", stateUpInv);
      setNewArraySell([...newArraySell, stateUpInv.item]);
    }
    if (
      stateUp?.action == "DELETE" &&
      mode == ModeList.change &&
      stateUp.item.item_id === stateUpInv?.item.id &&
      stateUpInv
    ) {
      setNewArrayMarket([...newArrayMarket, stateUpInv.item]);
    }
    if (
      stateUp?.action == "DELETE" &&
      mode == ModeList.buy &&
      stateUp.item.item_id == stateUpInv?.item.id &&
      stateUpInv
    ) {
      setNewArrayMarket([...newArrayMarket, stateUpInv.item]);
    }
  }, [stateUp, stateUpInv]);

  function UpdateItemChange() {
    let j: IWeaponCard[] = [];
    // console.log(item)

    // console.log(user.user.id)
    newArraySell.forEach((item) => {
      if (item.id == stateUp?.item.item_id) {
        const obj: IWeaponCard = {
          id: item.id,
          steamid: item.steamid64,
          nickname: item.nickname,
          avatar: item.avatar,
          src: item.src,
          price: stateUp?.item.price,
          skin: item.skin,
          weapon: item.weapon,
          rarity: item.rarity,
        };
        j.push(obj);
      } else {
        j.push(item);
      }
    });
    setNewArraySell(j);
  }

  function DeleteItemChange() {
    let j: IWeaponCard[] = [];

    newArraySell.forEach((item) => {
      if (item.id !== stateUp?.item.item_id) {
        j.push(item);
      }
    });
    setNewArraySell(j);
  }

  function DeleteAddItemInventory() {
    let j: IWeaponCard[] = [];

    // console.log("user.user.steamid64", user.user.steamid64);
    // console.log("stateUp?.item.steamid64", stateUp?.item.steamid64);
    // console.log("user.user.steamid64", user.user.steamid64);
    // console.log("stateUp?.item.item_id", stateUp?.item.item_id);

    newArrayMarket.forEach((item) => {
      // console.log("item.id", item.id);

      if (item.id !== stateUp?.item.item_id) {
        j.push(item);
      }
    });
    setNewArrayMarket(j);
  }
  function AddItemMarket() {
    let j: IWeaponCard[] = [];
    newArrayData.forEach((item) => {
      j.push(item);
    });
    if (
      stateUp?.item.src !== undefined &&
      stateUp?.item.steamid !== user.user.steamid64
    ) {
      j.push(stateUp?.item);
    }
    setNewArrayData(j);
  }

  function UpdateItemMarket() {
    let j: IWeaponCard[] = [];

    newArrayData.forEach((item) => {
      if (item.id == stateUp?.item.id) {
        const obj: IWeaponCard = {
          id: item.id,
          steamid: item.steamid64,
          nickname: item.nickname,
          avatar: item.avatar,
          src: item.src,
          price: stateUp?.item.price,
          skin: item.skin,
          weapon: item.weapon,
          rarity: item.rarity,
        };
        j.push(obj);
      } else {
        j.push(item);
      }
    });
    setNewArrayData(j);
  }

  function DeleteItemMarket() {
    let j: IWeaponCard[] = [];

    newArrayData.forEach((item) => {
      if (item.id !== stateUp?.item.id) {
        j.push(item);
      }
    });
    // console.log(j);
    setNewArrayData(j);
  }

  const windowInnerWidth = document.documentElement.clientWidth;

  useEffect(() => {
    setFoo("");
  }, [mode]);

  interface filters {
    id: number;
    single: boolean;
    name: string;
    items: filterItem[];
  }
  interface filterItem {
    id: number;
    item_id: number;
    class_ru: string;
  }

  const [filters, setFilters] = useState<Array<filters>>([]);
  const [_, setFoo] = useState<string | undefined>();
  // console.log(_);
  const [_1, setFoo1] = useState("");
  const [filItem, setFilitem] = useState([""]);

  //-------------------------------------------------------------

  //------------------------------------------------------
  useEffect(() => {
    const fetchFilters = async () => {
      const filters = await MarketplaceService.getFilters();
      setFilters(filters.classes);
    };
    // PageWidth();
    fetchFilters();
    // fetchMarketplace({
    //   page: page,
    //   perPage: perPage,
    //   classId: classId1,
    //   weapon: weapon,
    //   priceSort: priceSort,
    //   raritySort: raritySort,
    // });
    fetchSalesItems();
    fetchMarketInventory({
      page: page,
      perPage: perPage,
      classId: classId1,
      weapon: weapon,
      raritySort: raritySort,
    });
  }, [1]);

  useEffect(() => {
    switch (mode) {
      case 0:
        fetchMarketplace({
          page: page,
          perPage: perPage,
          classId: classId1,
          weapon: weapon,
          priceSort: priceSort,
          raritySort: raritySort,
        });
        break;
      case 1:
        fetchMarketInventory({
          page: page,
          perPage: perPage,
          classId: classId1,
          weapon: weapon,
          raritySort: raritySort,
        });

        break;
      case 2:
        fetchSalesItems();
        break;
    }

    // console.log(data);
  }, [classId1, weapon, priceSort, raritySort, weapon, page, mode]);

  // console.log(
  //   "ban",
  //   user?.blocks?.find((item) => item.banned.type == Banss.ban)
  // );

  function priceUpdate() {
    // fetchMarketplace({
    //   page: page,
    //   perPage: perPage,
    //   classId: classId1,
    //   weapon: weapon,
    //   priceSort: priceSort,
    //   raritySort: raritySort,
    // });
    // fetchSalesItems();
    fetchMarketInventory({
      page: page,
      perPage: perPage,
      classId: classId1,
      weapon: weapon,
      raritySort: raritySort,
    });
  }

  useEffect(() => {
    setUseClass(true);
    let a;
    setFoo1("");
    filters.map((item) => {
      if (_ == item.name) {
        setClassId(item.id);
        setWeapon(undefined);
        a = item.items.map((item) => {
          return item.class_ru;
        });
      }
      return;
    });

    if (a !== null && a !== undefined) {
      setFilitem(a);
    }
  }, [_]);

  useEffect(() => {
    MarketplaceService.getMarketplaceHistory().then((data) => {
      setHistory(data);
    });
  }, [mode == ModeList.history]);

  function loginHandler() {
    window.location.href = url + endpoints.auth.login;
  }

  useEffect(() => {
    setUseClass(false);
    filters.map((item) => {
      item.items.map((i) => {
        if (i.class_ru == _1) {
          setWeapon(i.id);
        }
      });
    });
  }, [_1]);

  const isMySkin = (id: any) => {
    return id === user.user?.steamid64;
  };

  function sortPrice() {
    setUseSortPrice(true);
    setUseSortRarity(false);
    setRaritySort(undefined);
    if (priceSort == undefined) {
      setPriceSort("DESC");
    }
    if (priceSort == "ASC") {
      setPriceSort("DESC");
    }
    if (priceSort == "DESC") {
      setPriceSort("ASC");
    }
  }

  function sortRarity() {
    setPriceSort(undefined);
    setUseSortRarity(true);
    setUseSortPrice(false);
    if (raritySort == undefined) {
      setRaritySort("DESC");
    }
    if (raritySort == "ASC") {
      setRaritySort("DESC");
    }
    if (raritySort == "DESC") {
      setRaritySort("ASC");
    }
  }

  const getItems = () => {
    // console.log("fsdfsdfdsfsdfsdfsdfdsf");
    // console.log(MarketPlaceState.UpdateMarket);
    if (mode == ModeList.buy) {
      return (
        newArrayData &&
        newArrayData.map((item, index) => (
          <WeaponCard
            key={index}
            cl={false}
            src={item.src}
            skin={item.skin}
            weapon={item.weapon}
            price={item.price}
            nickname={item.nickname}
            avatar={item.avatar}
            paint_kit_id={null}
            rarity={rarityConvector(+item.rarity)}
            // rarity="exceedingly_rare"
            owner={item.owner}
            onClick={() => {
              if (isMySkin(item?.steamid64)) return;
              setCurrentWeapon(newArrayData[index]);
              setModal(true);
            }}
            disableAvatar
          />
        ))
      );
    }

    if (mode == ModeList.sell) {
      return newArrayMarket.map((item, index) => {
        return (
          <WeaponCard
            key={index}
            src={item.src}
            paint_kit_id={null}
            cl={false}
            skin={item.skin}
            weapon={item.weapon}
            price={item.price}
            rarity={rarityConvector(+item.rarity)}
            owner={item.owner}
            // counter={item.skinInfo.amount > 1 ? `${item.skinInfo.amount}` : ""}
            onClick={() => {
              setCurrentWeapon(newArrayMarket[index]);
              setModal(true);
            }}
            disablePrice
            disableAvatar
          />
        );
      });
    }

    if (mode == ModeList.change) {
      return newArraySell.map((item, index) => {
        return (
          <WeaponCard
            key={index}
            src={item.src}
            paint_kit_id={null}
            skin={item.skin}
            weapon={item.weapon}
            price={item.price}
            cl={false}
            rarity={rarityConvector(+item.rarity)}
            owner={item.owner}
            onClick={() => {
              setCurrentWeapon(newArraySell[index]);
              setModal(true);
            }}
            disableAvatar
          />
        );
      });
    }

    if (mode == ModeList.history) {
      return (
        <Table>
          <Head>
            <HeadItem>№</HeadItem>
            <HeadItem>Тип</HeadItem>
            <HeadItem>Предмет</HeadItem>
            <HeadItem>Цена (Кредитов)</HeadItem>
            <HeadItem>Дата</HeadItem>
            <HeadItem>Сделка с игроком</HeadItem>
          </Head>
          <Body>
            {history.data &&
              history.data.map((item, index) => {
                const type =
                  item.type == HistoryType.sell ? "Продажа" : "Покупка";
                const typeColor =
                  item.type == HistoryType.sell ? "#E32020" : "#1EE43E";

                return (
                  <BodyElement key={index}>
                    <BodyItem>{index + 1}</BodyItem>
                    <BodyItem color={typeColor}>{type}</BodyItem>
                    <BodyItemItem
                      rarity={rarityConvector(item.skinInfo.rarity)}
                    >
                      {item.skinInfo.name}
                    </BodyItemItem>

                    <BodyItem>{item.price}</BodyItem>
                    <Duration>{tradeData(item.date)}</Duration>
                    <Reason to={`/profile/${item.steamid64}`}>
                      <Avatar
                        isBorder={false}
                        src={item.avatar}
                        size="super-small"
                      />
                      {item.name}
                    </Reason>
                  </BodyElement>
                );
              })}
          </Body>
        </Table>
      );
    }
  };

  return (
    <MarketplaceContext.Provider value={currentWeapon}>
      <Helmet>
        <title>FREEZ-BLOOD - Торговая площадка</title>
        <meta name="description" content="FREEZ-BLOOD - Торговая площадка" />
      </Helmet>
      <Layout
        total={totalPage}
        activePages={
          mode == ModeList.history || mode == ModeList.change ? false : true
        }
        setPage={setPage}
        page={page}
        perPage={perPage}
        headerElement={<div />}
        leftElement={<div />}
      >
        <BlockFilter>
          <BlockItemFilter
            active={mode === ModeList.buy ? true : false}
            onClick={() => setMode(ModeList.buy)}
          >
            Покупка
          </BlockItemFilter>
          {isAuth &&
          user?.blocks?.find((item) => item.banned.type == Banss.ban) ==
            undefined ? (
            <>
              <BlockItemFilter
                active={mode === ModeList.sell ? true : false}
                onClick={() => setMode(ModeList.sell)}
              >
                Продажа
              </BlockItemFilter>
              <BlockItemFilter
                active={mode === ModeList.change ? true : false}
                onClick={() => setMode(ModeList.change)}
              >
                В продаже
              </BlockItemFilter>
              <BlockItemFilter
                active={mode === ModeList.history ? true : false}
                onClick={() => setMode(ModeList.history)}
              >
                История
              </BlockItemFilter>
            </>
          ) : null}
        </BlockFilter>
        {(mode == ModeList.buy || mode == ModeList.sell) &&
        windowInnerWidth > 1250 ? (
          <Filters params={{ width: "unset" }}>
            <UIKIT.DropDown
              onChange={setFoo}
              setUse={useClass}
              // useSort={useSortPrice}
              items={filters.map((item) => {
                // setClassId(item.id);
                return item.name;
              })}
              headParams={{
                title: "Класс:",
                icon: <TinyIcons.Arrow />,
              }}
            />
            {_ !== undefined && _ !== "Все" && _ !== "Агенты" && _ !== "" ? (
              <UIKIT.DropDown
                onChange={setFoo1}
                items={filItem}
                setUse={useClass}
                headParams={{
                  title: "Тип:",
                  icon: <TinyIcons.Arrow />,
                }}
              />
            ) : null}
            {mode == ModeList.buy ? (
              <Button
                height="0"
                variant="Sort"
                params={{ width: "0", padding: "0" }}
                color="Gray"
                onClick={() => sortPrice()}
              >
                <UIKIT.DropDown
                  useSort={useSortPrice}
                  onChange={null}
                  items={["pric"]}
                  headParams={{
                    title: "Цена:",
                    icon: <TinyIcons.Arrow />,
                  }}
                />
              </Button>
            ) : null}
            <Row
              gap="3rem"
              fullWidth
              justifyContent="space-between"
              style={{
                minWidth: windowInnerWidth > 1250 ? "38.1rem" : "",
                maxWidth: windowInnerWidth > 1250 ? "43.2rem" : "",
                height: "2.5rem",
              }}
            >
              <Button
                height="0"
                variant="Sort"
                params={{ width: "0", padding: "0" }}
                color="Gray"
                onClick={() => sortRarity()}
              >
                <UIKIT.DropDown
                  onChange={null}
                  useSort={useSortRarity}
                  items={["rar"]}
                  headParams={{
                    title: "Редкость:",
                    icon: <TinyIcons.Arrow />,
                  }}
                />
              </Button>
            </Row>
          </Filters>
        ) : windowInnerWidth < 1250 ? (
          <Filters params={{ width: "unset" }}>
            <UIKIT.DropDown
              onChange={setFoo}
              setUse={useClass}
              // useSort={useSortPrice}
              items={filters.map((item) => {
                // setClassId(item.id);
                return item.name;
              })}
              headParams={{
                title: "Класс:",
                icon: <TinyIcons.Arrow />,
              }}
            />
            {_ !== undefined && _ !== "Все" && _ !== "Агенты" && _ !== "" ? (
              <UIKIT.DropDown
                onChange={setFoo1}
                items={filItem}
                setUse={useClass}
                headParams={{
                  title: "Тип:",
                  icon: <TinyIcons.Arrow />,
                }}
              />
            ) : null}
            {mode == ModeList.buy ? (
              <Button
                height="0"
                variant="Sort"
                params={{ width: "0", padding: "0" }}
                color="Gray"
                onClick={() => sortPrice()}
              >
                <UIKIT.DropDown
                  useSort={useSortPrice}
                  onChange={null}
                  items={["pric"]}
                  headParams={{
                    title: "Цена:",
                    icon: <TinyIcons.Arrow />,
                  }}
                />
              </Button>
            ) : null}
            <Row
              gap="3rem"
              fullWidth
              justifyContent="space-between"
              style={{
                minWidth: windowInnerWidth > 1250 ? "38.1rem" : "",
                maxWidth: windowInnerWidth > 1250 ? "43.2rem" : "",
                height: "2.5rem",
              }}
            >
              <Button
                height="0"
                variant="Sort"
                params={{ width: "0", padding: "0" }}
                color="Gray"
                onClick={() => sortRarity()}
              >
                <UIKIT.DropDown
                  onChange={null}
                  useSort={useSortRarity}
                  items={["rar"]}
                  headParams={{
                    title: "Редкость:",
                    icon: <TinyIcons.Arrow />,
                  }}
                />
              </Button>
            </Row>
          </Filters>
        ) : null}

        <Weapons isGrid={mode !== ModeList.history}>{getItems()}</Weapons>
        <Modals.MarketplaceWeapon
          isActive={modal}
          setActive={setModal}
          mode={mode}
          priceUpdate={() => priceUpdate}
          marketInventory={marketInventory}
        />
      </Layout>
    </MarketplaceContext.Provider>
  );
}
