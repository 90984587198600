import { MarketplaceService } from "API/MarketplaceService";
import { TinyIcons } from "assets/tiny-icons";
import { InventoryWeaponCard } from "components/Cards";
import Layout from "components/Layout";
import { Modals } from "components/Modal";
import ProfileLayout from "components/ProfileLayout";
import Row from "components/Row";
import SubHeader from "components/SubHeader";
import Filters from "components/Templates/Filters";
import UIKIT from "components/UI";
import Button from "components/UI/Button";
import { useActions } from "hooks/useActions";
import { useAppSelector } from "hooks/useAppSelector";
import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import rarityConvector from "utils/rarityConvector";
import { EModalType } from "./Header/Header";
import { Grid, Wrapper } from "./Styles";
export default function Inventory() {
  interface filters {
    id: number;
    single: boolean;
    name: string;
    items: filterItem[];
  }
  interface filterItem {
    id: number;
    item_id: number;
    class_ru: string;
  }

  const { inventory } = useAppSelector((state) => state.user);
  const { total } = useAppSelector((state) => state.user);
  const { fetchInventory } = useActions();
  const { steamid64 } = useParams();
  const [modal, setModal] = useState(false);
  const [type] = useState(EModalType.fromSite);
  const [page, setPage] = useState(1);
  const [_, setFoo] = useState("Все");
  const [useClass, setUseClass] = useState<boolean>();
  const [filters, setFilters] = useState<Array<filters>>([]);
  const [_1, setFoo1] = useState("");
  const [filItem, setFilitem] = useState([""]);
  const [priceSort, setPriceSort] = useState<string | undefined>(undefined);

  const [useSortPrice, setUseSortPrice] = useState<boolean | undefined>(
    undefined
  );
  const [useSortRarity, setUseSortRarity] = useState<boolean | undefined>(
    undefined
  );
  const [classId1, setClassId] = useState<number>(1);
  const [weaponId, setWeapon] = useState<number>();
  const [raritySort, setRaritySort] = useState<string | undefined>(undefined);
  const perPage: number = PageWidth();
  function PageWidth() {
    const screenWidth = window.screen.width;
    switch (true) {
      case screenWidth < 4638 && screenWidth >= 3791:
        return 68;
      case screenWidth < 3791 && screenWidth >= 3583:
        return 64;
      case screenWidth < 3583 && screenWidth >= 3371:
        return 60;
      case screenWidth < 3371 && screenWidth >= 3159:
        return 56;
      case screenWidth < 3159 && screenWidth >= 2947:
        return 65;
      case screenWidth < 2947 && screenWidth >= 2735:
        return 60;
      case screenWidth < 2735 && screenWidth >= 2523:
        return 55;
      case screenWidth < 2523 && screenWidth >= 2311:
        return 50;
      case screenWidth < 2311 && screenWidth >= 2099:
        return 54;
      case screenWidth < 2099 && screenWidth >= 1887:
        return 48;
      case screenWidth < 1887 && screenWidth >= 1675:
        return 42;
      case screenWidth < 1675 && screenWidth >= 1463:
        return 36;
      case screenWidth < 1463 && screenWidth >= 1078:
        return 30;
      case screenWidth < 1078 && screenWidth >= 861:
        return 24;
      case screenWidth < 861 && screenWidth >= 100:
        return 24;
      default:
        return 30;
    }
  }

  const windowInnerWidth = document.documentElement.clientWidth;
  useEffect(() => {
    const fetchFilters = async () => {
      const filters = await MarketplaceService.getFilters();
      setFilters(filters.classes);
    };
    // PageWidth();
    fetchFilters();
  }, [1]);

  useEffect(() => {
    setPage(1);
  }, [weaponId, classId1, raritySort]);

  // useMemo(() => {
  //   setPage(1);
  //   fetchInventory({
  //     steamid64: steamid64 || "",
  //     page: 1,
  //     classId: 1,
  //     perPage: perPage,
  //   });
  // }, [steamid64]);
  useMemo(() => {
    setUseClass(false);
    filters.map((item) => {
      item.items.map((i) => {
        if (i.class_ru == _1) {
          setWeapon(i.id);
        }
      });
    });
  }, [_1]);

  useMemo(() => {
    fetchInventory({
      steamid64: steamid64 || "",
      page: page,
      classId: classId1,
      perPage: perPage,
      raritySort: raritySort,
      weapon: weaponId,
    });
  }, [steamid64, raritySort, weaponId, classId1, page]);

  // useMemo(() => {
  //   fetchInventory({
  //     steamid64: steamid64 || "",
  //     page: page,
  //     classId: classId1,
  //     perPage: perPage,
  //     raritySort: raritySort,
  //     weapon: weaponId,
  //   });
  // }, [page]);

  useMemo(() => {
    setUseClass(true);
    let a;
    setFoo1("");
    filters.map((item) => {
      if (_ == item.name) {
        setClassId(item.id);
        setWeapon(undefined);
        a = item.items.map((item) => {
          return item.class_ru;
        });
      }
      return;
    });

    if (a !== null && a !== undefined) {
      setFilitem(a);
    }
  }, [_]);

  function sortRarity() {
    setPriceSort(undefined);
    setUseSortRarity(true);
    setUseSortPrice(false);
    if (raritySort == undefined) {
      setRaritySort("DESC");
    }
    if (raritySort == "ASC") {
      setRaritySort("DESC");
    }
    if (raritySort == "DESC") {
      setRaritySort("ASC");
    }
  }

  const inventMemo = useMemo(() => {
    return inventory.map((item, index) => (
      <InventoryWeaponCard
        key={index}
        src={item.src}
        rarity={rarityConvector(+item.rarity)}
        weapon={item.weapon}
        skin={item.skin}
        cl={false}
        counter={item.skinInfo.amount}
      />
    ));
  }, [inventory]);
  return (
    <Layout
      leftElement={<div />}
      headerElement={<SubHeader>Инвентарь</SubHeader>}
      activePages={true}
      total={total}
      setPage={setPage}
      page={page}
      perPage={perPage}
    >
      <Helmet>
        <title>FREEZ-BLOOD - Инвентарь</title>
        <meta name="description" content={`FREEZ-BLOOD - Инвентарь`} />
      </Helmet>
      <ProfileLayout gap="3rem">
        <Wrapper>
          <Filters params={{ width: "unset" }}>
            <UIKIT.DropDown
              onChange={setFoo}
              setUse={useClass}
              // useSort={useSortPrice}
              items={filters.map((item) => {
                // setClassId(item.id);
                return item.name;
              })}
              headParams={{
                title: "Класс:",
                icon: <TinyIcons.Arrow />,
              }}
            />
            {_ !== undefined && _ !== "Все" && _ !== "Агенты" ? (
              <UIKIT.DropDown
                onChange={setFoo1}
                items={filItem}
                setUse={useClass}
                headParams={{
                  title: "Тип:",
                  icon: <TinyIcons.Arrow />,
                }}
              />
            ) : null}

            <Row
              gap="3rem"
              fullWidth
              justifyContent="space-between"
              style={
                windowInnerWidth > 1250
                  ? {
                      minWidth: "38.1rem",
                      maxWidth: "43.2rem",
                    }
                  : {
                      minWidth: "",
                      maxWidth: "",
                    }
              }
            >
              <Button
                height="0"
                variant="Sort"
                params={{ width: "0", padding: "0" }}
                color="Gray"
                onClick={() => sortRarity()}
              >
                <UIKIT.DropDown
                  onChange={null}
                  useSort={useSortRarity}
                  items={["rar"]}
                  headParams={{
                    title: "Редкость:",
                    icon: <TinyIcons.Arrow />,
                  }}
                />
              </Button>
            </Row>
          </Filters>
        </Wrapper>
        <Grid>{inventMemo}</Grid>
      </ProfileLayout>
      <Modals.SkinTransfer isActive={modal} setActive={setModal} type={type} />
    </Layout>
  );
}
