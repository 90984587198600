import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5rem;
`;

export const BlockFilter = styled.div`
  display: flex;
  /* gap: 2rem; */
  /* height: max-content; */
  background: ${(props) => props.theme.colors.white.white10};
  width: 100%;
  border-radius: 1.5rem;
  /* flex-wrap: wrap; */
  @media (max-width: 1250px) {
    flex-direction: column;
  }
`;

export const BlockItemFilter = styled.div<{ active: boolean }>`
  height: 100%;
  background: ${(props) =>
    props.active ? props.theme.colors.blue.accent : ""};
  border-radius: 1.5rem;
  padding: 2rem 1.5rem;
  align-items: center;
  display: flex;
  justify-content: center;
  ${(props) => props.theme.fonts.GothamMedium16} /* flex-wrap: wrap; */
	  cursor: pointer;
  user-select: none;
`;
