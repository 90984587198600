export default function ViShop() {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
    >
      <g data-name="30. Crown" id="_30._Crown" fill="#ffd000" fillOpacity="0.7">
        <path d="M20,19H4a2,2,0,0,0,0,4H20a2,2,0,0,0,0-4Z" />
        <path d="M22,6a2,2,0,0,0-2,2,2.041,2.041,0,0,0,.042.409L16.41,10.59,13.349,4.468a2,2,0,1,0-2.7,0L7.59,10.59,3.958,8.409A2.041,2.041,0,0,0,4,8a2,2,0,1,0-2,2c.037,0,.071-.009.107-.011L2.88,17H21.12l.773-7.011c.036,0,.07.011.107.011a2,2,0,0,0,0-4Z" />
      </g>
    </svg>
  );
}
