import { useEffect, useMemo, useRef, useState } from "react";
import "./style.css";

import rarityConvector from "utils/rarityConvector";
import { BlockDiv, Item, NameSkin, SkinName, WeaponName } from "./styled";

const itemWidth = 190;
const itemsToShow = 5; // Количество элементов, видимых одновременно

export const CasesAnimation = (props) => {
  const scrollContentRef = useRef(null);
  const [newArray, setNewArray] = useState([]);

  const generateNewArray = (arrayItem, vinItem) => {
    const tempArray = [];
    for (let i = 0; i < 80; i++) {
      if (i !== 75) {
        const randomElement =
          arrayItem[Math.floor(Math.random() * arrayItem.length)];
        tempArray.push(randomElement);
      } else {
        tempArray.push(vinItem);
      }
    }
    return tempArray;
  };

  useMemo(() => {
    setNewArray(generateNewArray(props.arrayItem, props.vinItem));
  }, [props.vinItem]);

  const windowInnerWidth = document.documentElement.clientWidth;

  useMemo(() => {
    if (props.isAutoScrolling) {
      const scrollContainer = scrollContentRef.current;
      const itemsToScroll = props.ItemScroll;

      console.log(itemsToScroll);
      const targetScrollPosition = itemsToScroll * itemWidth;
      const animationDuration = 15000; // Время анимации в миллисекундах
      const startTime = performance.now();

      const easingFunction = (progress) => {
        // Используем плавную функцию ease-in-out с дополнительным ускорением к середине
        return progress < 0.5
          ? 4 * Math.pow(progress, 3) // Ускорение в начале
          : 1 - Math.pow(-2 * progress + 2, 3) / 2; // Замедление к концу
      };

      const animateScroll = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const progress = elapsedTime / animationDuration;

        const easedProgress = easingFunction(progress);

        const currentPosition = targetScrollPosition * easedProgress;

        setScrollPosition(currentPosition);

        if (progress <= 1) {
          requestAnimationFrame(animateScroll);
        } else {
          setTimeout(() => {
            props.setIsAutoScrolling(false);
          }, 2500);
        }
      };

      requestAnimationFrame(animateScroll);
    }
  }, [props.isAutoScrolling]);

  const setScrollPosition = (position) => {
    const scrollContainer = scrollContentRef.current;
    scrollContainer.style.transform = `translateX(-${position}px)`;
  };

  return windowInnerWidth >= 1650 ? (
    <div className="container">
      <div
        ref={scrollContentRef}
        className={`scroll-content ${props.isAutoScrolling ? "scrolling" : ""}`}
      >
        {newArray.map((item, index) => (
          <Item
            key={index}
            className="item"
            rarity={rarityConvector(item.rarity)}
          >
            <BlockDiv rarity={rarityConvector(item.rarity)}>&nbsp;</BlockDiv>
            <img src={item.image} alt="" width={"100px"} height="80px" />
            <NameSkin>
              <WeaponName>{item.weapon}</WeaponName>
              <SkinName rarity={rarityConvector(item.rarity)}>
                {item.skin}
              </SkinName>
            </NameSkin>
          </Item>
        ))}
      </div>
    </div>
  ) : windowInnerWidth > 800 && windowInnerWidth < 1650 ? (
    <div className="container2">
      <div
        ref={scrollContentRef}
        className={`scroll-content ${props.isAutoScrolling ? "scrolling" : ""}`}
      >
        {newArray.map((item, index) => (
          <Item
            key={index}
            className="item"
            rarity={rarityConvector(item.rarity)}
          >
            <BlockDiv rarity={rarityConvector(item.rarity)}>&nbsp;</BlockDiv>
            <img src={item.image} alt="" width={"100px"} height="80px" />
            <NameSkin>
              <WeaponName>{item.weapon}</WeaponName>
              <SkinName rarity={rarityConvector(item.rarity)}>
                {item.skin}
              </SkinName>
            </NameSkin>
          </Item>
        ))}
      </div>
    </div>
  ) : (
    <div className="container1">
      <div
        ref={scrollContentRef}
        className={`scroll-content ${props.isAutoScrolling ? "scrolling" : ""}`}
      >
        {newArray.map((item, index) => (
          <Item
            key={index}
            className="item"
            rarity={rarityConvector(item.rarity)}
          >
            <BlockDiv rarity={rarityConvector(item.rarity)}>&nbsp;</BlockDiv>
            <img src={item.image} alt="" width={"100px"} height="80px" />
            <NameSkin>
              <WeaponName>{item.weapon}&nbsp;|&nbsp;</WeaponName>
              <SkinName rarity={rarityConvector(item.rarity)}>
                {item.skin}
              </SkinName>
            </NameSkin>
          </Item>
        ))}
      </div>
    </div>
  );
};
