import { endpoints, url } from "API";
import { CaseServise as CaseService } from "API/CaseService";
import { socketListener } from "API/SocketProvider";
import { appSocket } from "App";
import { Icons } from "assets/icons";
import { TinyIcons } from "assets/tiny-icons";
import Image from "components/Image";
import Layout from "components/Layout";
import Button from "components/UI/Button";
import { useAppSelector } from "hooks/useAppSelector";
import { CaseCategory, ItemLifeScroll } from "interfaces/Case";
import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import SliderLifeScroll from "./SliderLifeScroll/SliderLifeScroll";
import {
  InfoSmall,
  ItemCase,
  PriceKP,
  Text,
  Title,
  TitleCaseName,
  TitleCaseWrapper,
  Wrapper,
  WrapperCaseCategory,
  WrapperCaseItem,
  WrapperLifeScroll,
} from "./styled";

export default function Case() {
  const [openedItems, setOpenedItems] = useState<Array<ItemLifeScroll>>([]);

  const [fullCase, setFullCase] = useState<Array<CaseCategory>>();
  const { isAuth, user } = useAppSelector((state) => state.user);
  useEffect(() => {
    async function getCase() {
      const caseCategory = await CaseService.getCases();
      setFullCase(caseCategory);
    }
    async function getLastItem() {
      const item = await CaseService.GetLastTenItem();
      setOpenedItems(item);
    }
    getCase();
    if (isAuth) {
      getLastItem();
    }
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuth) {
      appSocket.on(socketListener.dropLive, (liveElem: ItemLifeScroll[]) => {
        // const newItem = liveElem.map((item) => {
        //   return item;
        // });
        if (liveElem[0] && !openedItems.includes(liveElem[0])) {
          setOpenedItems((prevItems) => [
            ...liveElem,
            ...prevItems.slice(0, 10 - liveElem.length),
          ]);
        }
      });
    }
  }, [user?.user?.steamid64]);

  function loginHandler() {
    window.location.href = url + endpoints.auth.login;
  }
  const windowInnerWidth = document.documentElement.clientWidth;

  const caseFullMemo = useMemo(() => {
    return fullCase?.map((item) => {
      return (
        <WrapperCaseCategory>
          <TitleCaseWrapper>
            <Icons.ArrowLeftCase />
            <Title>{item.category}</Title>
            <Icons.ArrowRightCase />
          </TitleCaseWrapper>
          <hr />
          <WrapperCaseItem>
            {item.cases.map((items) => {
              return (
                <ItemCase>
                  <Image
                    width={windowInnerWidth > 768 ? "190px" : "120px"}
                    height={windowInnerWidth > 768 ? "146px" : "80px"}
                    src={items.image}
                    alt="Gun"
                  />
                  <TitleCaseName>{items.name}</TitleCaseName>
                  {isAuth ? (
                    <Button
                      variant="picker"
                      height="44"
                      params={{ width: "100%" }}
                      onClick={() => navigate(`/chest/${items.id}`)}
                    >
                      {items.price == 0 ? (
                        <PriceKP Color={true}>БЕСПЛАТНО</PriceKP>
                      ) : (
                        <>
                          <PriceKP>{items.price}</PriceKP>
                          <PriceKP Color={true}>
                            &nbsp; <TinyIcons.Credit />
                          </PriceKP>
                        </>
                      )}
                    </Button>
                  ) : (
                    <Button
                      variant="picker"
                      height="44"
                      params={{ width: "100%" }}
                      onClick={() => loginHandler()}
                      color="blue"
                    >
                      Авторизоваться
                    </Button>
                  )}
                </ItemCase>
              );
            })}
          </WrapperCaseItem>
        </WrapperCaseCategory>
      );
    });
  }, [fullCase, isAuth]);

  return (
    <Layout leftElement={<div />} headerElement={<div />}>
      <Helmet>
        <title>FREEZ-BLOOD - Контейнеры</title>
        <meta name="description" content="FREEZ-BLOOD - Контейнеры" />
      </Helmet>
      <Wrapper>
        {windowInnerWidth > 1400 && fullCase && fullCase[1] && isAuth ? (
          <WrapperLifeScroll>
            <InfoSmall>
              <Icons.LifeCheck />
              <Text>Последние дропы</Text>
            </InfoSmall>
            <SliderLifeScroll _openedItems={openedItems.slice(0, 10)} />
          </WrapperLifeScroll>
        ) : null}
        {windowInnerWidth < 1400 &&
        windowInnerWidth > 768 &&
        fullCase &&
        fullCase[1] &&
        isAuth ? (
          <WrapperLifeScroll>
            <InfoSmall>
              <Icons.LifeCheck />
              <Text>Последние дропы</Text>
            </InfoSmall>
            <SliderLifeScroll _openedItems={openedItems.slice(0, 5)} />
          </WrapperLifeScroll>
        ) : null}
        {windowInnerWidth < 768 && fullCase && fullCase[1] && isAuth ? (
          <WrapperLifeScroll>
            <InfoSmall>
              <Icons.LifeCheck />
              <Text>Последние дропы</Text>
            </InfoSmall>
            <SliderLifeScroll _openedItems={openedItems.slice(0, 3)} />
          </WrapperLifeScroll>
        ) : null}

        {caseFullMemo}
      </Wrapper>
    </Layout>
  );
}
